import React, { useCallback, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  Add as AddIcon,
  Assistant as AssistantIcon,
  BorderClearOutlined as BorderClearOutlinedIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  CreateNewFolderOutlined as CreateNewFolderOutlinedIcon,
  Folder as FolderIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  UploadFileOutlined as UploadFileOutlinedIcon, FolderSharedOutlined,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'

import { IClipboard, IClipboardItemState } from '../utils/ClipboardContext'
import IStudyData, { serializeStudy } from '../types/IStudyData'
import StudiesService from '../DataServices/StudiesService'

import ConfirmDialog from '../utils/controls/ConfirmDialog'
import StudyConfigDialog from './StudyConfigDialog'
import TemplateDialog from '../utils/controls/TemplateDialog'
import ShareWithDialog from '../utils/controls/ShareWithDialog'
import MoreActionsMenu from '../utils/controls/MoreActionsMenu'
import ClipboardMenu from '../utils/controls/ClipboardMenu'

type StudyActionsMenuProps = {
  clipboard: IClipboard
  onCreateStation: () => void
  onCreateStationList: () => void
  onImportFromTemplate: (templateId: number) => void
  onShareWith: (shareWithUserEmail: string) => void
  setStudy: React.Dispatch<React.SetStateAction<IStudyData | null>>
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  study: IStudyData | null
  studyRef: React.MutableRefObject<IStudyData | null>
  templatesRefreshState: boolean
}

export default function StudyActionsMenu({
  clipboard,
  onCreateStation,
  onCreateStationList,
  onImportFromTemplate,
  onShareWith,
  setStudy,
  setSubmitting,
  study,
  studyRef,
  templatesRefreshState,
}: StudyActionsMenuProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  const [optionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const [configDialogOpen, setConfigDialogOpen] = useState<boolean>(false)
  const [templateDialogOpen, setTemplateDialogOpen] = useState<boolean>(false)
  const [shareWithDialogOpen, setShareWithDialogOpen] = useState<boolean>(false)
  const [deleteAllStationListsDialogOpen, setDeleteAllStationListsDialogOpen] =
    useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const cbStudyState: IClipboardItemState<IStudyData> | null = clipboard.studies.getItemState(
    study?.id,
  )
  const isCopiedToClipboard: boolean = cbStudyState !== null && cbStudyState.action === 'copy'
  const isCuttedToClipboard: boolean = cbStudyState !== null && cbStudyState.action === 'cut'

  const handleCopyToClipboard = (): void => {
    if (study) {
      clipboard.studies.addItem('copy', study)
      enqueueSnackbar(t`Study copied to clipboard`)
    } else {
      console.log('Warning: Study.handleCopyToClipboard: study is null')
    }
  }

  const handleCutToClipboard = (): void => {
    if (study) {
      clipboard.studies.addItem('cut', study)
      enqueueSnackbar(t`Study cut to clipboard`)
    } else {
      console.log('Warning: Study.handleCutToClipboard: study is null')
    }
  }

  const handleRemoveFromClipboard = (): void => {
    if (study) {
      clipboard.studies.removeItem(study.id)
      enqueueSnackbar(t`Study removed from clipboard`)
    } else {
      console.log('Warning: Study.handleRemoveFromClipboard: study is null')
    }
  }

  const handleOpenConfigDialog = (): void => {
    setOptionsMenuOpen(false)
    setConfigDialogOpen(true)
  }

  const handleCloseConfigDialog = useCallback((): void => {
    setConfigDialogOpen(false)
  }, [])

  const handleOpenTemplateDialog = (): void => {
    setOptionsMenuOpen(false)
    setTemplateDialogOpen(true)
  }

  const handleCloseTemplateDialog = (): void => {
    setTemplateDialogOpen(false)
  }

  const handleOpenShareWithDialog = (): void => {
    console.log("StudyActionsMenu handleOpenShareWithDialog")
    setOptionsMenuOpen(false)
    setShareWithDialogOpen(true)
  }

  const handleCloseShareWithDialog = (): void => {
    setShareWithDialogOpen(false)
  }

  const handleCreateStationClick = (): void => {
    setOptionsMenuOpen(false)
    onCreateStation()
  }

  const handleCreateStationListClick = (): void => {
    setOptionsMenuOpen(false)
    onCreateStationList()
  }

  const handleImportFromTemplate = (templateId: number): void => {
    setOptionsMenuOpen(false)
    onImportFromTemplate(templateId)
  }

  const handleShareWith = (shareWithUserEmail: string): void => {
    setOptionsMenuOpen(false)
    onShareWith(shareWithUserEmail)
  }

  const handleOpenDeleteAllStationListsDialog = (): void => {
    setOptionsMenuOpen(false)
    setDeleteAllStationListsDialogOpen(true)
  }

  const handleCloseDeleteAllStationListsDialog = useCallback((): void => {
    setDeleteAllStationListsDialogOpen(false)
  }, [])

  const handleDeleteAllStationListsClick = useCallback((): void => {
    // Use stationRef in a Callback function.
    if (studyRef.current) {
      setDeleteAllStationListsDialogOpen(false)
      setSubmitting(true)
      StudiesService.deleteAllStationListsOfStudy(studyRef.current.id)
        .then(() => {
          if (studyRef.current) {
            console.log('All station lists deleted', studyRef.current.id)
            studyRef.current.stationList_ids = []
            studyRef.current.stationLists = []
            setStudy({ ...studyRef.current })
          } else {
            console.log('Warning: Study.handleDeleteAllStationListsClick (then): study is null')
          }
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while station lists deleting', err, err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      console.log('Warning: Study.handleDeleteAllStationListsClick: study is null')
    }
  }, [])

  const handleOpenDeleteDialog = (): void => {
    setOptionsMenuOpen(false)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  const handleDeleteClick = useCallback((): void => {
    // Use stationRef in a Callback function.
    // todo: this does not feel very robust. How to let the sibling page know
    // we've deleted this study?
    if (studyRef.current) {
      setDeleteDialogOpen(false)
      setSubmitting(true)
      StudiesService.deleteStudy(studyRef.current.id)
        .then(() => {
          console.log('Study deleled', studyRef.current?.id)
          navigate('/')
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while study deleting', err, err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      console.log('Warning: Study.handleDeleteStudy: study is null')
    }
  }, [])

  const moreActionMenuItems = study && [
    {
      icon: <SettingsOutlinedIcon />,
      label: t`Configure study`,
      onClick: handleOpenConfigDialog,
    },
    {
      icon: <CreateNewFolderOutlinedIcon />,
      label: t`Import study from template`,
      onClick: handleOpenTemplateDialog,
    },
    {
      icon: <UploadFileOutlinedIcon />,
      label: t`Export study to file`,
      props: {
        href: `data:text/json;charset=utf-8,${encodeURIComponent(serializeStudy(study))}`,
        download: `STS-Study (${study.title}).json`,
        component: 'a',
      },
      onClick: () => setOptionsMenuOpen(false),
    },
    {
      icon: <FolderSharedOutlined />,
      label: t`Share study`,
      onClick: handleOpenShareWithDialog,
    },
    {
      icon: <AddIcon />,
      label: t`Add area with station`,
      onClick: handleCreateStationClick,
      hidden: study.stationLists.length !== 0,
      hasDivider: true,
    },
    {
      icon: <BorderClearOutlinedIcon />,
      label: t`Add blank area`,
      hidden: study.stationLists.length !== 0,
      onClick: handleCreateStationListClick,
    },
    {
      icon: <DeleteOutlinedIcon />,
      label: t`Delete all areas`,
      onClick: handleOpenDeleteAllStationListsDialog,
      color: 'error.dark',
      hasDivider: true,
    },
    {
      icon: <DeleteForeverOutlinedIcon />,
      label: t`Delete study`,
      onClick: handleOpenDeleteDialog,
      color: 'error.dark',
    },
  ]

  return (
    <Box display="flex">
      {study ? (
        <>
          <Box sx={{ mt: '3px', whiteSpace: 'nowrap' }}>
            {study.isHelper && (
              <Tooltip title={t`This study is a helper`}>
                <IconButton
                  color="warning"
                  onClick={handleOpenConfigDialog}
                  size="small"
                  sx={{ px: 0, ml: '4px', mt: '1px' }}
                >
                  <AssistantIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {study.isTemplate && (
              <Tooltip title={t`This study is a template`}>
                <IconButton
                  color="warning"
                  onClick={handleOpenConfigDialog}
                  size="small"
                  sx={{ px: 0, ml: '4px' }}
                >
                  <FolderIcon />
                </IconButton>
              </Tooltip>
            )}
            {(study.isHelper || study.isTemplate) && (
              <span style={{ marginLeft: '8px', marginRight: '4px' }}>|</span>
            )}
          </Box>
          <ClipboardMenu
            containerSx={{ mt: '5px' }}
            isCopied={isCopiedToClipboard}
            isCutted={isCuttedToClipboard}
            itemToString={t`the study`}
            onCopyToClipboard={handleCopyToClipboard}
            onCutToClipboard={handleCutToClipboard}
            onRemoveFromClipboard={handleRemoveFromClipboard}
          />
          <MoreActionsMenu
            containerSx={{ mt: '5px' }}
            items={moreActionMenuItems}
            open={optionsMenuOpen}
            setOpen={setOptionsMenuOpen}
          />
          <StudyConfigDialog
            open={configDialogOpen}
            onClose={handleCloseConfigDialog}
            setStudy={setStudy}
            study={study}
          />
          <TemplateDialog
            description={t`Insert stations, tools and terms from a template.`}
            getTemplates={StudiesService.getStudyTemplates}
            key={`templates-${templatesRefreshState}`}
            open={templateDialogOpen}
            onClose={handleCloseTemplateDialog}
            onImportFromTemplate={handleImportFromTemplate}
            title={t`Import study from template`}
          />
          <ShareWithDialog
            description={t`Enter the email of the user you want to share this study with.`}
            onClose={handleCloseShareWithDialog}
            onShareWith={handleShareWith}
            open={shareWithDialogOpen}
            title={t`Share with user`}
          />
          <ConfirmDialog
            confirmColor="secondary"
            confirmText={t`Delete all areas`}
            description={t`Are you sure you want to delete all areas of this study?`}
            open={deleteAllStationListsDialogOpen}
            onClose={handleCloseDeleteAllStationListsDialog}
            onConfirm={handleDeleteAllStationListsClick}
            title={study.title}
          />
          <ConfirmDialog
            confirmColor="secondary"
            confirmText={t`Delete study`}
            description={t`Are you sure you want to delete this study?`}
            open={deleteDialogOpen}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleDeleteClick}
            title={study.title}
          />
        </>
      ) : (
        <span />
      )}
    </Box>
  )
}
