import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Avatar, Box, Button, CssBaseline, Container } from '@mui/material'
import { activateInitialLanguage, setInitialLanguage } from './utils/i18n'

export default function AuthContainer(): JSX.Element {
  console.log('Load Auth Container Component')

  const [, setLanguage] = useState<string>(activateInitialLanguage())

  const handleChangeLanguage = (newLanguage: string): void => {
    setInitialLanguage(newLanguage)
    setLanguage(newLanguage)
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 8 }}>
        <Avatar sx={{ bgcolor: 'secondary.main', m: 1 }} />
        <Outlet />
        <Box sx={{ mt: 6 }}>
          <Button onClick={() => handleChangeLanguage('en')} variant="text">
            English
          </Button>
          <Button onClick={() => handleChangeLanguage('fr')} variant="text">
            Français
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
