import { Box, Typography } from '@mui/material'

import { t } from '@lingui/macro'
import ForgotPasswordForm from './ForgotPasswordForm'

export default function ForgotPassword(): JSX.Element {
  console.log('Load Forgot Password Component')

  return (
    <>
      <Typography component="h1" variant="h5">
        {t`Forgot password?`}
      </Typography>
      <Box sx={{ mt: 3, textAlign: 'center' }}>
        {t`Enter your email address below, and we'll send you an email allowing you to reset it.`}
      </Box>
      <ForgotPasswordForm
        onFormSubmitted={(): void => {
          /* nothing */
        }}
        submitButtonText={t`Reset password`}
      />
    </>
  )
}
