import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, FormControl, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

const useStyles = makeStyles({
  select: {
    color: '#fff',
    '&:before': {
      borderColor: '#fff',
    },
    '&:after': {
      borderColor: '#fff',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#fff',
    },
  },
  icon: {
    fill: '#fff',
  },
  root: {
    color: '#fff',
  },
})

type LanguageSelectorProps = {
  language: string
  onChangeLanguage: (newLanguage: string) => void
}

function LanguageSelector({ language, onChangeLanguage }: LanguageSelectorProps): JSX.Element {
  console.log('Load LanguageSelector Component')

  const classes = useStyles()

  const handleSelectChange = (e: SelectChangeEvent): void => {
    e.preventDefault()
    onChangeLanguage(e.target.value)
  }

  return (
    <Box sx={{ ml: 2 }}>
      <FormControl variant="standard">
        <Select
          className={classes.select}
          disableUnderline
          inputProps={{
            classes: {
              icon: classes.icon,
              root: classes.root,
            },
          }}
          onChange={handleSelectChange}
          value={language}
          variant="standard"
        >
          <MenuItem sx={{ py: '4px' }} value="en">
            English
          </MenuItem>
          <MenuItem sx={{ py: '4px' }} value="fr">
            Français
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default React.memo(LanguageSelector)
