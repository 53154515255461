/* eslint-disable camelcase */

import yup from '../utils/yupLocale'
import IToolData, { ToolExportProps, ToolImportSchema } from './IToolData'

export default interface IStationData {
  id: number
  title: string
  subtitle: string
  subject: string
  note?: string
  tool_ids: number[]
  tools: IToolData[]
  stationList: number
  direction: 'row' | 'column'
  isPinned: boolean
  isTemplate: boolean
}

export const StationExportProps: string[] = [
  'title',
  'subtitle',
  'subject',
  'note',
  'tools',
  ...ToolExportProps,
  'direction',
  'isPinned',
  'isTemplate',
]

export const StationImportSchema = yup.object().shape({
  title: yup.string().required(),
  subtitle: yup.string().defined(),
  subject: yup.string().defined(),
  note: yup.string().defined(),
  tools: yup.array().of(ToolImportSchema),
  direction: yup
    .string()
    .defined()
    .matches(/^(row|column)$/),
  isPinned: yup.boolean().defined(),
  isTemplate: yup.boolean().defined(),
})

export const serializeStation = (station: IStationData): string =>
  JSON.stringify(station, StationExportProps)
