import React, { useCallback, useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  Launch as LaunchIcon,
  RecordVoiceOver,
  UploadFileOutlined as UploadFileOutlinedIcon,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'

import { IClipboardItemState } from '../utils/ClipboardContext'
import ITermData, { serializeTerm } from '../types/ITermData'
import StudiesService from '../DataServices/StudiesService'

import MoreActionsMenu from '../utils/controls/MoreActionsMenu'
import ClipboardMenu from '../utils/controls/ClipboardMenu'

type TermActionsMenuProps = {
  clipboardTermState: IClipboardItemState<ITermData> | null
  onCopyToClipboard: (term: ITermData) => IClipboardItemState<ITermData> | null
  onCutToClipboard: (term: ITermData) => IClipboardItemState<ITermData> | null
  onRemoveFromClipboard: (term: ITermData) => IClipboardItemState<ITermData> | null
  onTermDeleted: (termId: number) => void
  term: ITermData
}

export default function TermActionsMenu({
  clipboardTermState: initClipboardTermState,
  onCopyToClipboard,
  onCutToClipboard,
  onRemoveFromClipboard,
  onTermDeleted,
  term,
}: TermActionsMenuProps): JSX.Element {
  const [optionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  // For performance reasons, the Clipboard Context is not used directly in Term
  // components to prevent them from being re-render whenever the clipboard changes.
  const [cbTermState, setCbTermState] = useState<IClipboardItemState<ITermData> | null>(
    initClipboardTermState,
  )
  const isCopiedToClipboard: boolean = cbTermState !== null && cbTermState.action === 'copy'
  const isCuttedToClipboard: boolean = cbTermState !== null && cbTermState.action === 'cut'

  const handleCopyToClipboard = (): void => {
    const cbTermState: IClipboardItemState<ITermData> | null = onCopyToClipboard(term)
    setCbTermState(cbTermState)
    enqueueSnackbar(t`Term copied to clipboard`)
  }

  const handleCutToClipboard = (): void => {
    const cbTermState: IClipboardItemState<ITermData> | null = onCutToClipboard(term)
    setCbTermState(cbTermState)
    enqueueSnackbar(t`Term cut to clipboard`)
  }

  const handleRemoveFromClipboard = (): void => {
    const cbTermState: IClipboardItemState<ITermData> | null = onRemoveFromClipboard(term)
    setCbTermState(cbTermState)
    enqueueSnackbar(t`Term removed from clipboard`)
  }

  const handleDeleteClick = useCallback((): void => {
    setDeleteDialogOpen(false)
    StudiesService.deleteTerm(term.id)
      .then(() => {
        console.log('Term deleted', term.id)
        onTermDeleted(term.id)
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while term deleting', err, err.response)
      })
  }, [])

  const moreActionMenuItems = [
    {
      icon: <UploadFileOutlinedIcon />,
      label: t`Export term to file`,
      props: {
        href: `data:text/json;charset=utf-8,${encodeURIComponent(serializeTerm(term))}`,
        download: `STS-Term (${term.term}).json`,
        component: 'a',
      },
      onClick: () => setOptionsMenuOpen(false),
    },
    {
      icon: <DeleteForeverOutlinedIcon color="secondary" />,
      label: t`Delete term`,
      onClick: handleDeleteClick,
      color: 'error.dark',
    },
  ]

  const handleVoice = (): void => {
    console.log('Begining handleVoice')
    StudiesService.voiceForTerm(term.id)
      .then((response) => {
        console.log(response.data)
        const fetchAudioData = async () => {
          try {
            // Create the audio URL and play the audio
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const audioBytes = atob(response.data)

            // Convert the bytes to a Uint8Array
            const audioArray = new Uint8Array(audioBytes.length)
            for (let i = 0; i < audioBytes.length; i++) {
              audioArray[i] = audioBytes.charCodeAt(i)
            }
            const oBlob = new Blob([audioArray], { type: 'audio/mpeg' })
            const audioURL = window.URL.createObjectURL(oBlob)
            const audio: HTMLAudioElement = new Audio()
            audio.src = audioURL
            audio.play()
          } catch (error) {
            console.error('Error loading audio:', error)
          }
        }
        // Call the function to fetch and play the audio
        fetchAudioData()
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while getting my voice', err, err.response)
      })
      .finally(() => {
        console.log('End handleVoice')
      })
  }

  return (
    <>
      {term.hyperlink && (
        <Box>
          <Tooltip title={t`Launch`}>
            <IconButton
              color="primary"
              onClick={() => window.open(term.hyperlink, '_blank')}
              size="small"
              sx={{ px: 0, ml: '4px' }}
            >
              <LaunchIcon fontSize="small" sx={{ p: '1px' }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box>
        <Tooltip title={t`Read out loud`}>
          <IconButton color="primary" onClick={handleVoice} size="medium" sx={{ px: 0, ml: '5px' }}>
            <RecordVoiceOver fontSize="small" sx={{ p: '0px' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <ClipboardMenu
        iconSize="small"
        isCopied={isCopiedToClipboard}
        isCutted={isCuttedToClipboard}
        itemToString={t`the term`}
        onCopyToClipboard={handleCopyToClipboard}
        onCutToClipboard={handleCutToClipboard}
        onRemoveFromClipboard={handleRemoveFromClipboard}
      />
      <MoreActionsMenu
        items={moreActionMenuItems}
        open={optionsMenuOpen}
        setOpen={setOptionsMenuOpen}
      />
    </>
  )
}
