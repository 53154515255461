import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import { SnackbarProvider } from 'notistack'
import reportWebVitals from './reportWebVitals'

import './index.css'
import App from './App'
import AuthRoutes from './AuthRoutes'
import Login from './Auth/Login'
import Logout from './Auth/Logout'
import SignUp from './Auth/SignUp'
import ConfirmEmail from './Auth/ConfirmEmail'
import ForgotPassword from './Auth/ForgotPassword'
import ResetPassword from './Auth/ResetPassword'
import Study from './Study/Study'
import StudyLists from './StudyLists/StudyLists'
import AccountEdit from './Account/AccountEdit'
import PasswordEdit from './Account/PasswordEdit'
import ProtectedRoutes from './ProtectedRoutes'
import AuthContainer from './AuthContainer'
import NotFound from './NotFound'

const theme = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          disableInteractive: true,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#1e56a0',
    },
    secondary: {
      main: '#b53f51',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
})

const routing = (
  <ThemeProvider theme={theme}>
    <I18nProvider i18n={i18n}>
      <React.StrictMode>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ProtectedRoutes />}>
                <Route path="" element={<App />}>
                  <Route path="" element={<StudyLists />} />
                  <Route path="study/:id" element={<Study />} />
                  <Route path="account-edit/me" element={<AccountEdit />} />
                  <Route path="password-edit/me" element={<PasswordEdit />} />
                </Route>
              </Route>
              <Route path="/auth" element={<AuthRoutes />}>
                <Route path="" element={<AuthContainer />}>
                  <Route path="login" element={<Login />} />
                  <Route path="signup" element={<SignUp />} />
                  <Route path="confirm-email" element={<ConfirmEmail />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                </Route>
                <Route path="logout" element={<Logout />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </React.StrictMode>
    </I18nProvider>
  </ThemeProvider>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
