import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { useSnackbar } from 'notistack'

import { activateInitialLanguage, setInitialLanguage } from './utils/i18n'
import { axiosObserver } from './utils/axios'

import STSAppBar from './STSAppBar/STSAppBar'
import ClipboardContext, { ClipboardState, IClipboard } from './utils/ClipboardContext'

function App(): JSX.Element {
  console.log('Load App Component')

  const [language, setLanguage] = useState<string>(activateInitialLanguage())
  const isInitialErrorRender = useRef<boolean>(true)
  const [errorState, setErrorState] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()
  const clipboardState: IClipboard = ClipboardState()

  axiosObserver.errorState = setErrorState

  useEffect(() => {
    if (isInitialErrorRender.current) {
      isInitialErrorRender.current = false
    } else if (errorState !== '') {
      enqueueSnackbar(errorState, { variant: 'error' })
    }
  }, [errorState])

  const handleLanguageChange = useCallback((newLanguage: string): void => {
    setLanguage(newLanguage)
    setInitialLanguage(newLanguage)
  }, [])

  return (
    <ClipboardContext.Provider value={clipboardState}>
      <CssBaseline />
      <STSAppBar language={language} onChangeLanguage={handleLanguageChange} />
      <Outlet />
    </ClipboardContext.Provider>
  )
}

export default App
