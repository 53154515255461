import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  Close as CloseIcon,
  ContentCopy as ContentCopyIcon,
  ContentCut as ContentCutIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'
import ClipboardContext, {
  IClipboard,
  IClipboardItems,
  IClipboardItemState,
} from '../utils/ClipboardContext'
import IStudyData from '../types/IStudyData'
import IStationData from '../types/IStationData'
import IToolData from '../types/IToolData'
import ITermData from '../types/ITermData'
import Checkbox from '../utils/controls/Checkbox'

const TypographySubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  fontWeight: 'bold',
  paddingTop: '5px',
  textTransform: 'uppercase',
}))

type ClipboardDrawerProps = {
  onClose: () => void
  open: boolean
}

function ClipboardDrawer({ onClose, open: initOpen }: ClipboardDrawerProps): JSX.Element {
  console.log('Load ClipboardDrawer Component', initOpen)

  const [open, setOpen] = useState(initOpen)

  useEffect(() => {
    setOpen(initOpen)
  }, [initOpen])

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setOpen(false)
    onClose()
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (
    e: React.MouseEvent<HTMLDivElement>,
    clipboard: IClipboard,
  ): void => {
    e.stopPropagation()
    if (clipboard.studies.itemCount > 0) {
      console.log(
        'Debug (Clipboard Studies)',
        clipboard.studies.itemStateKeys,
        clipboard.studies.itemStateByKey,
      )
    }
    if (clipboard.stations.itemCount > 0) {
      console.log(
        'Debug (Clipboard Stations)',
        clipboard.stations.itemStateKeys,
        clipboard.stations.itemStateByKey,
      )
    }
    if (clipboard.tools.itemCount > 0) {
      console.log(
        'Debug (Clipboard Tools)',
        clipboard.tools.itemStateKeys,
        clipboard.tools.itemStateByKey,
      )
    }
    if (clipboard.terms.itemCount > 0) {
      console.log(
        'Debug (Clipboard Terms)',
        clipboard.terms.itemStateKeys,
        clipboard.terms.itemStateByKey,
      )
    }
  }

  function getItemText(item: unknown): string {
    if ((item as IStudyData).title) {
      return (item as IStudyData).title
    }
    if ((item as IStationData).title) {
      return (item as IStationData).title
    }
    if ((item as IToolData).toolType) {
      return (item as IToolData).toolType
    }
    if ((item as IToolData).title) {
      return (item as IToolData).title
    }
    if ((item as ITermData).term) {
      return (item as ITermData).term
    }
    return t`Unknown type`
  }

  function renderClipboardItem<T>(
    clipboardItems: IClipboardItems<T>,
    title: string,
    removeAllItemsText: string,
    itemIsCopiedText: string,
    copyItemText: string,
    itemIsCutText: string,
    cutItemText: string,
    removeItemText: string,
  ): JSX.Element {
    if (clipboardItems.itemCount > 0) {
      return (
        <Box sx={{ pb: 2, pt: 1 }}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Checkbox
                checked={clipboardItems.itemCount === clipboardItems.selectedItemCount}
                color="default"
                label={<TypographySubTitle>{title}</TypographySubTitle>}
                labelPlacement="end"
                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                  clipboardItems.changeAllItemsSelection(checked)
                }
                size="small"
                sx={{ mt: '3px', py: 0 }}
              />
            </Box>
            <Box>
              <Tooltip title={removeAllItemsText}>
                <IconButton
                  onClick={clipboardItems.removeAllItems}
                  size="small"
                  sx={{ mx: 1, p: 0 }}
                >
                  <HighlightOffIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Divider sx={{ mb: 1, mt: '3px' }} />
          <Box>
            {clipboardItems.itemStateKeys.map((itemKey: string) => {
              const itemState: IClipboardItemState<T> = clipboardItems.itemStateByKey[itemKey]
              return (
                <Box display="flex" key={itemKey}>
                  <Box flexGrow={1}>
                    <Checkbox
                      checked={itemState.selected}
                      label={getItemText(itemState.data)}
                      labelPlacement="end"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        clipboardItems.changeItemSelection(checked, itemKey)
                      }
                      size="small"
                      sx={{ py: 0 }}
                    />
                  </Box>
                  <Box>
                    <Tooltip title={itemState.action === 'copy' ? itemIsCopiedText : copyItemText}>
                      <span>
                        <IconButton
                          color="primary"
                          disabled={itemState.action === 'copy'}
                          onClick={() => clipboardItems.changeItemAction('copy', itemKey)}
                          size="small"
                          sx={{ fontSize: '18px', mr: '9px', p: 0 }}
                        >
                          {itemState.action === 'copy' ? (
                            <ContentCopyIcon
                              fontSize="small"
                              sx={{
                                border: 'solid 1px',
                                borderRadius: '3px',
                                color: 'warning.dark',
                                p: '1px',
                              }}
                            />
                          ) : (
                            <ContentCopyIcon color="primary" fontSize="small" sx={{ p: '1px' }} />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title={itemState.action === 'cut' ? itemIsCutText : cutItemText}>
                      <span>
                        <IconButton
                          color="primary"
                          disabled={itemState.action === 'cut'}
                          onClick={() => clipboardItems.changeItemAction('cut', itemKey)}
                          size="small"
                          sx={{ fontSize: '18px', mr: '9px', p: 0 }}
                        >
                          {itemState.action === 'cut' ? (
                            <ContentCutIcon
                              fontSize="small"
                              sx={{
                                border: 'solid 1px',
                                borderRadius: '3px',
                                color: 'warning.dark',
                                p: '2px',
                              }}
                            />
                          ) : (
                            <ContentCutIcon color="primary" fontSize="small" sx={{ p: '2px' }} />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title={removeItemText}>
                      <IconButton
                        color="primary"
                        onClick={() => clipboardItems.removeItem(itemKey)}
                        size="small"
                        sx={{ fontSize: '18px', mr: '9px', p: 0 }}
                      >
                        <HighlightOffIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      )
    }
    return <Box />
  }

  return (
    <ClipboardContext.Consumer>
      {(clipboard: IClipboard) => (
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          onDoubleClick={(e) => handleDisplayDebugInfo(e, clipboard)}
        >
          <Box sx={{ minWidth: '350px', overflowX: 'hidden' }}>
            <DialogTitle sx={{ p: 2 }}>
              <Box display="flex">
                <Box flexGrow={1}>{t`Clipboard`}</Box>
                <Tooltip title={t`Close`}>
                  <IconButton
                    onClick={onClose}
                    sx={{ color: 'text.secondary', mr: '5px', p: '3px' }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2 }}>
              {clipboard.totalItemCount === 0 ? (
                <Typography
                  color="text.secondary"
                  fontSize="15px"
                >{t`The clipboard is empty.`}</Typography>
              ) : (
                <Box />
              )}
              {renderClipboardItem<IStudyData>(
                clipboard.studies,
                t`Studies`,
                t`Remove all studies`,
                t`Study is copied`,
                t`Copy study`,
                t`Study is cut`,
                t`Cut study`,
                t`Remove study`,
              )}
              {renderClipboardItem<IStationData>(
                clipboard.stations,
                t`Stations`,
                t`Remove all stations`,
                t`Station is copied`,
                t`Copy station`,
                t`Station is cut`,
                t`Cut station`,
                t`Remove station`,
              )}
              {renderClipboardItem<IToolData>(
                clipboard.tools,
                t`Tools`,
                t`Remove all tools`,
                t`Tool is copied`,
                t`Copy tool`,
                t`Tool is cut`,
                t`Cut tool`,
                t`Remove tool`,
              )}
              {renderClipboardItem<ITermData>(
                clipboard.terms,
                t`Terms`,
                t`Remove all terms`,
                t`Term is copied`,
                t`Copy term`,
                t`Term is cut`,
                t`Cut term`,
                t`Remove term`,
              )}
            </DialogContent>
            <DialogActions sx={{ p: 2, pt: 0 }}>
              {clipboard.totalItemCount > 0 ? (
                <Button
                  autoFocus
                  color="primary"
                  fullWidth
                  onClick={clipboard.removeAll}
                  size="small"
                  variant="outlined"
                >
                  {t`Clear Clipboard`}
                </Button>
              ) : (
                <Box />
              )}
            </DialogActions>
          </Box>
        </Drawer>
      )}
    </ClipboardContext.Consumer>
  )
}

export default React.memo(ClipboardDrawer)
