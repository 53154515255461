import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import { t } from '@lingui/macro'
import ProgressBackdrop from '../utils/controls/ProgressBackdrop'
import { axiosObserver } from '../utils/axios'
import AccountDataService from '../DataServices/AccountDataService'
import IUserData from '../types/IUserData'
import FormTextField from '../utils/controls/FormTextField'

type FormAccountEditFields = {
  name: string
  email: string
}

export default function AccountEdit(): JSX.Element {
  console.log('Load Account Edit Component')

  const navigate: NavigateFunction = useNavigate()
  const [isValidUser, setIsValidUser] = useState<boolean | null>(null)
  const [submitWarning, setSubmitWarning] = useState<string>('')
  const [submitError, setSubmitError] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .label(t`The full name`)
      .required()
      .max(50),
    email: yup
      .string()
      .label(t`The email address`)
      .required()
      .email()
      .max(100),
  })

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<FormAccountEditFields>({
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    AccountDataService.getCurrentUserInfo()
      .then((response) => {
        const user: IUserData | null = response.data
        console.log('Successful getting user information', user)
        if (user !== null) {
          reset(user as FormAccountEditFields)
        }
        setIsValidUser(true)
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while getting user info', err, err.response)
        setIsValidUser(false)
      })
  }, [])

  const handleFormSubmit = (data: FormAccountEditFields): void => {
    setSubmitWarning('')
    setSubmitError('')

    if (isDirty) {
      axiosObserver.errorStateEnabled = false
      setSubmitting(true)

      AccountDataService.saveProfile(data.name, data.email)
        .then((response) => {
          const user: IUserData = response.data
          console.log('Successful profile modification', user)
          localStorage.setItem('user_name', user.name)
          navigate('/')
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while editing profile', err, err.response)
          if (err.response.status === 400 && err.response.data === 'email_already_exists') {
            setSubmitWarning(t`This email is already in use, please choose another one.`)
          } else if (
            err.response.status === 401 &&
            err.response.data === 'email_verification_failed'
          ) {
            navigate('/auth/logout')
          } else {
            setSubmitError(t`An error occurred while editing profile, please try again.`)
          }
        })
        .finally(() => {
          axiosObserver.errorStateEnabled = true
          setSubmitting(false)
        })
    } else {
      navigate('/')
    }
  }

  const handlePasswordEditClick = (): void => {
    navigate('/password-edit/me')
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 8 }}>
        <Typography component="h1" variant="h5">
          {t`Editing your profile`}
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(handleFormSubmit)}
          sx={{ mt: 5, width: '100%' }}
        >
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            {isValidUser === null && <CircularProgress sx={{ p: 0.5 }} />}
            {isValidUser === true && (
              <Alert severity="info" sx={{ textAlign: 'left' }}>
                {t`If you change your email, you will be logged out and we will send you an email for verification.`}
              </Alert>
            )}
            {isValidUser === false && (
              <Alert severity="error" sx={{ textAlign: 'left' }}>
                {t`An error occurred while getting user info, the form is disabled.`}
              </Alert>
            )}
          </Box>
          <FormTextField
            autoComplete='off'
            autoFocus
            disabled={isValidUser !== true}
            errorText={errors.name?.message}
            InputLabelProps={{ shrink: true }}
            label={t`Full Name`}
            margin="normal"
            registerReturn={register('name')}
          />
          <FormTextField
            autoComplete='off'
            disabled={isValidUser !== true}
            errorText={errors.email?.message}
            InputLabelProps={{ shrink: true }}
            label={t`Email Address`}
            margin="normal"
            registerReturn={register('email')}
          />
          {submitWarning !== '' && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              {submitWarning}
            </Alert>
          )}
          {submitError !== '' ? (
            <Alert severity="error" sx={{ mt: 2 }}>
              {submitError}
            </Alert>
          ) : (
            <span />
          )}
          <Button
            color="primary"
            disabled={isValidUser !== true}
            fullWidth
            sx={{ mb: 2, mt: 3 }}
            type="submit"
            variant="contained"
          >
            {t`Save`}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                disabled={isValidUser !== true}
                onClick={handlePasswordEditClick}
                size="small"
                sx={{ fontSize: '14px', textTransform: 'none' }}
                variant="text"
              >
                {t`Click here to change your password`}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ProgressBackdrop open={submitting} />
    </Container>
  )
}
