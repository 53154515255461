import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'

export default function useRefState<S>(
  initialState: S,
): [state: S, setState: MutableRefObject<S>, stateRef: Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState<S>(initialState)
  const stateRef = useRef<S>(state)

  useEffect(() => {
    stateRef.current = state
  }, [state])

  return [state, stateRef, setState]
}
