import { AxiosResponse } from 'axios'
import axiosInstance from '../utils/axios'
import IStudyListData from '../types/IStudyListData'

const rootPath = '/studies/study_lists'

const getAll = (): Promise<AxiosResponse<IStudyListData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<IStudyListData[]>>(rootPath)

const get = (id: number): Promise<AxiosResponse<IStudyListData>> =>
  axiosInstance.get<unknown, AxiosResponse<IStudyListData>>(`${rootPath}/${id}`)

const create = (data: IStudyListData): Promise<AxiosResponse<IStudyListData>> =>
  axiosInstance.post<IStudyListData, AxiosResponse<IStudyListData>>(`${rootPath}/`, data)

const updateOrCreate = (
  id: number,
  data: IStudyListData,
): Promise<AxiosResponse<IStudyListData>> => {
  if (id > 0) {
    let data2 : any
    data2 = Object.assign({},  data)
    delete data2["study_ids"]
    return axiosInstance.put<IStudyListData, AxiosResponse<IStudyListData>>(
      `${rootPath}/${id}/`,
      data2,
    )
  }
  return create(data)
}

const remove = (id: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(`${rootPath}/${id}/`)

const removeAll = (): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(rootPath)

const findByXY = (x: number, y: number): Promise<AxiosResponse<IStudyListData>> =>
  axiosInstance.get<unknown, AxiosResponse<IStudyListData>>(`${rootPath}?x=${x}&y=${y}`)

const StudyListDataService = {
  getAll,
  get,
  create,
  updateOrCreate,
  remove,
  removeAll,
  findByXY,
}

export default StudyListDataService
