/* eslint-disable camelcase */

import yup from '../utils/yupLocale'
import IStationData, { StationExportProps, StationImportSchema } from './IStationData'

export default interface IStationListData {
  id: number
  station_ids: number[]
  stations: IStationData[]
  study: number
}

export const StationListExportProps: string[] = ['stations', ...StationExportProps]

export const StationListImportSchema = yup.object().shape({
  stations: yup.array().of(StationImportSchema),
})

export const serializeStationList = (stationList: IStationListData): string =>
  JSON.stringify(stationList, StationListExportProps)
