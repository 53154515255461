import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { Close as CloseIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'

import ITemplateData from '../../types/ITemplateData'
import Select, { SelectMenuItemData } from './Select'

type TemplateDialogProps = {
  description: string
  getTemplates: () => Promise<AxiosResponse<ITemplateData[]>>
  onClose: () => void
  onImportFromTemplate: (templateId: number) => void
  open: boolean
  title: string
}

function TemplateDialog({
  description,
  getTemplates,
  onClose,
  onImportFromTemplate,
  open,
  title,
}: TemplateDialogProps): JSX.Element {

  const [templates, setTemplates] = useState<ITemplateData[]>([])
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('')

  useEffect(() => {
    let isMounted: boolean = true
    console.log("TemplateDialog useEffect")
    getTemplates()
      .then((response) => {
        if (isMounted) {
          const loadedTemplates: ITemplateData[] = response.data
          console.log('Templates loaded (length)', loadedTemplates.length)
          setTemplates(loadedTemplates)
        }
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while templates loading', err, err.response)
      })
    return () => {
      isMounted = false
    }
  }, [])

  const handleSelectChange = (e: SelectChangeEvent<string>): void => {
    setSelectedTemplateId(e.target.value)
  }

  const handleImportClick = (): void => {
    if (selectedTemplateId !== '') {
      onClose()
      setSelectedTemplateId('')
      onImportFromTemplate(parseInt(selectedTemplateId, 10))
    }
  }

  const handleCloseDialog = (): void => {
    setSelectedTemplateId('')
    onClose()
  }

  const getTemplateData = (): SelectMenuItemData[] => {
    const templateData: SelectMenuItemData[] = []
    templates.forEach((template: ITemplateData) => {
      templateData.push({ value: template.id.toString(), text: template.title })
    })
    templateData.sort((a, b) => (a.text > b.text ? 1 : -1))
    return templateData
  }

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={handleCloseDialog}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
        <Box display="flex">
          <Box flexGrow={1}>{title}</Box>
          <Tooltip title={t`Close`}>
            <IconButton onClick={onClose} sx={{ color: 'text.secondary', mr: 0, p: 0 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2 }}>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '0.95rem', pb: 4 }}>
          {description}
        </DialogContentText>
        <Select
          errorText=""
          label={t`Select a template`}
          name="templates"
          onChange={handleSelectChange}
          options={getTemplateData()}
          sx={{ fontSize: '0.95rem' }}
          value={selectedTemplateId}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCloseDialog} size="small" variant="outlined">{t`Cancel`}</Button>
        <Button
          autoFocus
          onClick={handleImportClick}
          size="small"
          variant="contained"
        >{t`Import`}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(TemplateDialog)
