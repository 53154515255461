import { SxProps, Theme } from '@mui/material/styles'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  ContentCopy as ContentCopyIcon,
  ContentCut as ContentCutIcon,
  ContentPasteGo as ContentPasteGoIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'

export type ClipboardMenuProps = {
  containerSx?: SxProps<Theme>
  iconSize?: 'default' | 'small'
  isCopied?: boolean
  isCutted?: boolean
  itemToString?: string
  onCopyToClipboard?: () => void
  onCutToClipboard?: () => void
  onRemoveFromClipboard?: () => void
  onPasteFromClipboardSelection?: () => void
  pasteMessages?: { empty: string; selected: string }
  selectedChildItemCount?: number
}

export default function ClipboardMenu({
  containerSx,
  iconSize = 'default',
  isCopied,
  isCutted,
  itemToString,
  onCopyToClipboard,
  onCutToClipboard,
  onRemoveFromClipboard,
  onPasteFromClipboardSelection,
  pasteMessages,
  selectedChildItemCount,
}: ClipboardMenuProps): JSX.Element {
  const iconExtraPadding: number = iconSize === 'default' ? 0 : 1

  return (
    <Box display="flex" sx={containerSx}>
      {onCopyToClipboard &&
        onRemoveFromClipboard &&
        isCopied !== undefined &&
        itemToString !== undefined && (
          <Box>
            <Tooltip
              title={
                isCopied
                  ? t`Remove ${itemToString} from clipboard`
                  : t`Copy ${itemToString} to clipboard`
              }
            >
              <IconButton
                onClick={isCopied ? onRemoveFromClipboard : onCopyToClipboard}
                size="small"
                sx={{ px: 0, ml: '4px' }}
              >
                {isCopied ? (
                  <ContentCopyIcon
                    fontSize="small"
                    sx={{
                      border: 'solid 1px',
                      borderRadius: '3px',
                      color: 'warning.dark',
                      p: `${iconExtraPadding}px`,
                    }}
                  />
                ) : (
                  <ContentCopyIcon
                    color="primary"
                    fontSize="small"
                    sx={{ p: `${iconExtraPadding}px` }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}
      {onCutToClipboard &&
        onRemoveFromClipboard &&
        isCutted !== undefined &&
        itemToString !== undefined && (
          <Box>
            <Tooltip
              title={
                isCutted
                  ? t`Remove ${itemToString} from clipboard`
                  : t`Cut ${itemToString} to clipboard`
              }
            >
              <IconButton
                onClick={isCutted ? onRemoveFromClipboard : onCutToClipboard}
                size="small"
                sx={{ px: 0, ml: '4px' }}
              >
                {isCutted ? (
                  <ContentCutIcon
                    fontSize="small"
                    sx={{
                      border: 'solid 1px',
                      borderRadius: '3px',
                      color: 'warning.dark',
                      p: `${1 + iconExtraPadding}px`,
                    }}
                  />
                ) : (
                  <ContentCutIcon
                    color="primary"
                    fontSize="small"
                    sx={{ p: `${1 + iconExtraPadding}px` }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        )}
      {onPasteFromClipboardSelection &&
        selectedChildItemCount !== undefined &&
        pasteMessages !== undefined && (
          <Box>
            <Tooltip
              title={selectedChildItemCount > 0 ? pasteMessages.selected : pasteMessages.empty}
            >
              <span>
                <IconButton
                  color="primary"
                  disabled={selectedChildItemCount === 0}
                  onClick={onPasteFromClipboardSelection}
                  size="small"
                  sx={{ px: 0, ml: '4px' }}
                >
                  <ContentPasteGoIcon fontSize="small" sx={{ p: `${iconExtraPadding}px` }} />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        )}
    </Box>
  )
}
