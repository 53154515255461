import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  Close as CloseIcon,
  GridOn as GridOnIcon,
  ViewHeadline as ViewHeadlineIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'

import IToolData, { getTypeToolText } from '../types/IToolData'
import ITermData from '../types/ITermData'

const useStyles = makeStyles({
  tableFormatCol: {
    fontSize: '0.95rem',
    padding: '0 5px',
  },
  tableFormatFirstCol: {
    fontSize: '0.95rem',
    padding: '0 5px 0 0',
  },
  tableFormatLastCol: {
    fontSize: '0.95rem',
    padding: '0 0 0 5px',
  },
})

type ToolDisplayDialogProps = {
  onClose: () => void
  open: boolean
  tool: IToolData
}

function ToolCopyDialog({ onClose, open, tool }: ToolDisplayDialogProps): JSX.Element {
  console.log('Load ToolCopyDialog Component', tool.id)

  const classes = useStyles()

  // const [tool] = useState<IToolData>(initTool)
  const [displayFormat, setDisplayFormat] = useState<'paragraphe' | 'table'>('paragraphe')
  const [includeHeaders, setIncludeHeaders] = useState<boolean>(true)

  const handleDisplayFormatChanged = (
    e: React.MouseEvent<HTMLElement>,
    value: 'paragraphe' | 'table' | null,
  ): void => {
    if (value !== null) {
      setDisplayFormat(value)
    }
  }

  const handleIncludeHeadersChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setIncludeHeaders(checked)
  }

  const handleCopyAllTerms = (): void => {
    const el: HTMLElement | null = document.getElementById('select-all-terms')
    if (el) {
      if (window.getSelection) {
        if ((el as HTMLTextAreaElement).value) {
          ;(el as HTMLTextAreaElement).select()
        }
        const selection: Selection | null = window.getSelection()
        if (selection) {
          const range: Range = document.createRange()
          range.selectNodeContents(el)
          selection.removeAllRanges()
          selection.addRange(range)
          document.execCommand('copy')
        }
      } else {
        console.warn('Could not select: Unsupported browser.')
      }
    }
  }

  const handleCloseDialog = (): void => {
    onClose()
  }

  const renderPlainText = (): JSX.Element => {
    let value: string = ''
    tool.terms.forEach((term: ITermData, index: number) => {
      if (index > 0) {
        value += `${'\n'}`
      }
      value += term.term
    })
    return (
      <TextField
        autoComplete='off'
        fullWidth
        id="select-all-terms"
        InputProps={{ disableUnderline: true }}
        multiline
        value={value}
        variant="standard"
      />
    )
  }

  const renderTable = (): JSX.Element => (
    <table id="select-all-terms">
      {includeHeaders ? (
        <thead>
          <tr>
            <th
              className={classes.tableFormatFirstCol}
              style={{ textAlign: 'left' }}
            >{t`Title`}</th>
            <th className={classes.tableFormatCol} style={{ textAlign: 'left' }}>{t`Note`}</th>
            <th
              className={classes.tableFormatLastCol}
              style={{ textAlign: 'left' }}
            >{t`Hyperlink`}</th>
          </tr>
        </thead>
      ) : (
        <thead />
      )}
      <tbody>
        {tool.terms.map((term: ITermData) => (
          <tr key={term.id}>
            <td className={classes.tableFormatFirstCol} style={{ textAlign: 'left' }}>
              {term.term}
            </td>
            <td className={classes.tableFormatCol} style={{ textAlign: 'left' }}>
              {term.note}
            </td>
            <td className={classes.tableFormatLastCol} style={{ textAlign: 'left' }}>
              {term.hyperlink}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={handleCloseDialog}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
        <Box display="flex" sx={{ minWidth: '400px' }}>
          <Box flexGrow={1}>
            {tool.toolType ? getTypeToolText(tool.toolType) : tool.title}
            {tool.subtitle ? (
              <Box sx={{ color: 'text.secondary', fontSize: '14px' }}>{tool.subtitle}</Box>
            ) : (
              <Box />
            )}
          </Box>
          <Tooltip title={t`Close`}>
            <IconButton onClick={onClose} sx={{ color: 'text.secondary', mr: 0, p: 0 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <Divider />
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <ToggleButtonGroup
          color="primary"
          exclusive
          onChange={handleDisplayFormatChanged}
          size="small"
          value={displayFormat}
        >
          <ToggleButton value="paragraphe" sx={{ px: 2 }}>
            <ViewHeadlineIcon sx={{ mr: 1 }} />
            {t`Paragraphe`}
          </ToggleButton>
          <ToggleButton value="table" sx={{ px: 2 }}>
            <GridOnIcon fontSize="small" sx={{ mr: 1 }} />
            {t`Table`}
          </ToggleButton>
        </ToggleButtonGroup>
        {displayFormat === 'table' ? (
          <Box sx={{ ml: 3, mt: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={includeHeaders}
                  onChange={handleIncludeHeadersChanged}
                  size="small"
                />
              }
              label={
                <Typography sx={{ fontSize: '0.95rem' }}>{t`Include column headers`}</Typography>
              }
            />
          </Box>
        ) : (
          <span />
        )}
      </Box>
      <Divider />
      <DialogContent sx={{ my: 2, px: 2, py: 0 }}>
        {displayFormat === 'table' ? renderTable() : renderPlainText()}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCloseDialog} size="small" variant="outlined">{t`Close`}</Button>
        <Button
          autoFocus
          color="primary"
          onClick={handleCopyAllTerms}
          size="small"
          variant="contained"
        >
          {t`Copy All Terms`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ToolCopyDialog)
