import React, { useCallback, useEffect, useState } from 'react'
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AccountCircle as AccountCircleIcon,
  ArrowBack as ArrowBackIcon,
  ContentPasteSearch as ContentPasteSearchIcon,
  InfoOutlined as InfoOutlinedIcon,
  InsertCommentOutlined as InsertCommentOutlinedIcon,
  Logout as LogoutIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
} from '@mui/icons-material'
import { t, Trans } from '@lingui/macro'

import ClipboardContext, { IClipboard } from '../utils/ClipboardContext'
import ClipboardDrawer from './ClipboardDrawer'
import LanguageSelector from './LanguageSelector'

type STSAppBarProps = {
  language: string
  onChangeLanguage: (newLanguage: string) => void
}

function STSAppBar({ language, onChangeLanguage }: STSAppBarProps): JSX.Element {
  console.log('Load STSAppBar Component')

  const location: Location = useLocation()
  const navigate: NavigateFunction = useNavigate()
  const [clipboardDrawerOpen, setClipboardDrawerOpen] = useState<boolean>(false)
  const [title, setTitle] = useState<string>(t`STS42`)
  const [aboutOpen, setAboutOpen] = useState<boolean>(false)
  const [userAnchorEl, setUserAnchorEl] = React.useState<HTMLElement | null>(null)

  useEffect(() => {
    let title: string = t`STS42`
    if (location.pathname === '/') {
      title += `: ${t`Studies`}`
    } else if (location.pathname.startsWith('/study/')) {
      title += `: ${t`Study`}`
    } else if (
      location.pathname.startsWith('/account-edit/me') ||
      location.pathname.startsWith('/password-edit/me')
    ) {
      title += `: ${t`Profile`}`
    }
    document.title = title
    setTitle(title)
  }, [location.pathname])

  const handleOpenAbout = (): void => {
    setUserAnchorEl(null)
    setAboutOpen(true)
  }

  const handleCloseAbout = (): void => {
    setAboutOpen(false)
  }

  const handleGoHomeClick = (): void => {
    navigate('/')
  }

  const handleOpenClipboardDrawer = (): void => {
    setClipboardDrawerOpen(true)
  }

  const handleCloseClipboardDrawer = useCallback((): void => {
    setClipboardDrawerOpen(false)
  }, [])

  const handleOpenUserMenu = (e: React.MouseEvent<HTMLElement>) => {
    setUserAnchorEl(e.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setUserAnchorEl(null)
  }

  const handleAccountEditClick = (): void => {
    setUserAnchorEl(null)
    navigate('/account-edit/me')
  }

  const handleLogoutClick = (): void => {
    setUserAnchorEl(null)
    navigate('/auth/logout')
  }

  document.title = title

  return (
    <AppBar position="static">
      <Toolbar>
        {location.pathname === '/' ? (
          <Box sx={{ mr: 2, mt: 1, p: 0 }}>
            <Typography variant="h6" noWrap component="div">
              <img src="/logo80-white.png" alt="LOGO" style={{ height: '30px' }} />
            </Typography>
          </Box>
        ) : (
          <Tooltip title={t`Back to main dashboard`}>
            <IconButton color="inherit" onClick={handleGoHomeClick} sx={{ mr: 2, p: 0 }}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <Tooltip title={t`Open clipboard panel`}>
          <IconButton color="inherit" onClick={handleOpenClipboardDrawer} size="small">
            <ClipboardContext.Consumer>
              {(clipboard: IClipboard) => (
                <Badge badgeContent={clipboard.totalItemCount} color="secondary">
                  <ContentPasteSearchIcon />
                </Badge>
              )}
            </ClipboardContext.Consumer>
          </IconButton>
        </Tooltip>
        <LanguageSelector language={language} onChangeLanguage={onChangeLanguage} />
        <Box>
          <Tooltip title={t`Open user menu`}>
            <IconButton
              aria-controls="user-menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleOpenUserMenu}
              sx={{ ml: 2, px: 0 }}
            >
              <AccountCircleIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={userAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id="user-menu-appbar"
            keepMounted
            onClose={handleCloseUserMenu}
            open={Boolean(userAnchorEl)}
            sx={{ mt: '30px' }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {localStorage.getItem('user_name') ? (
              <MenuItem onClick={handleAccountEditClick} sx={{ py: '4px' }}>
                <ListItemIcon>
                  <PersonOutlineOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>
                    {localStorage.getItem('user_name')}
                  </Typography>
                </ListItemText>
              </MenuItem>
            ) : (
              <span />
            )}
            <Divider sx={{ my: '8px' }} />
            <a
              href="https://forms.clickup.com/f/dgbxq-7640/OC5XD2091JDD91JT8M"
              style={{ color: 'inherit', textDecoration: 'none' }}
              target="feedbackform"
            >
              <MenuItem onClick={handleCloseUserMenu} sx={{ py: '4px' }}>
                <ListItemIcon>
                  <InsertCommentOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`Feedback Form`}</Typography>
                </ListItemText>
              </MenuItem>
            </a>
            <MenuItem onClick={handleOpenAbout} sx={{ py: '4px' }}>
              <ListItemIcon>
                <InfoOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontSize: '0.95rem' }}>{t`About STS42`}</Typography>
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogoutClick} sx={{ py: '4px' }}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontSize: '0.95rem' }}>{t`Logout`}</Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <ClipboardDrawer onClose={handleCloseClipboardDrawer} open={clipboardDrawerOpen} />
      <Dialog
        aria-describedby="about-dialog-description"
        aria-labelledby="about-dialog-title"
        onClose={handleCloseAbout}
        open={aboutOpen}
      >
        <DialogTitle id="about-dialog-title">{t`About STS42`}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="about-dialog-description" variant="body2" textAlign="justify">
            <Trans id="app.about">
              This site serves as a prototyping platform for STS user interface and experience. It
              lets users organize collections of studies. A study is a space and a process to
              discover, compare and organize thoughts or ideas. Paraphrasing from the
              Merriam-Webster dictionary, a study is the careful investigation or examination of
              something. Another meaning for study is a room designed and dedicated for studying,
              reading, or writing. STS provides the virtual study room to assists users conducting
              studies.
            </Trans>
            <br />
            <br />
            <Trans>Copyright 2021-2023. All rights reserved, Strategic Thinking Systems Inc.</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: 2, pr: 3 }}>
          <Button
            autoFocus
            color="primary"
            onClick={handleCloseAbout}
            size="small"
            variant="outlined"
          >
            {t`Got it!`}
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  )
}

export default React.memo(STSAppBar)
