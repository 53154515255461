import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { lightBlue } from '@mui/material/colors'
import { Box, IconButton, Paper, Tooltip } from '@mui/material'
import {
  Add as AddIcon,
  BorderLeftOutlined as BorderLeftOutlinedIcon,
  BorderRightOutlined as BorderRightOutlinedIcon,
  Report as ReportIcon,
} from '@mui/icons-material'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import ProgressBackdrop from '../utils/controls/ProgressBackdrop'

import useRefState from '../utils/useRefState'
import useWindowDimensions from '../utils/useWindowDimensions'
import ClipboardContext, { IClipboard } from '../utils/ClipboardContext'
import IStationListData from '../types/IStationListData'
import IStationData from '../types/IStationData'
import Station from '../Station/Station'
import StudiesService from '../DataServices/StudiesService'
import StationListActionsMenu from './StationListActionsMenu'

type StationListProps = {
  onCreateStationList: (stationListId: number, position: 'left' | 'right') => void
  onStationListChanged: (stationList: IStationListData) => void
  onStationListDeleted: (stationListId: number) => void
  onStationsMoved: (movedStationIds: number[], targetStationListId: number) => void
  onTermsMoved: (movedTermIds: number[], targetToolId: number) => void
  onToolsMoved: (movedToolIds: number[], targetStationId: number) => void
  stationList: IStationListData
}

function StationList({
  onCreateStationList,
  onStationListChanged,
  onStationListDeleted,
  onStationsMoved,
  onTermsMoved,
  onToolsMoved,
  stationList: initStationList,
}: StationListProps): JSX.Element {

  const { height: screenHeight } = useWindowDimensions()

  const isInitialStationListRender = useRef<boolean>(true)
  const [stationList, stationListRef, setStationList] =
    useRefState<IStationListData>(initStationList)
  const [isPinned, setIsPinned] = useState<boolean>(
    initStationList.stations.some((s: IStationData) => s.isPinned),
  )
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  // The study component will be re-render on all clipboard changes
  const clipboard: IClipboard = useContext(ClipboardContext)

  useEffect(() => {
    if (isInitialStationListRender.current) {
      isInitialStationListRender.current = false
    } else {
      console.log('Station List changed (triggers onStationListChanged)', stationList.id)
      onStationListChanged(stationList)
    }
  }, [stationList])

  const handleCreateStationClick = (): void => {
    const newStation: IStationData = {
      id: 0,
      title: '',
      subtitle: '',
      subject: '',
      note: '',
      tools: [],
      tool_ids: [],
      stationList: stationList.id,
      direction: 'row',
      isPinned: false,
      isTemplate: false,
    }
    StudiesService.addStation(newStation)
      .then((response) => {
        const createdStation: IStationData = response.data
        console.log('Station created', createdStation.id)
        stationList.stations.push(createdStation)
        stationList.station_ids.push(createdStation.id)
        setStationList({ ...stationList })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while station creating', err, err.response)
      })
  }

  const handleCreateStationList = (position: 'left' | 'right'): void =>
    onCreateStationList(stationList.id, position)

  const handleTermsMoved = useCallback((movedTermIds: number[], targetToolId: number): void => {
    onTermsMoved(movedTermIds, targetToolId)
  }, [])

  const handleToolsMoved = useCallback((movedToolIds: number[], targetStationId: number): void => {
    onToolsMoved(movedToolIds, targetStationId)
  }, [])

  const handleStationChanged = useCallback((station: IStationData): void => {
    // Use stationListRef in a Callback function
    const stations: IStationData[] = []
    stationListRef.current.stations.forEach((s: IStationData) => {
      stations.push(s.id === station.id ? station : s)
    })
    stationListRef.current.stations = stations
    setStationList({ ...stationListRef.current })
    setIsPinned(stationListRef.current.stations.some((s: IStationData) => s.isPinned))
  }, [])

  const handleStationDeleted = useCallback((stationId: number): void => {
    // Use stationListRef in a Callback function
    stationListRef.current.stations = stationListRef.current.stations.filter(
      (station: IStationData) => station.id !== stationId,
    )
    stationListRef.current.station_ids = stationListRef.current.station_ids.filter(
      (id: number) => id !== stationId,
    )
    setStationList({ ...stationListRef.current })
  }, [])

  const handleFixFunctionalIntegrity = (): void => {
    setSubmitting(true)
    StudiesService.fixFunctionalIntegrityOfStationList(stationList.id)
      .then((response) => {
        const fixedStationList: IStationListData = response.data
        console.log('Station List fixed', fixedStationList.id)
        setStationList({ ...fixedStationList })
        enqueueSnackbar(t`Station list fixed correctly`, { variant: 'success' })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while station list fixing', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    console.log('Debug (Station List)', stationList)
  }

  const findFunctionalIntegrityIssues = (): boolean => {
    const stationIds: number[] = stationList.stations.map((station: IStationData) => station.id)
    const equal: boolean = isEqual(stationList.station_ids, stationIds)
    if (!equal) {
      console.log(
        'Functional integrity issues for station list',
        stationList.id,
        stationList.station_ids,
        stationIds,
      )
    }
    return !equal
  }

  return (
    <Droppable
      direction="vertical"
      droppableId={`stationList-${stationList.id}`}
      type="STATIONLIST"
    >
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <Paper
          onDoubleClick={handleDisplayDebugInfo}
          ref={provided.innerRef}
          style={{
            backgroundColor: snapshot.isDraggingOver ? lightBlue['50'] : 'inherit',
            boxShadow: 'none',
            maxHeight: isPinned ? `${screenHeight + 35}px` : 'inherit',
            overflowY: 'auto',
            position: isPinned ? 'sticky' : 'inherit',
            top: '-44px',
          }}
          sx={{ mt: 1, px: 1 }}
        >
          <Box display="flex" sx={{ py: 1 }}>
            {findFunctionalIntegrityIssues() ? (
              <Box sx={{ pt: '4px' }}>
                <Tooltip
                  title={t`Integrity issue: the stations do not match the sort list. Click to repair data.`}
                >
                  <IconButton
                    color="error"
                    onClick={handleFixFunctionalIntegrity}
                    size="small"
                    sx={{ ml: 1, p: 0 }}
                  >
                    <ReportIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <span />
            )}
            <Box>
              <Tooltip title={t`Add station`}>
                <IconButton onClick={handleCreateStationClick} size="small">
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t`Add blank area at left`}>
                <IconButton onClick={() => handleCreateStationList('left')} size="small">
                  <BorderLeftOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t`Add blank area at right`}>
                <IconButton onClick={() => handleCreateStationList('right')} size="small">
                  <BorderRightOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box flexGrow={1}>
              <span />
            </Box>
            <StationListActionsMenu
              clipboard={clipboard}
              onCreateStation={handleCreateStationClick}
              onCreateStationList={handleCreateStationList}
              onStationListDeleted={onStationListDeleted}
              onStationsMoved={onStationsMoved}
              setStationList={setStationList}
              setSubmitting={setSubmitting}
              stationList={stationList}
              stationListRef={stationListRef}
            />
          </Box>
          <Box flexDirection="row">
            {stationList.stations.length > 0 ? (
              stationList.stations.map((station: IStationData, index: number) => (
                <Draggable
                  draggableId={`station-${station.id}`}
                  index={index}
                  key={`station-${station.id}`}
                >
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{ mb: stationList.stations.length > index + 1 ? 2 : '2px' }}
                    >
                      <Station
                        onStationChanged={handleStationChanged}
                        onStationDeleted={handleStationDeleted}
                        onTermsMoved={handleTermsMoved}
                        onToolsMoved={handleToolsMoved}
                        station={station}
                      />
                    </Box>
                  )}
                </Draggable>
              ))
            ) : (
              <Box
                style={{
                  border: snapshot.isDraggingOver ? '0' : '1px dashed grey',
                  borderRadius: '4px',
                  color: 'grey',
                }}
                sx={{ p: 2, pt: 1 }}
              >
                <p>{t`BLANK AREA`}</p>
                <p>{t`Add, drag, import or paste a station here`}</p>
              </Box>
            )}
            {provided.placeholder}
          </Box>
          <ProgressBackdrop open={submitting} />
        </Paper>
      )}
    </Droppable>
  )
}

export default React.memo(StationList)
