import React, { useCallback, useEffect } from 'react'
import { Box, Grid, CircularProgress } from '@mui/material'
import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd'

import useRefState from '../utils/useRefState'
import IStudyListsData from '../types/IStudyListsData'
import IStudyListData from '../types/IStudyListData'
import IStudyData from '../types/IStudyData'
import StudyList from './StudyList'
import StudiesService from '../DataServices/StudiesService'

function StudyLists(): JSX.Element {
  console.log('Load StudyLists Component')

  const [data, dataRef, setData] = useRefState<IStudyListsData | null>(null)
  const [studyListsRefreshState, studyListsRefreshStateRef, forceStudyListsRefresh] =
    useRefState<boolean>(false)

  useEffect(() => {
    let isMounted: boolean = true
    StudiesService.getStudyListsData()
      .then((response) => {
        if (isMounted) {
          console.log('StudyLists data loaded')
          setData(response.data)
        }
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while studyLists data loading', err, err.response)
      })
    return () => {
      isMounted = false
    }
  }, [])

  const onDragEnd = (result: DropResult, provider: ResponderProvided): void => {
    const { destination, source, draggableId } = result

    if (!data || !destination) {
      return
    }

    const studyId: number = parseInt(draggableId.split('-')[1], 10)
    const srcStudyListId: number = parseInt(source.droppableId.split('-')[1], 10)
    const srcStudyListIndex: number = source.index
    const destStudyListId: number = parseInt(destination.droppableId.split('-')[1], 10)
    const destStudyListIndex: number = destination.index

    if (destStudyListId === srcStudyListId && destStudyListIndex === srcStudyListIndex) {
      return
    }

    let foundStudy: IStudyData | null = null
    let foundTargetStudyList: IStudyListData | null = null

    data.studyLists.forEach((studyList: IStudyListData) => {
      if (studyList.id === destStudyListId) {
        foundTargetStudyList = studyList
      }
      if (studyList.id === srcStudyListId) {
        const studyIndex = studyList.study_ids.indexOf(studyId)
        foundStudy = studyList.studies[studyIndex]
        studyList.study_ids.splice(studyIndex, 1)
        studyList.studies.splice(studyIndex, 1)
      }
    })

    if (foundTargetStudyList && foundStudy) {
      foundTargetStudyList = foundTargetStudyList as IStudyListData
      foundStudy = foundStudy as IStudyData
      foundTargetStudyList.study_ids.splice(destStudyListIndex, 0, foundStudy.id)
      foundTargetStudyList.studies.splice(destStudyListIndex, 0, foundStudy)
      setData({ ...data })
    }

    StudiesService.moveStudy(studyId, srcStudyListId, destStudyListId, destStudyListIndex)
      .then(() => {
        console.log('Study drag and dropped')
        // Drag and drop is confirmed before the API update to improve responsiveness
        // What do we do in the "catch" if there is an error with the API?
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while study drag and dropping', err, err.response)
      })
  }

  const handleStudiesMoved = useCallback(
    (movedStudyIds: number[], targetStudyListId: number): void => {
      // Use studyRef in a Callback function
      if (dataRef.current) {
        dataRef.current.studyLists.forEach((studyList: IStudyListData) => {
          if (studyList.id !== targetStudyListId) {
            // Remove studies from this studyList (if exists)
            studyList.studies = studyList.studies.filter(
              (study: IStudyData) => movedStudyIds.indexOf(study.id) < 0,
            )
            studyList.study_ids = studyList.study_ids.filter(
              (id: number) => movedStudyIds.indexOf(id) < 0,
            )
          }
        })
        setData({ ...dataRef.current })
        console.log('Force studyLists data refresh')
        forceStudyListsRefresh(!studyListsRefreshStateRef.current)
      }
    },
    [],
  )

  const handleStudyListChanged = useCallback((studyList: IStudyListData): void => {
    // Use studyRef in a Callback function
    if (dataRef.current) {
      const studyLists: IStudyListData[] = []
      dataRef.current.studyLists.forEach((sl: IStudyListData) => {
        studyLists.push(sl.id === studyList.id ? studyList : sl)
      })
      dataRef.current.studyLists = studyLists
      setData({ ...dataRef.current })
    }
  }, [])

  // Hack for display debug info
  const handleDisplayDebugInfo = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    console.log('Debug (StudyLists data)', data)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {data?.studyLists ?
      <Box onDoubleClick={handleDisplayDebugInfo} sx={{ m: 2 }}>
        <Grid container spacing={2}>
          {data?.studyLists.map((studyList: IStudyListData) => (
            <Grid
              key={`studyList-${studyList.id}-${studyListsRefreshState}`}
              item
              xs={12}
              sm={6}
              lg={4}
            >
              <StudyList
                onStudiesMoved={handleStudiesMoved}
                onStudyListChanged={handleStudyListChanged}
                studyList={studyList}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      : <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <CircularProgress sx={{ p: 0.5 }} />
        </Box>
      }
    </DragDropContext>
  )
}

export default React.memo(StudyLists)
