import React from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'
import FileUpload from './FileUpload'

export type FileUploadDialogProps = {
  accept?: string
  description: string
  maxSize?: number
  onClose: () => void
  onUpload: (json: string, setErrorList: React.Dispatch<React.SetStateAction<string[]>>) => void
  open: boolean
  title: string
}

function FileUploadDialog({
  accept = 'application/JSON',
  description,
  maxSize = 1048576, // 1MB (1024 * 1024)
  onClose,
  onUpload,
  open,
  title,
}: FileUploadDialogProps): JSX.Element {
  const handleUpload = (
    file: File,
    setErrorList: React.Dispatch<React.SetStateAction<string[]>>,
  ): void => {
    if (file.type.toLowerCase() !== accept.toLowerCase()) {
      setErrorList([t`Invalid file type`])
    } else if (file.size > maxSize) {
      setErrorList([t`File too large`])
    } else {
      const reader: FileReader = new FileReader()
      reader.onload = (e: ProgressEvent<FileReader>): void => {
        if (e.target) {
          const json: string = e.target.result as string
          onUpload(json, setErrorList)
        }
      }
      reader.readAsText(file)
    }
  }

  return (
    <Dialog aria-labelledby="alert-dialog-title" onClose={onClose} open={open}>
      <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
        <Box display="flex">
          <Box flexGrow={1}>{title}</Box>
          <Tooltip title={t`Close`}>
            <IconButton onClick={onClose} sx={{ color: 'text.secondary', mr: 0, p: 0 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ fontFamily: 'sans-serif', textAlign: 'center' }}>
          <FileUpload
            accept={accept}
            dropLabel={t`Drop file here`}
            hoverLabel={description}
            onUpload={handleUpload}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(FileUploadDialog)
