import React from 'react'
import axios from 'axios'
import { t } from '@lingui/macro'
import { getInitialLanguage } from './i18n'
import { IAuthData } from '../types/IUserData'

const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000/api/'
    : 'https://back.sts42.com/api/'
const headerToken = 'Bearer '

interface IAxiosObserver {
  appState: React.Dispatch<React.SetStateAction<string>> | null
  errorStateEnabled: boolean
  errorState: React.Dispatch<React.SetStateAction<string>> | null
}

export const axiosObserver: IAxiosObserver = {
  appState: null,
  errorStateEnabled: true,
  errorState: null,
}

const axiosInstance = axios.create({
  baseURL,
  timeout: 60000, // 60 seconds
  headers: {
    Authorization: localStorage.getItem('access_token')
      ? headerToken + localStorage.getItem('access_token')
      : null,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const setLocalToken = (authData: IAuthData): void => {
  localStorage.setItem('access_token', authData.access)
  localStorage.setItem('refresh_token', authData.refresh)
  localStorage.setItem('user_name', authData.user.name)
  axiosInstance.defaults.headers.Authorization = headerToken + authData.access
}

export const unsetLocalToken = (): void => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('user_name')
  axiosInstance.defaults.headers.Authorization = null
}

axiosInstance.interceptors.request.use((request) => {
  if (axiosObserver.appState) {
    axiosObserver.appState(t`.`)
  }
  request.headers['Accept-Language'] = `${getInitialLanguage()}-ca`
  return request
})

axiosInstance.interceptors.response.use(
  (response) => {
    if (axiosObserver.appState) {
      axiosObserver.appState('')
    }
    return response
  },
  (error) => {
    if (axiosObserver.appState) {
      axiosObserver.appState('')
    }
    if (axiosObserver.errorStateEnabled && axiosObserver.errorState) {
      axiosObserver.errorState('')
    }

    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.code === 'token_not_valid') {
            const refreshToken: string | null = localStorage.getItem('refresh_token')

            if (refreshToken) {
              const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]))

              // exp date in token is expressed in seconds, while now() returns milliseconds:
              const now = Math.ceil(Date.now() / 1000)

              if (tokenParts.exp > now) {
                return axiosInstance
                  .post('auth/refresh/', { refresh: refreshToken })
                  .then((response) => {
                    console.log('Axios - Access token refreshed')
                    localStorage.setItem('access_token', response.data.access)
                    if (response.data.refresh) {
                      localStorage.setItem('refresh_token', response.data.refresh)
                    }

                    axiosInstance.defaults.headers.Authorization =
                      headerToken + response.data.access
                    error.config.headers.Authorization = headerToken + response.data.access
                    return axiosInstance(error.config)
                  })
                  .catch((err) => {
                    console.log('Axios error handler - auth/refresh/', err, err.response)
                  })
              }
              unsetLocalToken() // Unnecessary 'else' after 'return'
            }
          }
          if (error.config.url !== 'auth/login/') {
            window.location.href = '/auth/login/'
          }
        }
      }
    }

    if (
      error?.response?.status !== 401 &&
      axiosObserver?.errorStateEnabled &&
      axiosObserver?.errorState
    ) {
      axiosObserver?.errorState(t`Unexpected result, please try again.`)
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
