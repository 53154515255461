import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@mui/material'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Add as AddIcon,
  BorderClearOutlined as BorderClearOutlinedIcon,
  Report as ReportIcon,
} from '@mui/icons-material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import ProgressBackdrop from '../utils/controls/ProgressBackdrop'

import useRefState from '../utils/useRefState'
import ClipboardContext, { IClipboard } from '../utils/ClipboardContext'
import IStudyData from '../types/IStudyData'
import IStationListData from '../types/IStationListData'
import IStationData from '../types/IStationData'
import IToolData from '../types/IToolData'
import ITermData from '../types/ITermData'
import StationList from '../StationList/StationList'
import StudiesService from '../DataServices/StudiesService'
import StudyDataService from '../DataServices/StudyDataService'
import StudyActionsMenu from './StudyActionsMenu'
import FormTextField from '../utils/controls/FormTextField'
import { useIdleTimer } from 'react-idle-timer'

type FormStudyFields = {
  title: string
  subtitle: string
  background: string
}

function Study(): JSX.Element {
  const { id = '0' } = useParams()
  const studyId: number = parseInt(id, 10)

  console.log('Load Study Component', studyId)

  const navigate: NavigateFunction = useNavigate()
  const isInitialStudyRender = useRef<boolean>(true)
  const [study, studyRef, setStudy] = useRefState<IStudyData | null>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const isInitialTemplatesRender = useRef<boolean>(true)
  const [templatesRefreshState, forceTemplatesRefresh] = useState<boolean>(false)
  const [stationListsRefreshState, stationListsRefreshStateRef, forceStationListsRefresh] =
    useRefState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [autoRefresh, setAutoRefresh] = React.useState(false)

  // The study component will be re-render on all clipboard changes
  const clipboard: IClipboard = useContext(ClipboardContext)

  const formSchema = yup.object().shape({
    title: yup
      .string()
      .trim(),
    subtitle: yup.string().trim(),
    background: yup.string().trim(),
  })

  const {
    formState: { errors, isDirty, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<FormStudyFields>({
    defaultValues: useMemo(() => study as FormStudyFields, [study]),
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  const onIdle = () => {
    console.log(study)
    if (autoRefresh) {
      if (study && isDirty) {
        handleSubmit(handleFormBlur)
      } else {
        StudyDataService.get(studyId)
            .then((response) => {
              const loadedStudy: IStudyData = response.data
              console.log('Study data loaded by onIdle', loadedStudy.id)
              setStudy(loadedStudy)
              forceStationListsRefresh(!stationListsRefreshState)
            })
            .catch((err) => {
              if (err.response.status === 404) {
                navigate('/')
              } else {
                console.log('ERROR: An error occurred while study data loading onIdle', err, err.response)
              }
            })
      }
      idleTimer.reset()
    }
  }

   const idleTimer = useIdleTimer({
    onIdle,
    timeout: 1000 * 10,
  })

  useEffect(() => {
    let isMounted: boolean = true
    StudyDataService.get(studyId)
      .then((response) => {
        if (isMounted) {
          const loadedStudy: IStudyData = response.data
          console.log('Study data loaded', loadedStudy.id)
          setStudy(loadedStudy)
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          navigate('/')
        } else {
          console.log('ERROR: An error occurred while study data loading', err, err.response)
        }
      })
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (isInitialStudyRender.current) {
      isInitialStudyRender.current = false
    } else if (study) {
      console.log('Study changed', study.id)
      reset(study as FormStudyFields)
      if (clipboard.studies.getItemState(study.id) !== null) {
        console.log('Update study in clipboard', study.id)
        clipboard.studies.updateItemData(study)
      }
    }
  }, [study])

  useEffect(() => {
    if (isInitialTemplatesRender.current) {
      isInitialTemplatesRender.current = false
    } else if (study) {
      console.log('Study.useEffect (Refresh Template List)')
      forceTemplatesRefresh(!templatesRefreshState)
    }
  }, [study?.isTemplate])

  const handleFormBlur = (data: FormStudyFields): void => {
    if (study && isDirty) {
      const studyToUpdate: IStudyData = { ...study, ...data }
      StudyDataService.updateOrCreate(study.id, studyToUpdate)
        .then((response) => {
          console.log('Study updated', studyToUpdate, study)
          setStudy( response.data )
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while study updating', err, err.response)
        })
    }
  }

  const handleImportFromTemplate = (templateId: number): void => {
    if (study) {
      if (isValid) {
        setSubmitting(true)
        StudiesService.injectStudyTemplateOnExisting(study.id, templateId)
          .then((response) => {
            const updatedStudy: IStudyData = response.data
            console.log('Study template injected', updatedStudy.id)
            setStudy({ ...updatedStudy })
            console.log('Force station lists refresh (handleImportFromTemplate)')
            forceStationListsRefresh(!stationListsRefreshState)
          })
          .catch((err) => {
            console.log(
              'ERROR: An error occurred while study template injecting',
              err,
              err.response,
            )
          })
          .finally(() => {
            setSubmitting(false)
          })
      }
    } else {
      console.log('Warning: Study.handleImportFromTemplate: study is null')
    }
  }

  const handleShareWith = (shareWithUserEmail: string): void => {
    if (study) {
      if (isValid) {
        setSubmitting(true)
        StudiesService.shareStudyWithUser(study.id, shareWithUserEmail)
          .then((response) => {
            console.log('Study shared with user', study.id, shareWithUserEmail)
          })
          .catch((err) => {
            console.log(
              'ERROR: An error occurred while sharing study',
              err,
              err.response,
            )
          })
          .finally(() => {
            setSubmitting(false)
          })
      }
    } else {
      console.log('Warning: Study.handleShareWith: study is null')
    }
  }


  const handleCreateStationClick = (): void => {
    if (study) {
      const newStationList: IStationListData = {
        id: 0,
        stations: [],
        station_ids: [],
        study: study.id,
      }
      StudiesService.addStationList(newStationList)
        .then((response) => {
          const createdStationList: IStationListData = response.data
          console.log('Station List created', createdStationList.id)
          const newStation: IStationData = {
            id: 0,
            title: '',
            subtitle: '',
            subject: '',
            note: '',
            tools: [],
            tool_ids: [],
            stationList: createdStationList.id,
            direction: 'row',
            isPinned: false,
            isTemplate: false,
          }
          StudiesService.addStation(newStation)
            .then((response) => {
              const createdStation: IStationData = response.data
              console.log('Station created', createdStation.id)
              createdStationList.stations.push(createdStation)
              createdStationList.station_ids.push(createdStation.id)
            })
            .catch((err) => {
              console.log('ERROR: An error occurred while station creating', err, err.response)
            })
            .finally(() => {
              // Should display the StationList even if there is a problem with creating station
              study.stationLists.push(createdStationList)
              study.stationList_ids.push(createdStationList.id)
              setStudy({ ...study })
            })
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while station list creating', err, err.response)
        })
    } else {
      console.log('Warning: Study.handleCreateStationClick: study is null')
    }
  }

  const handleCreateStationListClick = (): void => {
    if (study) {
      const newStationList: IStationListData = {
        id: 0,
        stations: [],
        station_ids: [],
        study: study.id,
      }
      StudiesService.addStationList(newStationList)
        .then((response) => {
          const createdStationList: IStationListData = response.data
          console.log('Station List created', createdStationList.id)
          study.stationLists.push(createdStationList)
          study.stationList_ids.push(createdStationList.id)
          setStudy({ ...study })
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while station list creating', err, err.response)
        })
    } else {
      console.log('Warning: Study.handleCreateStationClick: study is null')
    }
  }

  const onDragStationEnd = (
    stationId: number,
    srcStationListId: number,
    destStationListId: number,
    destIndex: number,
  ): void => {
    if (!study) {
      return
    }

    let foundStation: IStationData | null = null
    let foundTargetStationList: IStationListData | null = null

    study.stationLists.forEach((stationList: IStationListData) => {
      if (stationList.id === destStationListId) {
        foundTargetStationList = stationList
      }
      if (stationList.id === srcStationListId) {
        const stationIndex = stationList.station_ids.indexOf(stationId)
        foundStation = stationList.stations[stationIndex]
        stationList.station_ids.splice(stationIndex, 1)
        stationList.stations.splice(stationIndex, 1)
      }
    })

    if (foundTargetStationList && foundStation) {
      foundTargetStationList = foundTargetStationList as IStationListData
      foundStation = foundStation as IStationData
      foundTargetStationList.station_ids.splice(destIndex, 0, foundStation.id)
      foundTargetStationList.stations.splice(destIndex, 0, foundStation)
      setStudy({ ...study })
      if (foundStation.isPinned) {
        console.log('Force station lists refresh (onDragStationEnd)')
        forceStationListsRefresh(!stationListsRefreshState)
      }

      StudiesService.moveStation(stationId, srcStationListId, destStationListId, destIndex)
        .then(() => {
          console.log(
            'Station drag and dropped',
            stationId,
            srcStationListId,
            destStationListId,
            destIndex,
          )
          // Drag and drop is confirmed before the API update to improve responsiveness
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while station drag and dropping', err, err.response)
        })
    } else {
      console.log('ERROR: Station List or Station not found', foundTargetStationList, foundStation)
    }
  }

  const onDragToolEnd = (
    toolId: number,
    srcStationId: number,
    destStationId: number,
    destIndex: number,
  ): void => {
    if (!study) {
      return
    }

    let foundTool: IToolData | null = null
    let foundTargetStation: IStationData | null = null

    study.stationLists.forEach((stationList: IStationListData) => {
      stationList.stations.forEach((station: IStationData) => {
        if (station.id === destStationId) {
          foundTargetStation = station
        }
        if (station.id === srcStationId) {
          const toolIndex = station.tool_ids.indexOf(toolId)
          foundTool = station.tools[toolIndex]
          station.tool_ids.splice(toolIndex, 1)
          station.tools.splice(toolIndex, 1)
        }
      })
    })

    if (foundTargetStation && foundTool) {
      foundTargetStation = foundTargetStation as IStationData
      foundTool = foundTool as IToolData
      foundTargetStation.tool_ids.splice(destIndex, 0, foundTool.id)
      foundTargetStation.tools.splice(destIndex, 0, foundTool)
      setStudy({ ...study })

      StudiesService.moveTool(toolId, srcStationId, destStationId, destIndex)
        .then(() => {
          console.log('Tool drag and dropped', toolId, srcStationId, destStationId, destIndex)
          // Drag and drop is confirmed before the API update to improve responsiveness
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while tool drag and dropping', err, err.response)
        })
    } else {
      console.log('ERROR: Station or Tool not found', foundTargetStation, foundTool)
    }
  }

  const onDragTermEnd = (
    termId: number,
    srcToolId: number,
    destToolId: number,
    destIndex: number,
  ): void => {
    if (!study) {
      return
    }

    let foundTerm: ITermData | null = null
    let foundTargetTool: IToolData | null = null

    study.stationLists.forEach((stationList: IStationListData) => {
      stationList.stations.forEach((station: IStationData) => {
        station.tools.forEach((tool: IToolData) => {
          if (tool.id === destToolId) {
            foundTargetTool = tool
          }
          if (tool.id === srcToolId) {
            const termIndex = tool.term_ids.indexOf(termId)
            foundTerm = tool.terms[termIndex]
            tool.term_ids.splice(termIndex, 1)
            tool.terms.splice(termIndex, 1)
          }
        })
      })
    })

    if (foundTargetTool && foundTerm) {
      foundTargetTool = foundTargetTool as IToolData
      foundTerm = foundTerm as ITermData
      foundTargetTool.term_ids.splice(destIndex, 0, foundTerm.id)
      foundTargetTool.terms.splice(destIndex, 0, foundTerm)
      setStudy({ ...study })

      StudiesService.moveTerm(termId, srcToolId, destToolId, destIndex)
        .then(() => {
          console.log('Term drag and dropped', termId, srcToolId, destToolId, destIndex)
          // Drag and drop is confirmed before the API update to improve responsiveness
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while term drag and dropping', err, err.response)
        })
    } else {
      console.log('ERROR: Tool or Term not found', foundTargetTool, foundTerm)
    }
  }

  const onDragEnd = (result: DropResult, provider: ResponderProvided): void => {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    const id: number = parseInt(draggableId.split('-')[1], 10)
    const srcId: number = parseInt(source.droppableId.split('-')[1], 10)
    const srcIndex: number = source.index
    const destId: number = parseInt(destination.droppableId.split('-')[1], 10)
    const destIndex: number = destination.index

    if (destId === srcId && destIndex === srcIndex) {
      return
    }

    switch (result.type) {
      case 'STATIONLIST':
        onDragStationEnd(id, srcId, destId, destIndex)
        break
      case 'STATION':
        onDragToolEnd(id, srcId, destId, destIndex)
        break
      case 'TOOL':
        onDragTermEnd(id, srcId, destId, destIndex)
        break
      default:
        console.log('Unknown type for drag&drop', result.type)
    }
  }

  const handleStationsMoved = useCallback(
    (movedStationIds: number[], targetStationListId: number): void => {
      // Use studyRef in a Callback function
      if (studyRef.current) {
        studyRef.current.stationLists.forEach((stationList: IStationListData) => {
          if (stationList.id !== targetStationListId) {
            // Remove stations from this stationList (if exists)
            stationList.stations = stationList.stations.filter(
              (station: IStationData) => movedStationIds.indexOf(station.id) < 0,
            )
            stationList.station_ids = stationList.station_ids.filter(
              (id: number) => movedStationIds.indexOf(id) < 0,
            )
          }
        })
        setStudy({ ...studyRef.current })
        console.log('Force station lists refresh (handleStationsMoved)')
        forceStationListsRefresh(!stationListsRefreshStateRef.current)
      } else {
        console.log('Warning: Study.handleStationsMoved: study is null')
      }
    },
    [],
  )

  const handleToolsMoved = useCallback((movedToolIds: number[], targetStationId: number): void => {
    // Use studyRef in a Callback function
    if (studyRef.current) {
      studyRef.current.stationLists.forEach((stationList: IStationListData) => {
        stationList.stations.forEach((station: IStationData) => {
          if (station.id !== targetStationId) {
            // Remove tools from this station (if exists)
            station.tools = station.tools.filter(
              (tool: IToolData) => movedToolIds.indexOf(tool.id) < 0,
            )
            station.tool_ids = station.tool_ids.filter((id: number) => movedToolIds.indexOf(id) < 0)
          }
        })
      })
      setStudy({ ...studyRef.current })
      console.log('Force station lists refresh (handleToolsMoved)')
      forceStationListsRefresh(!stationListsRefreshStateRef.current)
    } else {
      console.log('Warning: Study.handleToolsMoved: study is null')
    }
  }, [])

  const handleTermsMoved = useCallback((movedTermIds: number[], targetToolId: number): void => {
    // Use studyRef in a Callback function
    if (studyRef.current) {
      studyRef.current.stationLists.forEach((stationList: IStationListData) => {
        stationList.stations.forEach((station: IStationData) => {
          station.tools.forEach((tool: IToolData) => {
            if (tool.id !== targetToolId) {
              // Remove terms from this tool (if exists)
              tool.terms = tool.terms.filter((term: ITermData) => movedTermIds.indexOf(term.id) < 0)
              tool.term_ids = tool.term_ids.filter((id: number) => movedTermIds.indexOf(id) < 0)
            }
          })
        })
      })
      setStudy({ ...studyRef.current })
      console.log('Force station lists refresh (handleTermsMoved)')
      forceStationListsRefresh(!stationListsRefreshStateRef.current)
    } else {
      console.log('Warning: Study.handleTermsMoved: study is null')
    }
  }, [])

  const handleCreateStationList = (stationListId: number, position: 'left' | 'right'): void => {
    if (study) {
      let destIndex = study.stationList_ids.indexOf(stationListId)
      if (position === 'right') {
        destIndex += 1
      }
      const newStationList: IStationListData = {
        id: 0,
        stations: [],
        station_ids: [],
        study: study.id,
      }
      StudiesService.insertStationList(destIndex, newStationList)
        .then((response) => {
          const createdStationList: IStationListData = response.data
          console.log('Station List created', createdStationList.id)
          study.stationLists.splice(destIndex, 0, createdStationList)
          study.stationList_ids.splice(destIndex, 0, createdStationList.id)
          setStudy({ ...study })
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while station list creating', err, err.response)
        })
    } else {
      console.log('Warning: Study.handleCreateStationList: study is null')
    }
  }

  const handleStationListChanged = useCallback((stationList: IStationListData): void => {
    // Use studyRef in a Callback function
    if (studyRef.current) {
      const stationLists: IStationListData[] = []
      studyRef.current.stationLists.forEach((sl: IStationListData) => {
        stationLists.push(sl.id === stationList.id ? stationList : sl)
      })
      studyRef.current.stationLists = stationLists
      setStudy({ ...studyRef.current })
    } else {
      console.log('Warning: Study.handleStationListChanged: study is null')
    }
  }, [])

  const handleStationListDeleted = useCallback((stationListId: number): void => {
    // Use studyRef in a Callback function
    if (studyRef.current) {
      studyRef.current.stationLists = studyRef.current.stationLists.filter(
        (sl: IStationListData) => sl.id !== stationListId,
      )
      studyRef.current.stationList_ids = studyRef.current.stationList_ids.filter(
        (id: number) => id !== stationListId,
      )
      setStudy({ ...studyRef.current })
    } else {
      console.log('Warning: Study.handleStationListDeleted: study is null')
    }
  }, [])

  const handleFixFunctionalIntegrity = (): void => {
    if (study) {
      setSubmitting(true)
      StudiesService.fixFunctionalIntegrityOfStudy(study.id)
        .then((response) => {
          const fixedStudy: IStudyData = response.data
          console.log('Study fixed', fixedStudy.id)
          setStudy({ ...fixedStudy })
          enqueueSnackbar(t`Study fixed correctly`, { variant: 'success' })
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while study fixing', err, err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      console.log('Warning: Study.handleFixFunctionalIntegrity: study is null')
    }
  }

  const findFunctionalIntegrityIssues = (study: IStudyData): boolean => {
    const stationListIds: number[] = study.stationLists.map((sl: IStationListData) => sl.id)
    const equal: boolean = isEqual(study.stationList_ids, stationListIds)
    if (!equal) {
      console.log(
        'Functional integrity issues for study',
        study.id,
        study.stationList_ids,
        stationListIds,
      )
    }
    return !equal
  }

  const nbStationsMaxCols = (stations: IStationData[]): number => {
    let maxNbCols: number = 1
    stations.forEach((station: IStationData) => {
      const nbTools: number = station.tools.length > 0 ? station.tools.length : 1
      const nbCols: number = station.direction === 'row' ? nbTools : 1
      if (nbCols > maxNbCols) {
        maxNbCols = nbCols
      }
    })
    return maxNbCols
  }

  const getGridCols = (
    nbStationLists: number,
    nbTotalCols: number,
    maxNbCols: number,
    stationList: IStationListData,
  ): number => {
    if (!study || nbStationLists === 1 || maxNbCols === 1) {
      return 12
    }
    const stationListNbCols: number = nbStationsMaxCols(stationList.stations)
    const nbRows: number = Math.ceil(nbTotalCols / maxNbCols)
    const nbCols: number = Math.ceil(nbTotalCols / nbRows)
    let gridCols: number = (12 / nbCols) * stationListNbCols
    if (gridCols > 12) {
      gridCols = 12
    }
    return gridCols
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    console.log('Debug (Study)', study)
  }

  const handleAutoRefreshChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAutoRefresh(event.target.checked);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {study ? (
        <Droppable direction="horizontal" droppableId={`study-${study.id}`} type="STUDY">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <Paper
              onDoubleClick={handleDisplayDebugInfo}
              ref={provided.innerRef}
              style={{
                border: 0,
                borderRadius: 0,
                boxShadow: 'none',
              }}
              sx={{ mt: 1, p: 1 }}
            >
              <Box component="form" noValidate onBlur={handleSubmit(handleFormBlur)}>
                <Box sx={{ pl: 1 }}>
                  {findFunctionalIntegrityIssues(study) ? (
                    <Box sx={{ pt: '2px' }}>
                      <Tooltip
                        title={t`Integrity issue: the stations do not match the sort list. Click to repair data.`}
                      >
                        <IconButton
                          color="error"
                          onClick={handleFixFunctionalIntegrity}
                          size="small"
                          sx={{ mr: '5px', px: 0 }}
                        >
                          <ReportIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <span />
                  )}
                  <Box display={'flex'} sx={{ width: '100%', justifyContent: 'space-between' }} >
                      <FormTextField
                        autoComplete='off'
                        placeholder={t`Title of the study`}
                        errorText={errors.title?.message}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: '1.5rem',
                            letterSpacing: '0.1px',
                          },
                        }}
                        multiline
                        registerReturn={register('title')}
                        variant="standard"
                      />
                      {study?.contributingUser_ids?.length > 0 && (
                        <Box pr="5px" >
                          <Tooltip title={t`Allows you to view collaborators' actions. This can cause occasional loss of data.`}>
                            <FormControlLabel
                              control={<Switch color="primary" onChange={handleAutoRefreshChange} value={autoRefresh} />}
                              label="Autorefresh"
                              labelPlacement="start"
                              sx={{ color: '#999' }}
                            />
                          </Tooltip>
                        </Box>
                      )}
                    <StudyActionsMenu
                      clipboard={clipboard}
                      onCreateStation={handleCreateStationClick}
                      onCreateStationList={handleCreateStationListClick}
                      onImportFromTemplate={handleImportFromTemplate}
                      onShareWith={handleShareWith}
                      setStudy={setStudy}
                      setSubmitting={setSubmitting}
                      study={study}
                      studyRef={studyRef}
                      templatesRefreshState={templatesRefreshState}
                    />
                  </Box>
                </Box>
                <Box sx={{ pl: 1 }}>
                  <FormTextField
                    autoComplete='off'
                    placeholder={t`Subtitle`}
                    errorText={errors.subtitle?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: '0.9rem',
                        letterSpacing: '0.01px',
                      },
                    }}
                    multiline
                    registerReturn={register('subtitle')}
                    variant="standard"
                  />
                  <FormTextField
                    autoComplete='off'
                    placeholder={t`Background`}
                    errorText={errors.background?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: '0.9rem',
                        letterSpacing: '0.01px',
                      },
                    }}
                    multiline
                    registerReturn={register('background')}
                    variant="standard"
                  />
                </Box>
              </Box>
              {study.stationLists.length === 0 ? (
                <Box>
                  <Tooltip title={t`Add area with station`}>
                    <IconButton onClick={handleCreateStationClick} size="small">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t`Add blank area`}>
                    <IconButton onClick={handleCreateStationListClick} size="small">
                      <BorderClearOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <span />
              )}
              <Grid container>
                {study.stationLists.map((stationList: IStationListData, index: number) => {
                  const nbStationLists: number = study.stationLists.length
                  const nbTotalCols: number = study.stationLists.reduce(
                    (nb: number, stationList: IStationListData) =>
                      nb + nbStationsMaxCols(stationList.stations),
                    0,
                  )
                  return (
                    <Grid
                      key={`stationList-${stationList.id}-${stationListsRefreshState}`}
                      item
                      xs={getGridCols(nbStationLists, nbTotalCols, 1, stationList)}
                      sm={getGridCols(nbStationLists, nbTotalCols, 2, stationList)}
                      md={getGridCols(nbStationLists, nbTotalCols, 3, stationList)}
                      lg={getGridCols(nbStationLists, nbTotalCols, 4, stationList)}
                      xl={getGridCols(nbStationLists, nbTotalCols, 6, stationList)}
                    >
                      <StationList
                        onCreateStationList={handleCreateStationList}
                        onStationListChanged={handleStationListChanged}
                        onStationListDeleted={handleStationListDeleted}
                        onStationsMoved={handleStationsMoved}
                        onTermsMoved={handleTermsMoved}
                        onToolsMoved={handleToolsMoved}
                        stationList={stationList}
                      />
                    </Grid>
                  )
                })}
                {provided.placeholder}
              </Grid>
              <ProgressBackdrop open={submitting} />
            </Paper>
          )}
        </Droppable>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
          }}
        >
          <CircularProgress sx={{ p: 0.5 }} />
        </Box>
      )}
    </DragDropContext>
  )
}

export default React.memo(Study)
