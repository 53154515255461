import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'

import IToolData, {
  getTypeToolText,
  TOOL_TYPES,
  LANGUAGES,
  getLanguageText,
} from '../types/IToolData'
import ToolDataService from '../DataServices/ToolDataService'
import Checkbox from '../utils/controls/Checkbox'
import Select, { SelectMenuItemData } from '../utils/controls/Select'

type ToolConfigDialogProps = {
  onClose: () => void
  open: boolean
  setTool: React.Dispatch<React.SetStateAction<IToolData>>
  tool: IToolData
}

function ToolConfigDialog({ onClose, open, setTool, tool }: ToolConfigDialogProps): JSX.Element {
  const [isTemplate, setIsTemplate] = useState<boolean>(tool.isTemplate)
  const [sourceLanguage, setSourceLanguage] = useState<string>(tool.sourceLanguage)
  const [destinationLanguage, setDestinationLanguage] = useState<string>(tool.destinationLanguage)
  const [toolType, setToolType] = useState<string>(tool.toolType)

  const handleSaveDialog = (): void => {
    if (
      isTemplate !== tool.isTemplate ||
      sourceLanguage !== tool.sourceLanguage ||
      destinationLanguage !== tool.destinationLanguage ||
      toolType !== tool.toolType
    ) {
      const toolToUpdate: IToolData = {
        ...tool,
        ...{ isTemplate, toolType, sourceLanguage, destinationLanguage },
      }
      ToolDataService.updateOrCreate(tool.id, toolToUpdate)
        .then(() => {
          console.log('Tool config updated', toolToUpdate.id)
          setTool({ ...toolToUpdate })
          onClose()
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while tool config updating', err, err.response)
        })
    } else {
      onClose()
    }
  }

  const handleCloseDialog = (): void => {
    setIsTemplate(tool.isTemplate)
    setSourceLanguage(tool.sourceLanguage)
    setDestinationLanguage(tool.destinationLanguage)
    setToolType(tool.toolType)
    onClose()
  }

  const getLanguageData = (): SelectMenuItemData[] => {
    const languageData: SelectMenuItemData[] = []

    LANGUAGES.forEach((language: string) => {
      languageData.push({ value: language, text: getLanguageText(language) })
    })

    languageData.sort((a, b) => (a.text > b.text ? 1 : -1))
    return languageData
  }

  const getToolTypeData = (): SelectMenuItemData[] => {
    let allFound = false
    const toolTypeData: SelectMenuItemData[] = []

    TOOL_TYPES.forEach((toolType: string) => {
      if (toolType !== 'all') {
        toolTypeData.push({ value: toolType, text: getTypeToolText(toolType) })
      } else {
        allFound = true
      }
    })

    toolTypeData.sort((a, b) => (a.text > b.text ? 1 : -1))
    if (allFound) {
      // If "all" tool type exists, it's put in the first position
      toolTypeData.splice(0, 0, { value: 'all', text: getTypeToolText('all') })
    }
    return toolTypeData
  }

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={handleCloseDialog}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
        <Box display="flex">
          <Box flexGrow={1}>
            {`${t`Configure tool`}${t`:`} ${
              tool.toolType ? getTypeToolText(tool.toolType) : tool.title
            }`}
          </Box>
          <Tooltip title={t`Close`}>
            <IconButton onClick={onClose} sx={{ color: 'text.secondary', mr: 0, p: 0 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2 }}>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '0.95rem', pb: 4 }}>
          {t`Here are the additional options available for this tool.`}
          {` ${t`Selecting a tool type allows you to activate the search functionality.`}`}
        </DialogContentText>
        <Box>
          <Checkbox
            checked={isTemplate}
            color="primary"
            label={
              <Typography sx={{ fontSize: '0.95rem' }}>{t`This tool is a template`}</Typography>
            }
            labelPlacement="end"
            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
              setIsTemplate(checked)
            }
            sx={{ py: 0 }}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Select
            label={t`Source Language`}
            onChange={(e: SelectChangeEvent<string>) => setSourceLanguage(e.target.value)}
            options={getLanguageData()}
            sx={{ fontSize: '0.95rem' }}
            value={sourceLanguage}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Select
            label={t`Destination Language`}
            onChange={(e: SelectChangeEvent<string>) => setDestinationLanguage(e.target.value)}
            options={getLanguageData()}
            sx={{ fontSize: '0.95rem' }}
            value={destinationLanguage}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Select
            label={t`Tool type`}
            onChange={(e: SelectChangeEvent<string>) => setToolType(e.target.value)}
            options={getToolTypeData()}
            sx={{ fontSize: '0.95rem' }}
            value={toolType}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCloseDialog} size="small" variant="outlined">{t`Cancel`}</Button>
        <Button
          autoFocus
          onClick={handleSaveDialog}
          size="small"
          variant="contained"
        >{t`Save`}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ToolConfigDialog)
