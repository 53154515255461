import React, { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Theme, useTheme } from '@mui/material/styles'
import { blue } from '@mui/material/colors'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Assistant as AssistantIcon, Folder as FolderIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'

import { RotationalExpandMoreIcon } from '../utils/controls/RotationalIcons'
import IStudyData from '../types/IStudyData'

type StudyCardProps = {
  study: IStudyData
}

function StudyCard({ study }: StudyCardProps): JSX.Element {
  console.log('Load StudyCard Component', study.id)

  const navigate: NavigateFunction = useNavigate()
  const theme: Theme = useTheme()
  const isSmDown: boolean = useMediaQuery(theme.breakpoints.down('sm'))
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleEnterClick = () => {
    navigate(`/study/${study.id}`)
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    console.log('Debug (StudyCard)', study)
  }

  return (
    <Card variant="outlined" onDoubleClick={handleDisplayDebugInfo} sx={{ m: 1 }}>
      <CardHeader
        {...(!isSmDown
          ? {
              avatar: (
                <Avatar aria-label={study.title} sx={{ bgcolor: blue[500] }}>
                  {study.title ? study.title.substring(0, 1) : t`Untitled study`[0]}
                </Avatar>
              ),
            }
          : {})}
        disableTypography
        title={
          <Box display="flex">
            <Box flexGrow={1}>
            {study.title ? (
              <Typography color="textPrimary" variant="body2">
                {study.title}
              </Typography>
            ) : (
              <Typography color="textSecondary" variant="body2">
                {t`Untitled study`}
              </Typography>
            )}
            </Box>
            {study.isHelper ? (
              <Box sx={{ ml: '2px', mt: '2px' }}>
                <Tooltip title={t`This study is a helper`}>
                  <AssistantIcon color="info" fontSize="small" sx={{ m: 0 }} />
                </Tooltip>
              </Box>
            ) : (
              <span />
            )}
            {study.isTemplate ? (
              <Box sx={{ ml: '2px' }}>
                <Tooltip title={t`This study is a template`}>
                  <FolderIcon color="info" sx={{ m: 0 }} />
                </Tooltip>
              </Box>
            ) : (
              <span />
            )}
          </Box>
        }
        subheader={
          <Typography color="textSecondary" variant="body2">
            {study.subtitle || <span>&nbsp;</span>}
          </Typography>
        }
      />
      <CardActions sx={{ justifyContent: study.background ? 'space-between' : 'flex-end' }}>
        {study.background && (
          <IconButton aria-label={t`Show more`} onClick={handleExpandClick} sx={{ pt: '6px' }}>
            <RotationalExpandMoreIcon degrees={expanded ? 0 : -90} />
          </IconButton>
        )}
        <Button onClick={handleEnterClick} size="small" sx={{ mr: 1 }}>
          {t`Enter`}
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph variant="body2" textAlign="justify">
            {study.background}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default React.memo(StudyCard)
