import React, {useEffect, useMemo, useState} from 'react'
import FormTextField from "./FormTextField";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {SelectChangeEvent} from "@mui/material/Select";

type ShareWithDialogProps = {
  description: string
  onClose: () => void
  onShareWith: (userEmail: string) => void
  open: boolean
  title: string
}

function ShareWithDialog({
  description,
  onClose,
  onShareWith,
  open,
  title,
}: ShareWithDialogProps): JSX.Element {

  const [userEmail, setUserEmail] = useState<string>('')

  useEffect(() => {
    let isMounted: boolean = true
    console.log("ShareWithDialog useEffect")
    return () => {
      isMounted = false
    }
  }, [])

  const handleShareWithClick = (): void => {
    if (userEmail !== '') {
      onClose()
      setUserEmail('')
      onShareWith(userEmail)
    }
  }

  const handleEmailChange = (value: string): void => {
    setUserEmail(value)
  }

  const handleCloseDialog = (): void => {
    setUserEmail('')
    onClose()
  }

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={handleCloseDialog}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
        <Box display="flex">
          <Box flexGrow={1}>{title}</Box>
          <Tooltip title={t`Close`}>
            <IconButton onClick={onClose} sx={{ color: 'text.secondary', mr: 0, p: 0 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2 }}>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '0.95rem', pb: 4 }}>
          {description}
        </DialogContentText>
        <FormTextField
          autoComplete='off'
          errorText=""
          label={t`Email address`}
          name="userEmail"
          sx={{ fontSize: '0.95rem' }}
          value={userEmail}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleEmailChange(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCloseDialog} size="small" variant="outlined">{t`Cancel`}</Button>
        <Button
          autoFocus
          onClick={handleShareWithClick}
          size="small"
          variant="contained"
        >{t`Share`}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ShareWithDialog)
