import React, {useRef, useState} from 'react'
import {Button, IconButton, Tooltip} from '@mui/material'
import MicIcon from '@mui/icons-material/Mic';
import MicNoneIcon from '@mui/icons-material/MicNone';
import StudiesService from '../DataServices/StudiesService'
import IStudyData from "../types/IStudyData";
import { NavigateFunction, useNavigate } from 'react-router-dom'
import {t} from "@lingui/macro";

type TVoiceCommandProps = {
  command: string;
  context: string;
  onCommandExecuted: () => void;
};

const VoiceCommand: React.FC <TVoiceCommandProps> = ({command, context, onCommandExecuted }) =>  {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const audioChunksRef = useRef<BlobPart[]>([])
  const audioContextRef = useRef<AudioContext | null>(null)
  const audioSourceRef = useRef<AudioBufferSourceNode | null>(null)
  const [recording, setRecording] = useState(false)
  const [playing, setPlaying] = useState(false)

  const handleVoice = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const mediaRecorder = new MediaRecorder(stream)

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data)
        }
      }

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' })
        audioChunksRef.current = []
        // const context = new AudioContext()
        // const fileReader = new FileReader()

        StudiesService.voiceCommand(audioBlob, command, context )
          .then(response => {
            const data = response.data
            console.log(data)
            onCommandExecuted()
          })
          .catch(err => {
            console.log('ERROR: An error occurred getting the transcription', err, err.response)
          })
          .finally(() => {
            console.log('done handling audio file')
          })
      }
      mediaRecorderRef.current = mediaRecorder
      mediaRecorder.start()
      setRecording(true)
    } catch (err) {
      console.error('Error accessing microphone:', err)
    }
  }

  const handleStopRecording = () => {
    const mediaRecorder = mediaRecorderRef.current
    console.log(mediaRecorder)
    if (mediaRecorder /*&& mediaRecorder.state !== 'inactive'*/) {
      console.log(mediaRecorder.state)
      mediaRecorder.stop()
      setRecording(false)
    }
  }


  return (
    <div>
        <Tooltip title={t`Speak your command`}>
          <IconButton color="primary" onClick={recording ? handleStopRecording : handleVoice} size="medium" sx={{ px: 0, ml: '5px' }}>
            {recording ? <MicIcon fontSize="small" sx={{ p: '0px' }} /> : <MicNoneIcon fontSize="small" sx={{ p: '0px' }} />}
          </IconButton>
        </Tooltip>
    </div>
  )
}

export default VoiceCommand
