import yup from '../utils/yupLocale'

export default interface ITermData {
  id: number
  term: string
  note: string
  hyperlink: string
  tool: number
}

export const TermExportProps: string[] = ['term', 'note', 'hyperlink']

export const TermImportSchema = yup.object().shape({
  term: yup.string().required(),
  note: yup.string().defined(),
  hyperlink: yup.string().defined().max(500).url(),
})

export const serializeTerm = (term: ITermData): string => JSON.stringify(term, TermExportProps)
