import { AxiosResponse } from 'axios'
import axiosInstance from '../utils/axios'
import IStationData from '../types/IStationData'

const rootPath = '/studies/stations'

const getAll = (): Promise<AxiosResponse<IStationData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<IStationData[]>>(rootPath)

const get = (id: number): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.get<unknown, AxiosResponse<IStationData>>(`${rootPath}/${id}`)

const create = (data: IStationData): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(`${rootPath}/`, data)

const updateOrCreate = (id: number, data: IStationData): Promise<AxiosResponse<IStationData>> => {
  if (id > 0) {
    return axiosInstance.put<IStationData, AxiosResponse<IStationData>>(`${rootPath}/${id}/`, data)
  }
  return create(data)
}

const remove = (id: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(`${rootPath}/${id}/`)

const removeAll = (): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(rootPath)

const StationDataService = {
  getAll,
  get,
  create,
  updateOrCreate,
  remove,
  removeAll,
}

export default StationDataService
