import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { blueGrey, lightBlue, green } from '@mui/material/colors'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, Grid, IconButton, Paper, Tooltip } from '@mui/material'
import { Add as AddIcon, PushPin as PushPinIcon, Report as ReportIcon } from '@mui/icons-material'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import {
  RotationalArrowDownwardIcon,
  RotationalPushPinOutlinedIcon,
} from '../utils/controls/RotationalIcons'
import ProgressBackdrop from '../utils/controls/ProgressBackdrop'
import useRefState from '../utils/useRefState'
import ClipboardContext, { IClipboard } from '../utils/ClipboardContext'
import IStationData from '../types/IStationData'
import IToolData from '../types/IToolData'
import Tool from '../Tool/Tool'
import StationDataService from '../DataServices/StationDataService'
import StudiesService from '../DataServices/StudiesService'
import StationActionsMenu from './StationActionsMenu'
import FormTextField from '../utils/controls/FormTextField'

type StationProps = {
  onStationChanged: (station: IStationData) => void
  onStationDeleted: (stationId: number) => void
  onTermsMoved: (movedTermIds: number[], targetToolId: number) => void
  onToolsMoved: (movedToolIds: number[], targetStationId: number) => void
  station: IStationData
}

type FormStationFields = {
  title: string
  subtitle: string
  subject: string
}

function Station({
  onStationChanged,
  onStationDeleted,
  onTermsMoved,
  onToolsMoved,
  station: initStation,
}: StationProps): JSX.Element {
  console.log('Load Station Component', initStation.id)

  const isInitialStationRender = useRef<boolean>(true)
  const [station, stationRef, setStation] = useRefState<IStationData>(initStation)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [toolsRefreshState, forceToolsRefresh] = useState<boolean>(false)

  // The station component will be re-render on all clipboard changes
  const clipboard: IClipboard = useContext(ClipboardContext)

  const formSchema = yup.object().shape({
    title: yup
      .string()
      .trim(),
    subtitle: yup.string().trim(),
    subject: yup.string().trim(),
  })

  const {
    formState: { dirtyFields, errors, isDirty, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<FormStationFields>({
    defaultValues: useMemo(() => station as FormStationFields, [station]),
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (isInitialStationRender.current) {
      isInitialStationRender.current = false
    } else {
      console.log('Station changed (triggers onStationChanged)', station.id)
      reset(station as FormStationFields)
      onStationChanged(station)
      if (clipboard.stations.getItemState(station.id) !== null) {
        console.log('Update station in clipboard', station.id)
        clipboard.stations.updateItemData(station)
      }
    }
  }, [station])

  const submitForm = (): Promise<boolean> => {
    const promise = new Promise<boolean>((resolve, reject) => {
      if (isDirty && isValid) {
        const data: FormStationFields = getValues()
        const stationToUpdate: IStationData = { ...station, ...data }
        StationDataService.updateOrCreate(station.id, stationToUpdate)
          .then(() => {
            console.log('Station updated', stationToUpdate.id)
            setStation({ ...stationToUpdate })
            resolve(true)
          })
          .catch((err) => {
            console.log('ERROR: An error occurred while station updating', err, err.response)
            reject(err)
          })
      } else {
        resolve(false)
      }
    })
    return promise
  }

  const loadStationFromAPI = (): void => {
    if (!submitting) {
      setSubmitting(true)
      StudiesService.loadStationFromApi(station.id)
        .then((response) => {
          const updatedStation = response.data
          console.log('Station loaded from API', updatedStation.id)
          setStation({ ...updatedStation })
          console.log('Force tools refresh')
          forceToolsRefresh(!toolsRefreshState)
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while station loading from API', err, err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleFormBlur = (data: FormStationFields): void => {
    const subjectChanged: boolean = dirtyFields.subject || false
    submitForm().then(() => {
      if (subjectChanged) {
        loadStationFromAPI()
      }
    })
  }

  const handleSubjectKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault()
      submitForm().then(() => {
        loadStationFromAPI()
      })
    }
  }

  const handleChangeDirectionClick = (): void => {
    const updateStation = { ...station }
    updateStation.direction = station.direction === 'row' ? 'column' : 'row'
    StationDataService.updateOrCreate(station.id, updateStation)
      .then(() => {
        console.log('Station updated (direction)', updateStation.id)
        setStation({ ...updateStation })
      })
      .catch((err) => {
        console.log(
          'ERROR: An error occurred while station updating (direction)',
          err,
          err.response,
        )
      })
  }

  const handleChangePinClick = (): void => {
    const updateStation = { ...station }
    updateStation.isPinned = !station.isPinned
    StationDataService.updateOrCreate(station.id, updateStation)
      .then(() => {
        console.log('Station updated (isPinned)', updateStation.id)
        setStation({ ...updateStation })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while station updating (isPinned)', err, err.response)
      })
  }

  const handleCreateToolClick = (): void => {
    const newTool: IToolData = {
      id: 0,
      title: '',
      subtitle: '',
      note: '',
      terms: [],
      term_ids: [],
      station: station.id,
      sourceLanguage: '',
      destinationLanguage: '',
      toolType: '',
      isTemplate: false,
    }
    StudiesService.addTool(newTool)
      .then((response) => {
        const createdTool: IToolData = response.data
        console.log('Tool created', createdTool.id)
        station.tools.push(createdTool)
        station.tool_ids.push(createdTool.id)
        setStation({ ...station })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while tool creating', err, err.response)
      })
  }

  const handleTermsMoved = useCallback((movedTermIds: number[], targetToolId: number): void => {
    onTermsMoved(movedTermIds, targetToolId)
  }, [])

  const handleToolChanged = useCallback((tool: IToolData): void => {
    // Use stationRef in a Callback function
    const tools: IToolData[] = []
    stationRef.current.tools.forEach((t: IToolData) => {
      tools.push(t.id === tool.id ? tool : t)
    })
    stationRef.current.tools = tools
    setStation({ ...stationRef.current })
  }, [])

  const handleToolDeleted = useCallback((toolId: number): void => {
    // Use stationRef in a Callback function
    stationRef.current.tools = stationRef.current.tools.filter(
      (tool: IToolData) => tool.id !== toolId,
    )
    stationRef.current.tool_ids = stationRef.current.tool_ids.filter((id: number) => id !== toolId)
    setStation({ ...stationRef.current })
  }, [])

  const handleFixFunctionalIntegrity = (): void => {
    setSubmitting(true)
    StudiesService.fixFunctionalIntegrityOfStation(station.id)
      .then((response) => {
        const fixedStation: IStationData = response.data
        console.log('Station fixed', fixedStation.id)
        setStation({ ...fixedStation })
        enqueueSnackbar(t`Station fixed correctly`, { variant: 'success' })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while station fixing', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    console.log('Debug (Station)', station)
  }

  const findFunctionalIntegrityIssues = (): boolean => {
    const toolIds: number[] = station.tools.map((tool: IToolData) => tool.id)
    const equal: boolean = isEqual(station.tool_ids, toolIds)
    if (!equal) {
      console.log('Functional integrity issues for station', station.id, station.tool_ids, toolIds)
    }
    return !equal
  }

  const hasRobot = (): boolean => {
    let stationHasRobot: boolean = false
    station.tools.forEach((tool: IToolData): void => {
      stationHasRobot = tool.toolType !== '' || stationHasRobot
    })
    return stationHasRobot
  }

  const getGridCols = (maxNbCols: number): number => {
    const nbTools: number = station.tools.length > 0 ? station.tools.length : 1
    const stationNbCols: number = station.direction === 'row' ? nbTools : 1
    if (stationNbCols === 1 || maxNbCols === 1) {
      return 12
    }
    const nbRows: number = Math.ceil(stationNbCols / maxNbCols)
    const nbCols: number = Math.ceil(stationNbCols / nbRows)
    const gridCols: number = 12 / nbCols
    return gridCols
  }

  return (
    <Droppable
      direction={station.direction === 'row' ? 'horizontal' : 'vertical'}
      droppableId={`station-${station.id}`}
      type="STATION"
    >
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
        let bgColor: string = blueGrey['50']
        if (snapshot.isDraggingOver) {
          bgColor = lightBlue['50']
        } else if (station.isPinned) {
          bgColor = green['50']
        }
        return (
          <Paper
            onDoubleClick={handleDisplayDebugInfo}
            elevation={0}
            ref={provided.innerRef}
            style={{
              backgroundColor: bgColor,
              border: '1px solid',
              borderColor: snapshot.isDraggingOver ? lightBlue['100'] : blueGrey['100'],
            }}
            sx={{ p: 1 }}
          >
            <Box component="form" noValidate onBlur={handleSubmit(handleFormBlur)}>
              <Box display="flex" sx={{ pl: 1, pr: 1 }}>
                {findFunctionalIntegrityIssues() ? (
                  <Box sx={{ pt: ' 3px' }}>
                    <Tooltip
                      title={t`Integrity issue: the tools do not match the sort list. Click to repair data.`}
                    >
                      <IconButton
                        color="error"
                        onClick={handleFixFunctionalIntegrity}
                        size="small"
                        sx={{ mr: '5px', px: 0 }}
                      >
                        <ReportIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  <span />
                )}
                <Box flexGrow={1}>
                  <FormTextField
                    autoComplete='off'
                    placeholder={t`New station`}
                    errorText={errors.title?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: '1.2rem',
                        letterSpacing: '0.1px',
                      },
                    }}
                    multiline
                    registerReturn={register('title')}
                    variant="standard"
                  />
                  <FormTextField
                    autoComplete='off'
                    errorText={errors.subtitle?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: '.85rem',
                        letterSpacing: '0.01px',
                      },
                    }}
                    multiline
                    registerReturn={register('subtitle')}
                    variant="standard"
                  />
                </Box>
                <StationActionsMenu
                  clipboard={clipboard}
                  onChangeDirection={handleChangeDirectionClick}
                  onChangePin={handleChangePinClick}
                  onCreateTool={handleCreateToolClick}
                  onStationDeleted={onStationDeleted}
                  onToolsMoved={onToolsMoved}
                  setStation={setStation}
                  setSubmitting={setSubmitting}
                  station={station}
                  stationRef={stationRef}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start">
              <Tooltip title={t`Add tool`}>
                <IconButton onClick={handleCreateToolClick} size="small">
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t`Reverse orientation`}>
                <IconButton onClick={handleChangeDirectionClick} size="small">
                  <RotationalArrowDownwardIcon degrees={station.direction === 'row' ? -90 : 0} />
                </IconButton>
              </Tooltip>
              <Tooltip title={station.isPinned ? t`Unpin station` : t`Pin station`}>
                <IconButton onClick={handleChangePinClick} size="small" sx={{ ml: '2px' }}>
                  {station.isPinned ? (
                    <PushPinIcon fontSize="small" sx={{ mt: '3px' }} />
                  ) : (
                    <RotationalPushPinOutlinedIcon degrees={-30} fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            {hasRobot() && (
                <Box display="flex" key={station.id} sx={{ pl: 1, pr: 1 }}>
                  <FormTextField
                    autoComplete='off'
                    errorText={errors.subject?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: '1.1rem',
                        letterSpacing: '0.1px',
                      },
                    }}
                    margin="dense"
                    onKeyPress={handleSubjectKeyPress}
                    registerReturn={register('subject')}
                    placeholder={t`Prompt`}
                    hiddenLabel
                    size='small'
                    variant='filled'
                  />
                </Box>
              )}
            <Grid container flexDirection={station.direction}>
              {station.tools.map((tool: IToolData, index: number) => (
                <Draggable
                  draggableId={`tool-${tool.id}`}
                  index={index}
                  key={`tool-${tool.id}-${toolsRefreshState}`}
                >
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <Grid
                      item
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      xs={getGridCols(1)}
                      sm={getGridCols(2)}
                      md={getGridCols(3)}
                      lg={getGridCols(4)}
                      xl={getGridCols(6)}
                    >
                      <Tool
                        onTermsMoved={handleTermsMoved}
                        onToolChanged={handleToolChanged}
                        onToolDeleted={handleToolDeleted}
                        tool={tool}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
            <ProgressBackdrop open={submitting} />
          </Paper>
        )
      }}
    </Droppable>
  )
}

export default React.memo(Station)
