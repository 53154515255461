import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'

import IStationData from '../types/IStationData'
import StationDataService from '../DataServices/StationDataService'
import Checkbox from '../utils/controls/Checkbox'

type StationConfigDialogProps = {
  onClose: () => void
  open: boolean
  setStation: React.Dispatch<React.SetStateAction<IStationData>>
  station: IStationData
}

function StationConfigDialog({
  onClose,
  open,
  setStation,
  station,
}: StationConfigDialogProps): JSX.Element {
  const [isTemplate, setIsTemplate] = useState<boolean>(station.isTemplate)

  const handleSaveDialog = (): void => {
    if (isTemplate !== station.isTemplate) {
      const stationToUpdate: IStationData = { ...station, ...{ isTemplate } }
      StationDataService.updateOrCreate(station.id, stationToUpdate)
        .then(() => {
          console.log('Station updated', stationToUpdate.id)
          setStation({ ...stationToUpdate })
          onClose()
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while station updating', err, err.response)
        })
    } else {
      onClose()
    }
  }

  const handleCloseDialog = (): void => {
    setIsTemplate(station.isTemplate)
    onClose()
  }

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={handleCloseDialog}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
        <Box display="flex">
          <Box flexGrow={1}>{`${t`Configure station`}${t`:`} ${station.title}`}</Box>
          <Tooltip title={t`Close`}>
            <IconButton onClick={onClose} sx={{ color: 'text.secondary', mr: 0, p: 0 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2 }}>
        <DialogContentText id="alert-dialog-description" sx={{ fontSize: '0.95rem', pb: 3 }}>
          {t`Here are the additional options available for this station.`}
        </DialogContentText>
        <Box>
          <Checkbox
            checked={isTemplate}
            color="primary"
            label={
              <Typography sx={{ fontSize: '0.95rem' }}>{t`This station is a template`}</Typography>
            }
            labelPlacement="end"
            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
              setIsTemplate(checked)
            }
            sx={{ py: 0 }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCloseDialog} size="small" variant="outlined">{t`Cancel`}</Button>
        <Button
          autoFocus
          onClick={handleSaveDialog}
          size="small"
          variant="contained"
        >{t`Save`}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(StationConfigDialog)
