/* eslint no-empty-function: ["error", { "allow": ["arrowFunctions"] }] */
/* eslint @typescript-eslint/no-empty-function: ["error", { "allow": ["arrowFunctions"] }] */

import { createContext, useState } from 'react'
import IStudyData from '../types/IStudyData'
import IStationData from '../types/IStationData'
import IToolData from '../types/IToolData'
import ITermData from '../types/ITermData'

export interface IClipboardItemState<T> {
  action: 'copy' | 'cut'
  data: T
  selected: boolean
}

interface IClipboardItemStateAssocKey<T> {
  [key: string]: IClipboardItemState<T>
}

export interface IClipboardItems<T> {
  itemStateKeys: string[] // List of item keys: to keep the order of addition
  itemStateByKey: IClipboardItemStateAssocKey<T>

  // Calculated Properties: To avoid looping through all items
  // Initially for selected items, added standard count for consistency
  itemCount: number
  selectedItemCount: number

  addItem: (action: 'copy' | 'cut', item: T) => void
  updateItemData: (item: T) => void
  changeItemAction: (action: 'copy' | 'cut', itemId: number | string) => void
  changeItemSelection: (checked: boolean, itemId: number | string) => void
  changeAllItemsSelection: (checked: boolean) => void
  getItemState: (itemId: number | string | undefined) => IClipboardItemState<T> | null
  getSelectedItemStates: () => IClipboardItemState<T>[]
  getSelectedItems: (action: 'copy' | 'cut') => T[]
  removeItem: (itemId: number | string) => void
  removeSelectedItemStates: () => void
  removeAllItems: () => void
}

export interface IClipboard {
  studies: IClipboardItems<IStudyData>
  stations: IClipboardItems<IStationData>
  tools: IClipboardItems<IToolData>
  terms: IClipboardItems<ITermData>

  totalItemCount: number
  totalSelectedItemCount: number

  removeAll: () => void
}

const defaultClipboardItems = <T>(): IClipboardItems<T> => ({
  itemStateKeys: [],
  itemStateByKey: {},
  itemCount: 0,
  selectedItemCount: 0,
  addItem: (): void => {},
  updateItemData: (): void => {},
  changeItemAction: (): void => {},
  changeItemSelection: (): void => {},
  changeAllItemsSelection: (): void => {},
  getItemState: (): IClipboardItemState<T> | null => null,
  getSelectedItemStates: (): IClipboardItemState<T>[] => [],
  getSelectedItems: (): T[] => [],
  removeItem: (): void => {},
  removeSelectedItemStates: (): void => {},
  removeAllItems: (): void => {},
})

const defaultClipboard: IClipboard = {
  studies: defaultClipboardItems<IStudyData>(),
  stations: defaultClipboardItems<IStationData>(),
  tools: defaultClipboardItems<IToolData>(),
  terms: defaultClipboardItems<ITermData>(),

  totalItemCount: 0,
  totalSelectedItemCount: 0,

  removeAll: (): void => {},
}

export const ClipboardState = (): IClipboard => {
  const [clipboard, setClipboard] = useState<IClipboard>(defaultClipboard)

  const addItem = <T>(
    clipboardItems: IClipboardItems<T>,
    action: 'copy' | 'cut',
    key: string,
    item: T,
  ): void => {
    if (!(key in clipboardItems.itemStateByKey)) {
      clipboardItems.itemStateKeys.push(key)
      clipboardItems.itemStateByKey[key] = { action, data: { ...item }, selected: true }
      clipboardItems.itemCount += 1
      clipboardItems.selectedItemCount += 1
      setClipboard({ ...clipboard })
    } else if (clipboardItems.itemStateByKey[key].action !== action) {
      clipboardItems.itemStateByKey[key].action = action
      setClipboard({ ...clipboard })
    }
  }

  clipboard.studies.addItem = (action: 'copy' | 'cut', study: IStudyData): void => {
    addItem<IStudyData>(clipboard.studies, action, study.id.toString(), study)
  }

  clipboard.stations.addItem = (action: 'copy' | 'cut', station: IStationData): void => {
    addItem<IStationData>(clipboard.stations, action, station.id.toString(), station)
  }

  clipboard.tools.addItem = (action: 'copy' | 'cut', tool: IToolData): void => {
    addItem<IToolData>(clipboard.tools, action, tool.id.toString(), tool)
  }

  clipboard.terms.addItem = (action: 'copy' | 'cut', term: ITermData): void => {
    addItem<ITermData>(clipboard.terms, action, term.id.toString(), term)
  }

  const updateItemData = <T>(clipboardItems: IClipboardItems<T>, key: string, item: T): void => {
    if (key in clipboardItems.itemStateByKey) {
      clipboardItems.itemStateByKey[key].data = item
      setClipboard({ ...clipboard })
    }
  }

  clipboard.studies.updateItemData = (study: IStudyData): void => {
    updateItemData<IStudyData>(clipboard.studies, study.id.toString(), study)
  }

  clipboard.stations.updateItemData = (station: IStationData): void => {
    updateItemData<IStationData>(clipboard.stations, station.id.toString(), station)
  }

  clipboard.tools.updateItemData = (tool: IToolData): void => {
    updateItemData<IToolData>(clipboard.tools, tool.id.toString(), tool)
  }

  clipboard.terms.updateItemData = (term: ITermData): void => {
    updateItemData<ITermData>(clipboard.terms, term.id.toString(), term)
  }

  const changeItemAction = <T>(
    clipboardItems: IClipboardItems<T>,
    action: 'copy' | 'cut',
    key: string,
  ): void => {
    if (
      key in clipboardItems.itemStateByKey &&
      clipboardItems.itemStateByKey[key].action !== action
    ) {
      clipboardItems.itemStateByKey[key].action = action
      setClipboard({ ...clipboard })
    }
  }

  clipboard.studies.changeItemAction = (action: 'copy' | 'cut', studyId: number | string): void => {
    changeItemAction<IStudyData>(clipboard.studies, action, studyId.toString())
  }

  clipboard.stations.changeItemAction = (
    action: 'copy' | 'cut',
    stationId: number | string,
  ): void => {
    changeItemAction<IStationData>(clipboard.stations, action, stationId.toString())
  }

  clipboard.tools.changeItemAction = (action: 'copy' | 'cut', toolId: number | string): void => {
    changeItemAction<IToolData>(clipboard.tools, action, toolId.toString())
  }

  clipboard.terms.changeItemAction = (action: 'copy' | 'cut', termId: number | string): void => {
    changeItemAction<ITermData>(clipboard.terms, action, termId.toString())
  }

  const changeItemSelection = <T>(
    clipboardItems: IClipboardItems<T>,
    checked: boolean,
    key: string,
  ): void => {
    if (clipboardItems.itemStateByKey[key].selected !== checked) {
      clipboardItems.itemStateByKey[key].selected = checked
      clipboardItems.selectedItemCount = checked
        ? clipboardItems.selectedItemCount + 1
        : clipboardItems.selectedItemCount - 1
      setClipboard({ ...clipboard })
    }
  }

  clipboard.studies.changeItemSelection = (checked: boolean, studyId: number | string): void => {
    changeItemSelection<IStudyData>(clipboard.studies, checked, studyId.toString())
  }

  clipboard.stations.changeItemSelection = (checked: boolean, stationId: number | string): void => {
    changeItemSelection<IStationData>(clipboard.stations, checked, stationId.toString())
  }

  clipboard.tools.changeItemSelection = (checked: boolean, toolId: number | string): void => {
    changeItemSelection<IToolData>(clipboard.tools, checked, toolId.toString())
  }

  clipboard.terms.changeItemSelection = (checked: boolean, termId: number | string): void => {
    changeItemSelection<ITermData>(clipboard.terms, checked, termId.toString())
  }

  const changeAllItemsSelection = <T>(
    clipboardItems: IClipboardItems<T>,
    checked: boolean,
  ): void => {
    Object.values(clipboardItems.itemStateByKey).forEach((itemState: IClipboardItemState<T>) => {
      itemState.selected = checked
    })
    clipboardItems.selectedItemCount = checked
      ? Object.keys(clipboardItems.itemStateByKey).length
      : 0
    setClipboard({ ...clipboard })
  }

  clipboard.studies.changeAllItemsSelection = (checked: boolean): void => {
    changeAllItemsSelection<IStudyData>(clipboard.studies, checked)
  }

  clipboard.stations.changeAllItemsSelection = (checked: boolean): void => {
    changeAllItemsSelection<IStationData>(clipboard.stations, checked)
  }

  clipboard.tools.changeAllItemsSelection = (checked: boolean): void => {
    changeAllItemsSelection<IToolData>(clipboard.tools, checked)
  }

  clipboard.terms.changeAllItemsSelection = (checked: boolean): void => {
    changeAllItemsSelection<ITermData>(clipboard.terms, checked)
  }

  const getItemState = <T>(
    clipboardItems: IClipboardItems<T>,
    key: string,
  ): IClipboardItemState<T> | null => {
    if (key in clipboardItems.itemStateByKey) {
      return clipboardItems.itemStateByKey[key]
    }
    return null
  }

  clipboard.studies.getItemState = (
    studyId: number | string | undefined,
  ): IClipboardItemState<IStudyData> | null => {
    if (studyId) {
      return getItemState<IStudyData>(clipboard.studies, studyId.toString())
    }
    return null
  }

  clipboard.stations.getItemState = (
    stationId: number | string | undefined,
  ): IClipboardItemState<IStationData> | null => {
    if (stationId) {
      return getItemState<IStationData>(clipboard.stations, stationId.toString())
    }
    return null
  }

  clipboard.tools.getItemState = (
    toolId: number | string | undefined,
  ): IClipboardItemState<IToolData> | null => {
    if (toolId) {
      return getItemState<IToolData>(clipboard.tools, toolId.toString())
    }
    return null
  }

  clipboard.terms.getItemState = (
    termId: number | string | undefined,
  ): IClipboardItemState<ITermData> | null => {
    if (termId) {
      return getItemState<ITermData>(clipboard.terms, termId.toString())
    }
    return null
  }

  const getSelectedItemStates = <T>(
    clipboardItems: IClipboardItems<T>,
  ): IClipboardItemState<T>[] => {
    const selectedItemStates: IClipboardItemState<T>[] = []
    clipboardItems.itemStateKeys.forEach((key: string) => {
      const itemState: IClipboardItemState<T> = clipboardItems.itemStateByKey[key]
      if (itemState.selected) {
        selectedItemStates.push(itemState)
      }
    })
    return selectedItemStates
  }

  clipboard.studies.getSelectedItemStates = (): IClipboardItemState<IStudyData>[] =>
    getSelectedItemStates<IStudyData>(clipboard.studies)

  clipboard.stations.getSelectedItemStates = (): IClipboardItemState<IStationData>[] =>
    getSelectedItemStates<IStationData>(clipboard.stations)

  clipboard.tools.getSelectedItemStates = (): IClipboardItemState<IToolData>[] =>
    getSelectedItemStates<IToolData>(clipboard.tools)

  clipboard.terms.getSelectedItemStates = (): IClipboardItemState<ITermData>[] =>
    getSelectedItemStates<ITermData>(clipboard.terms)

  const getSelectedItems = <T>(clipboardItems: IClipboardItems<T>, action: 'copy' | 'cut'): T[] => {
    const selectedItems: T[] = []
    clipboardItems.itemStateKeys.forEach((key: string) => {
      const itemState: IClipboardItemState<T> = clipboardItems.itemStateByKey[key]
      if (itemState.selected && itemState.action === action) {
        selectedItems.push(itemState.data)
      }
    })
    return selectedItems
  }

  clipboard.studies.getSelectedItems = (action: 'copy' | 'cut'): IStudyData[] =>
    getSelectedItems<IStudyData>(clipboard.studies, action)

  clipboard.stations.getSelectedItems = (action: 'copy' | 'cut'): IStationData[] =>
    getSelectedItems<IStationData>(clipboard.stations, action)

  clipboard.tools.getSelectedItems = (action: 'copy' | 'cut'): IToolData[] =>
    getSelectedItems<IToolData>(clipboard.tools, action)

  clipboard.terms.getSelectedItems = (action: 'copy' | 'cut'): ITermData[] =>
    getSelectedItems<ITermData>(clipboard.terms, action)

  const removeItem = <T>(clipboardItems: IClipboardItems<T>, key: string): void => {
    const index: number = clipboardItems.itemStateKeys.indexOf(key)
    if (index >= 0) {
      clipboardItems.itemStateKeys.splice(index, 1) // Remove key
      delete clipboardItems.itemStateByKey[key] // Remove item state
      clipboardItems.itemCount -= 1
      clipboardItems.selectedItemCount -= 1
      setClipboard({ ...clipboard })
    }
  }

  clipboard.studies.removeItem = (studyId: number | string): void => {
    removeItem<IStudyData>(clipboard.studies, studyId.toString())
  }

  clipboard.stations.removeItem = (stationId: number | string): void => {
    removeItem<IStationData>(clipboard.stations, stationId.toString())
  }

  clipboard.tools.removeItem = (toolId: number | string): void => {
    removeItem<IToolData>(clipboard.tools, toolId.toString())
  }

  clipboard.terms.removeItem = (termId: number | string): void => {
    removeItem<ITermData>(clipboard.terms, termId.toString())
  }

  const removeSelectedItemStates = <T>(clipboardItems: IClipboardItems<T>): void => {
    const itemStateKeysCopy: string[] = [...clipboardItems.itemStateKeys]
    itemStateKeysCopy.forEach((key: string) => {
      if (clipboardItems.itemStateByKey[key].selected) {
        const realIndex: number = clipboardItems.itemStateKeys.indexOf(key)
        clipboardItems.itemStateKeys.splice(realIndex, 1) // Remove key
        delete clipboardItems.itemStateByKey[key] // Remove item state
        clipboardItems.itemCount -= 1
        clipboardItems.selectedItemCount -= 1
      }
    })
    setClipboard({ ...clipboard })
  }

  clipboard.studies.removeSelectedItemStates = (): void => {
    removeSelectedItemStates<IStudyData>(clipboard.studies)
  }

  clipboard.stations.removeSelectedItemStates = (): void => {
    removeSelectedItemStates<IStationData>(clipboard.stations)
  }

  clipboard.tools.removeSelectedItemStates = (): void => {
    removeSelectedItemStates<IToolData>(clipboard.tools)
  }

  clipboard.terms.removeSelectedItemStates = (): void => {
    removeSelectedItemStates<ITermData>(clipboard.terms)
  }

  const removeAllItems = <T>(clipboardItems: IClipboardItems<T>): void => {
    clipboardItems.itemStateKeys = [] // Remove all keys
    clipboardItems.itemStateByKey = {} // Remove all item states
    clipboardItems.itemCount = 0
    clipboardItems.selectedItemCount = 0
  }

  clipboard.studies.removeAllItems = (): void => {
    removeAllItems<IStudyData>(clipboard.studies)
    setClipboard({ ...clipboard })
  }

  clipboard.stations.removeAllItems = (): void => {
    removeAllItems<IStationData>(clipboard.stations)
    setClipboard({ ...clipboard })
  }

  clipboard.tools.removeAllItems = (): void => {
    removeAllItems<IToolData>(clipboard.tools)
    setClipboard({ ...clipboard })
  }

  clipboard.terms.removeAllItems = (): void => {
    removeAllItems<ITermData>(clipboard.terms)
    setClipboard({ ...clipboard })
  }

  clipboard.removeAll = (): void => {
    removeAllItems<IStudyData>(clipboard.studies)
    removeAllItems<IStationData>(clipboard.stations)
    removeAllItems<IToolData>(clipboard.tools)
    removeAllItems<ITermData>(clipboard.terms)
    setClipboard({ ...clipboard })
  }

  clipboard.totalItemCount =
    clipboard.studies.itemCount +
    clipboard.stations.itemCount +
    clipboard.tools.itemCount +
    clipboard.terms.itemCount

  clipboard.totalSelectedItemCount =
    clipboard.studies.selectedItemCount +
    clipboard.stations.selectedItemCount +
    clipboard.tools.selectedItemCount +
    clipboard.terms.selectedItemCount

  return clipboard
}

const ClipboardContext = createContext<IClipboard>(defaultClipboard)
export default ClipboardContext
