/* eslint-disable camelcase */

import yup from '../utils/yupLocale'
import IStationListData, {
  StationListExportProps,
  StationListImportSchema,
} from './IStationListData'

export default interface IStudyData {
  id: number
  title: string
  subtitle: string
  background: string
  note: string
  isTemplate: boolean
  isHelper: boolean
  stationList_ids: number[]
  stationLists: IStationListData[]
  studyList: number
  user: number
  contributingUser_ids: number[]
}

export const StudyExportProps: string[] = [
  'title',
  'subtitle',
  'background',
  'note',
  'isTemplate',
  'isHelper',
  'stationLists',
  'contributingUser_ids',
  ...StationListExportProps,
]

export const StudyImportSchema = yup.object().shape({
  title: yup.string().required(),
  subtitle: yup.string().defined(),
  background: yup.string().defined(),
  note: yup.string().defined(),
  isTemplate: yup.boolean().defined(),
  isHelper: yup.boolean().defined(),
  stationLists: yup.array().of(StationListImportSchema),
})

export const serializeStudy = (study: IStudyData): string => JSON.stringify(study, StudyExportProps)
