import React from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material'

export type CheckboxProps = MuiCheckboxProps & {
  label: string | number | React.ReactElement
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
  labelSx?: SxProps<Theme>
}

function FormCheckbox({
  label,
  labelPlacement = 'end',
  labelSx = {},
  ...others
}: CheckboxProps): JSX.Element {
  return (
    <FormControl>
      <FormControlLabel
        control={<MuiCheckbox {...others} />}
        label={label}
        labelPlacement={labelPlacement}
        sx={labelSx}
      />
    </FormControl>
  )
}

export default FormCheckbox
