import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material'
import { t } from '@lingui/macro'

export type SelectMenuItemData = {
  value: string
  text: string
}

export type SelectProps = MuiSelectProps<string> & {
  errorText?: string | null
  label: string
  options: SelectMenuItemData[]
}

function Select({ errorText = null, label, options, ...others }: SelectProps): JSX.Element {
  return (
    <FormControl fullWidth size="small" variant="outlined" {...(errorText && { errorText: true })}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect label={label} {...others}>
        <MenuItem sx={{ color: 'text.secondary' }} value="">
          <em>{t`None`}</em>
        </MenuItem>
        {options.map((item: SelectMenuItemData) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </MuiSelect>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}

export default Select
