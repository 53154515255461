import React, { useCallback, useState } from 'react'
import {
  Add as AddIcon,
  BorderLeftOutlined as BorderLeftOutlinedIcon,
  BorderRightOutlined as BorderRightOutlinedIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  CreateNewFolderOutlined as CreateNewFolderOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'

import { IClipboard, IClipboardItemState } from '../utils/ClipboardContext'
import IStationListData from '../types/IStationListData'
import IStationData, { StationImportSchema } from '../types/IStationData'
import StudiesService from '../DataServices/StudiesService'

import ConfirmDialog from '../utils/controls/ConfirmDialog'
import FileUploadDialog from '../utils/controls/FileUploadDialog'
import TemplateDialog from '../utils/controls/TemplateDialog'
import MoreActionsMenu from '../utils/controls/MoreActionsMenu'
import ClipboardMenu from '../utils/controls/ClipboardMenu'

type StationListActionsMenuProps = {
  clipboard: IClipboard
  onCreateStation: () => void
  onCreateStationList: (position: 'left' | 'right') => void
  onStationListDeleted: (stationListId: number) => void
  onStationsMoved: (movedStationIds: number[], targetStationListId: number) => void
  setStationList: React.Dispatch<React.SetStateAction<IStationListData>>
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  stationList: IStationListData
  stationListRef: React.MutableRefObject<IStationListData>
}

export default function StationListActionsMenu({
  clipboard,
  onCreateStation,
  onCreateStationList,
  onStationListDeleted,
  onStationsMoved,
  setStationList,
  setSubmitting,
  stationList,
  stationListRef,
}: StationListActionsMenuProps): JSX.Element {
  const [optionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const [templateDialogOpen, setTemplateDialogOpen] = useState<boolean>(false)
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [deleteAllStationsDialogOpen, setDeleteAllStationsDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  const handlePasteFromClipboardSelection = (): void => {
    const stationStates: IClipboardItemState<IStationData>[] =
      clipboard.stations.getSelectedItemStates()
    if (stationStates.length > 0) {
      setSubmitting(true)
      const stationsToMove: IStationData[] = clipboard.stations.getSelectedItems('cut')
      StudiesService.copyOrMoveStations(stationList.id, stationStates)
        .then((response) => {
          const pastedStations: IStationData[] = response.data
          console.log('Stations pasted (length)', pastedStations.length)
          const movedStationIds: number[] = stationsToMove.map(({ id }): number => id)
          if (movedStationIds.length > 0) {
            // Remove stations from station lists (if exists)
            stationList.stations = stationList.stations.filter(
              (station: IStationData) => movedStationIds.indexOf(station.id) < 0,
            )
            stationList.station_ids = stationList.station_ids.filter(
              (id: number) => movedStationIds.indexOf(id) < 0,
            )
          }
          pastedStations.forEach((station: IStationData) => {
            stationList.stations.push(station)
            stationList.station_ids.push(station.id)
          })
          setStationList({ ...stationList })
          if (movedStationIds.length > 0) {
            onStationsMoved(movedStationIds, stationList.id)
          }
          clipboard.stations.removeSelectedItemStates()
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while stations pasting', err, err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleCreateStationClick = (): void => {
    setOptionsMenuOpen(false)
    onCreateStation()
  }

  const handleOpenTemplateDialog = (): void => {
    setOptionsMenuOpen(false)
    setTemplateDialogOpen(true)
  }

  const handleCloseTemplateDialog = (): void => {
    setTemplateDialogOpen(false)
  }

  const handleImportFromTemplate = (templateId: number): void => {
    setSubmitting(true)
    StudiesService.injectStationTemplate(stationList.id, templateId)
      .then((response) => {
        const injectedStation: IStationData = response.data
        console.log('Station template injected', injectedStation.id)
        stationList.stations.push(injectedStation)
        stationList.station_ids.push(injectedStation.id)
        setStationList({ ...stationList })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while station template injecting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleOpenImportDialog = (): void => {
    setOptionsMenuOpen(false)
    setImportDialogOpen(true)
  }

  const handleCloseImportDialog = useCallback((): void => {
    setImportDialogOpen(false)
  }, [])

  const handleImportClick = useCallback(
    (json: string, setErrorList: React.Dispatch<React.SetStateAction<string[]>>): void => {
      // Use stationListRef in a Callback function
      StationImportSchema.validate(json, { abortEarly: false, stripUnknown: true })
        .then((value: unknown) => {
          const newStation: IStationData = value as IStationData
          setImportDialogOpen(false)
          setSubmitting(true)
          StudiesService.importStations(stationList.id, [newStation])
            .then((response) => {
              const createdStations: IStationData[] = response.data
              console.log('Stations imported (length)', createdStations.length)
              createdStations.forEach((createdStation: IStationData) => {
                stationListRef.current.stations.push(createdStation)
                stationListRef.current.station_ids.push(createdStation.id)
              })
              setStationList({ ...stationListRef.current })
            })
            .catch((err) => {
              console.log('ERROR: An error occurred while stations importing', err, err.response)
            })
            .finally(() => {
              setSubmitting(false)
            })
        })
        .catch(({ errors }) => {
          // "errors" is a string array when "abortEarly" is false
          console.log('Schema validation error while station importing', errors)
          setErrorList([t`Invalid file format`, ...errors])
        })
    },
    [],
  )

  const handleCreateStationList = (position: 'left' | 'right'): void => {
    setOptionsMenuOpen(false)
    onCreateStationList(position)
  }

  const handleOpenDeleteAllStationsDialog = (): void => {
    setOptionsMenuOpen(false)
    setDeleteAllStationsDialogOpen(true)
  }

  const handleCloseDeleteAllStationsDialog = useCallback((): void => {
    setDeleteAllStationsDialogOpen(false)
  }, [])

  const handleDeleteAllStationsClick = useCallback((): void => {
    // Use stationListRef in a Callback function
    setDeleteAllStationsDialogOpen(false)
    setSubmitting(true)
    StudiesService.deleteAllStationsOfStationList(stationList.id)
      .then(() => {
        console.log('All stations deleted', stationList.id)
        stationListRef.current.station_ids = []
        stationListRef.current.stations = []
        setStationList({ ...stationListRef.current })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while stations deleting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [])

  const handleOpenDeleteDialog = (): void => {
    setOptionsMenuOpen(false)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  const handleDeleteClick = useCallback((): void => {
    setDeleteDialogOpen(false)
    setSubmitting(true)
    StudiesService.deleteStationList(stationList.id)
      .then(() => {
        console.log('Station List deleted', stationList.id)
        onStationListDeleted(stationList.id)
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while station list deleting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [])

  const moreActionMenuItems = [
    {
      icon: <AddIcon />,
      label: t`Add station`,
      onClick: handleCreateStationClick,
    },
    {
      icon: <CreateNewFolderOutlinedIcon />,
      label: t`Import station from template`,
      onClick: handleOpenTemplateDialog,
    },
    {
      icon: <FileDownloadOutlinedIcon />,
      label: t`Import station from file`,
      onClick: handleOpenImportDialog,
    },
    {
      icon: <BorderLeftOutlinedIcon />,
      label: t`Add blank area at left`,
      onClick: () => handleCreateStationList('left'),
      hasDivider: true,
    },
    {
      icon: <BorderRightOutlinedIcon />,
      label: t`Add blank area at right`,
      onClick: () => handleCreateStationList('right'),
    },
    {
      icon: <DeleteOutlinedIcon color="secondary" />,
      label: t`Delete all stations`,
      onClick: handleOpenDeleteAllStationsDialog,
      color: 'error.dark',
      hasDivider: true,
    },
    {
      icon: <DeleteForeverOutlinedIcon color="secondary" />,
      label: t`Delete area`,
      onClick: handleOpenDeleteDialog,
      color: 'error.dark',
    },
  ]

  return (
    <>
      <ClipboardMenu
        containerSx={{ mt: '5px' }}
        onPasteFromClipboardSelection={handlePasteFromClipboardSelection}
        pasteMessages={{
          empty: t`No station selected in clipboard`,
          selected: t`Paste selected stations from clipboard`,
        }}
        selectedChildItemCount={clipboard.stations.selectedItemCount}
      />
      <MoreActionsMenu
        containerSx={{ mt: '5px' }}
        items={moreActionMenuItems}
        open={optionsMenuOpen}
        setOpen={setOptionsMenuOpen}
      />
      <TemplateDialog
        description={t`Add a station (including its tools and terms) from a template.`}
        getTemplates={StudiesService.getStationTemplates}
        open={templateDialogOpen}
        onClose={handleCloseTemplateDialog}
        onImportFromTemplate={handleImportFromTemplate}
        title={t`Import station from template`}
      />
      <FileUploadDialog
        description={t`Click or drag to import station`}
        open={importDialogOpen}
        onClose={handleCloseImportDialog}
        onUpload={handleImportClick}
        title={t`Area`}
      />
      <ConfirmDialog
        confirmColor="secondary"
        confirmText={t`Delete all stations`}
        description={t`Are you sure you want to delete all stations of this area?`}
        open={deleteAllStationsDialogOpen}
        onClose={handleCloseDeleteAllStationsDialog}
        onConfirm={handleDeleteAllStationsClick}
        title={t`Area`}
      />
      <ConfirmDialog
        confirmColor="secondary"
        confirmText={t`Delete area`}
        description={t`Are you sure you want to delete this area?`}
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteClick}
        title={t`Area`}
      />
    </>
  )
}
