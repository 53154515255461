import { AxiosResponse } from 'axios'
import axiosInstance from '../utils/axios'
import ITermData from '../types/ITermData'

const rootPath = '/studies/terms'

const getAll = (): Promise<AxiosResponse<ITermData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<ITermData[]>>(rootPath)

const get = (id: number): Promise<AxiosResponse<ITermData>> =>
  axiosInstance.get<unknown, AxiosResponse<ITermData>>(`${rootPath}/${id}`)

const create = (data: ITermData): Promise<AxiosResponse<ITermData>> =>
  axiosInstance.post<ITermData, AxiosResponse<ITermData>>(`${rootPath}/`, data)

const updateOrCreate = (id: number, data: ITermData): Promise<AxiosResponse<ITermData>> => {
  if (id) {
    return axiosInstance.put<ITermData, AxiosResponse<ITermData>>(`${rootPath}/${id}/`, data)
  }
  return create(data)
}

const remove = (id: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(`${rootPath}/${id}/`)

const removeAll = (): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(rootPath)

const TermDataService = {
  getAll,
  get,
  create,
  updateOrCreate,
  remove,
  removeAll,
}

export default TermDataService
