/* eslint-disable camelcase */

import { t } from '@lingui/macro'
import yup from '../utils/yupLocale'
import ITermData, { TermExportProps, TermImportSchema } from './ITermData'

export default interface IToolData {
  id: number
  title: string
  subtitle: string
  note?: string
  term_ids: number[]
  terms: ITermData[]
  station: number
  sourceLanguage: string
  destinationLanguage: string
  toolType: string
  isTemplate: boolean
}

export const ToolExportProps: string[] = [
  'title',
  'subtitle',
  'note',
  'sourceLanguage',
  'destinationLanguage',
  'terms',
  ...TermExportProps,
  'toolType',
  'isTemplate',
]

export const ToolImportSchema = yup.object().shape({
  title: yup.string().defined(),
  subtitle: yup.string().defined(),
  note: yup.string().defined(),
  terms: yup.array().of(TermImportSchema),
  toolType: yup
    .string()
    .defined()
    .matches(/^(agents|antonyms|chatgpt|definitions|examples|related|synonyms)$/, {
      excludeEmptyString: true,
    }),
  isTemplate: yup.boolean().defined(),
})

export const serializeTool = (tool: IToolData): string => JSON.stringify(tool, ToolExportProps)

export const getTypeToolText = (toolType: string): string => {
  switch (toolType) {
    // case 'answers':
    //   return t`Answers`
    case 'antonyms':
      return t`Antonyms`
    // case 'classify':
    //   return t`Classify`
    // case 'concepts':
    //   return t`Concepts`
    case 'definitions':
      return t`Definitions`
    case 'examples':
      return t`Examples`
    // case 'groupings':
    //   return t`Groupings`
    // case 'help':
    //   return t`Help`
    // case 'recommendations':
    //   return t`Recommendations`
    case 'related':
      return t`Related`
    case 'synonyms':
      return t`Synonyms`
    case 'chatgpt':
      return t`ChatGPT`
    case 'agents':
      return t`Agents`
    case 'translate':
      return t`Translate`
    default:
      return toolType
  }
}

export const getLanguageText = (language: string): string => {
  switch (language) {
    case 'arb':
      return t`arb`
    case 'bg':
      return t`bg`
    case 'ca':
      return t`ca`
    case 'cmn-Hans':
      return t`cmn-Hans`
    case 'da':
      return t`da`
    case 'el':
      return t`el`
    case 'es':
      return t`es`
    case 'eu':
      return t`eu`
    case 'fi':
      return t`fi`
    case 'fr':
      return t`fr`
    case 'gl':
      return t`gl`
    case 'he':
      return t`he`
    case 'hr':
      return t`hr`
    case 'id':
      return t`id`
    case 'is':
      return t`is`
    case 'it':
      return t`it`
    case 'ja':
      return t`ja`
    case 'lt':
      return t`lt`
    case 'nb':
      return t`nb`
    case 'nl':
      return t`nl`
    case 'nn':
      return t`nn`
    case 'pl':
      return t`pl`
    case 'pt':
      return t`pt`
    case 'ro':
      return t`ro`
    case 'sk':
      return t`sk`
    case 'sl':
      return t`sl`
    case 'sq':
      return t`sq`
    case 'sv':
      return t`sv`
    case 'th':
      return t`th`
    case 'zsm':
      return t`zsm`
    case 'en':
      return t`en`
    case 'de':
      return t`de`
    case 'ckb':
      return t`ckb`
    default:
      return language
  }
}

export const TOOL_TYPES: string[] = [
  // 'answers',
  'antonyms',
  // 'classify',
  // 'concepts',
  'definitions',
  'examples',
  // 'groupings',
  // 'help',
  'related',
  // 'recommendations',
  'synonyms',
  'chatgpt',
  'agents',
  // 'translate',
]

export const LANGUAGES: string[] = [
  'arb',
  'bg',
  'ca',
  'cmn-Hans',
  'da',
  'el',
  'es',
  'eu',
  'fi',
  'fr',
  'gl',
  'he',
  'hr',
  'id',
  'is',
  'it',
  'ja',
  'lt',
  'nb',
  'nl',
  'nn',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'sq',
  'sv',
  'th',
  'zsm',
  'en',
  'de',
  'ckb',
]
