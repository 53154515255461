import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { isEqual } from 'lodash'
import { blueGrey, lightBlue } from '@mui/material/colors'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, CircularProgress, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { Add as AddIcon, Report as ReportIcon } from '@mui/icons-material'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import ProgressBackdrop from '../utils/controls/ProgressBackdrop'

import useRefState from '../utils/useRefState'
import StudyCard from './StudyCard'
import IStudyData from '../types/IStudyData'
import IStudyListData from '../types/IStudyListData'
import StudiesService from '../DataServices/StudiesService'
import StudyListDataService from '../DataServices/StudyListDataService'
import StudyListActionsMenu from './StudyListActionsMenu'
import FormTextField from '../utils/controls/FormTextField'

type StudyListProps = {
  onStudiesMoved: (movedStudyIds: number[], targetStudyListId: number) => void
  onStudyListChanged: (studyList: IStudyListData) => void
  studyList: IStudyListData
}

type FormStudyListFields = {
  title: string
}

function StudyList({
  onStudiesMoved,
  onStudyListChanged,
  studyList: initStudyList,
}: StudyListProps): JSX.Element {
  console.log('Load StudyList Component', initStudyList)

  const navigate: NavigateFunction = useNavigate()
  const isInitialStudyListRender = useRef<boolean>(true)
  const [studyList, studyListRef, setStudyList] = useRefState<IStudyListData>(initStudyList)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const formSchema = yup.object().shape({
    title: yup.string().trim(),
  })

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<FormStudyListFields>({
    defaultValues: useMemo(() => studyList as FormStudyListFields, [studyList]),
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (isInitialStudyListRender.current) {
      isInitialStudyListRender.current = false
    } else {
      console.log('StudyList changed (triggers onStudyListChanged)', studyList.id)
      reset(studyList as FormStudyListFields)
      onStudyListChanged(studyList)
    }
  }, [studyList])

  const handleFormBlur = (data: FormStudyListFields): void => {
    if (isDirty) {
      const studyListToUpdate: IStudyListData = { ...studyList, ...data }
      StudyListDataService.updateOrCreate(studyList.id, studyListToUpdate)
        .then((response) => {
          console.log('StudyList updated', studyListToUpdate.id, response.data)
          setStudyList({ ...studyList, ...response.data })
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while studyList updating', err, err.response)
        })
    }
  }

  const handleCreateStudyClick = (): void => {
    const newStudy: IStudyData = {
      id: 0,
      title: '',
      subtitle: '',
      background: '',
      note: '',
      isTemplate: false,
      isHelper: false,
      stationLists: [],
      stationList_ids: [],
      studyList: studyList.id,
      user: 0,
      contributingUser_ids: []
    }
    StudiesService.addStudy(newStudy)
      .then((response) => {
        const createdStudy = response.data
        console.log('Study created', createdStudy.id)
        studyList.study_ids.push(createdStudy.id)
        studyList.studies?.push(createdStudy)
        setStudyList({ ...studyList })
        navigate(`/study/${createdStudy.id}`)
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while study creating', err, err.response)
      })
  }

  const handleFixFunctionalIntegrity = (): void => {
    setSubmitting(true)
    StudiesService.fixFunctionalIntegrityOfStudyList(studyList.id)
      .then((response) => {
        const fixedStudyList: IStudyListData = response.data
        console.log('StudyList fixed', fixedStudyList.id)
        studyList.study_ids = fixedStudyList.study_ids
        setStudyList({ ...studyList })
        enqueueSnackbar(t`Study list fixed correctly`, { variant: 'success' })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while studyList fixing', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    console.log('Debug (StudyList)', studyList)
  }

  const findFunctionalIntegrityIssues = (): boolean => {
    console.log("findFunctionalIntegrityIssues", studyList)
    const studyIds: number[] = studyList.studies.map((study: IStudyData) => study.id)
    const equal: boolean = isEqual(studyList.study_ids, studyIds)
    if (!equal) {
      console.log(
        'Functional integrity issues for studyList',
        studyList.id,
        studyList.study_ids,
        studyIds,
      )
    }
    return !equal
  }

  const renderStudyCards = (): JSX.Element | JSX.Element[] => {
    if (studyList.id === 0) {
      return (
        <Box sx={{ p: 1 }}>
          <CircularProgress sx={{ p: 0.5 }} />
        </Box>
      )
    }

    if (!studyList.studies) {
      return <Box sx={{ p: 1 }} />
    }

    return studyList.studies.map((study: IStudyData, index: number) => (
      <Draggable draggableId={`study-${study.id}`} key={`study-${study.id}`} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <StudyCard study={study} />
          </Box>
        )}
      </Draggable>
    ))
  }

  return (
    <Droppable
      direction="vertical"
      droppableId={`studyList-${studyList.id ? studyList.id.toString() : '0'}`}
      type="STUDYLIST"
    >
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <Paper
          onDoubleClick={handleDisplayDebugInfo}
          elevation={0}
          sx={{
            backgroundColor: snapshot.isDraggingOver ? lightBlue['50'] : blueGrey['50'],
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            overflow: 'auto',
            height: '100%',
            border: '1px solid',
            borderColor: snapshot.isDraggingOver ? lightBlue['100'] : blueGrey['100'],
          }}
        >
          <Box component="form" noValidate onBlur={handleSubmit(handleFormBlur)}>
            <Box display="flex">
              {findFunctionalIntegrityIssues() ? (
                <Box sx={{ pt: '7px' }}>
                  <Tooltip
                    title={t`Integrity issue: the studies do not match the sort list. Click to repair data.`}
                  >
                    <IconButton
                      color="error"
                      onClick={handleFixFunctionalIntegrity}
                      size="small"
                      sx={{ pr: 0 }}
                    >
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <span />
              )}
              <Box flexGrow={1} sx={{ pt: 1, pl: 1, pr: 1 }}>
                <Typography sx={{ color: 'text.secondary' }} variant="h6">
                  <FormTextField
                    autoComplete='off'
                    placeholder={t`Title of the study list`}
                    errorText={errors.title?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: '1.2rem',
                        letterSpacing: '0.1px',
                      },
                    }}
                    registerReturn={register('title')}
                    variant="standard"
                  />
                </Typography>
              </Box>
              <StudyListActionsMenu
                onCreateStudy={handleCreateStudyClick}
                onStudiesMoved={onStudiesMoved}
                setStudyList={setStudyList}
                setSubmitting={setSubmitting}
                studyList={studyList}
                studyListRef={studyListRef}
              />
            </Box>
          </Box>
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ height: '100%' }}
            display="flex"
          >
            <Grid container direction="column" justifyContent="space-between" overflow="hidden">
              <Grid item width="100%">
                {renderStudyCards()}
                {provided.placeholder}
              </Grid>
              <Grid item container alignItems="flex-end" justifyContent="flex-end">
                <Tooltip title={t`Add study`}>
                  <IconButton size="medium" onClick={handleCreateStudyClick} sx={{ m: 1 }}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <ProgressBackdrop open={submitting} />
        </Paper>
      )}
    </Droppable>
  )
}

export default React.memo(StudyList)
