import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isEqual } from 'lodash'
import { lightBlue, blueGrey } from '@mui/material/colors'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { Report as ReportIcon } from '@mui/icons-material'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import ProgressBackdrop from '../utils/controls/ProgressBackdrop'

import useRefState from '../utils/useRefState'
import ClipboardContext, { IClipboard, IClipboardItemState } from '../utils/ClipboardContext'
import ITermData from '../types/ITermData'
import IToolData, { getTypeToolText } from '../types/IToolData'
import AddTerm from '../Term/AddTerm'
import Term from '../Term/Term'
import StudiesService from '../DataServices/StudiesService'
import ToolDataService from '../DataServices/ToolDataService'
import ToolActionsMenu from './ToolActionsMenu'
import FormTextField from '../utils/controls/FormTextField'
import VoiceCommand from "../utils/VoiceCommand";
import IStudyData from "../types/IStudyData";

type ToolProps = {
  onTermsMoved: (movedTermIds: number[], targetToolId: number) => void
  onToolChanged: (tool: IToolData) => void
  onToolDeleted: (toolId: number) => void
  tool: IToolData
}

type FormToolFields = {
  title: string
  subtitle: string
}

function Tool({
  onTermsMoved,
  onToolChanged,
  onToolDeleted,
  tool: initTool,
}: ToolProps): JSX.Element {
  console.log('Load Tool Component', initTool.id, initTool.term_ids)

  const isInitialToolRender = useRef<boolean>(true)
  const [tool, toolRef, setTool] = useRefState<IToolData>(initTool)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  // The tool component will be re-render on all clipboard changes
  const clipboard: IClipboard = useContext(ClipboardContext)

  const formSchema = yup.object().shape({
    title: yup
      .string()
      .trim(),
    subtitle: yup.string().trim(),
  })

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<FormToolFields>({
    defaultValues: useMemo(() => tool as FormToolFields, [tool]),
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (isInitialToolRender.current) {
      isInitialToolRender.current = false
    } else {
      console.log('Tool changed (triggers onToolChanged)', tool.id)
      reset(tool as FormToolFields)
      onToolChanged(tool)
      if (clipboard.tools.getItemState(tool.id) !== null) {
        console.log('Update tool in clipboard', tool.id)
        clipboard.tools.updateItemData(tool)
      }
    }
  }, [tool])

  const handleFormBlur = (data: FormToolFields): void => {
    if (isDirty) {
      const toolToUpdate: IToolData = { ...tool, ...data }
      console.log("handleFormBlur", toolToUpdate, tool, data)
      ToolDataService.updateOrCreate(tool.id, toolToUpdate)
        .then((response) => {
          setTool(response.data)
          console.log('Tool updated', toolToUpdate.id, "ttu", toolToUpdate, "tool", tool)
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while tool updating', err, err.response)
        })
    }
  }

  const handleTermCreated = useCallback((newTerm: ITermData): void => {
    // Use toolRef in a Callback function
    console.log('begin handleTermCreated')
    toolRef.current.terms.push(newTerm)
    toolRef.current.term_ids.push(newTerm.id)
    setTool({ ...toolRef.current })
  }, [])

  const handleTermChanged = useCallback((term: ITermData): void => {
    // Use toolRef in a Callback function
    const terms: ITermData[] = []
    toolRef.current.terms.forEach((t: ITermData) => {
      terms.push(t.id === term.id ? term : t)
    })
    toolRef.current.terms = terms
    setTool({ ...toolRef.current })
    if (clipboard.terms.getItemState(term.id) !== null) {
      console.log('Update term in clipboard', term.id)
      clipboard.terms.updateItemData(term)
    }
  }, [])

  const handleTermDeleted = useCallback((termId: number): void => {
    // Use toolRef in a Callback function
    toolRef.current.terms = toolRef.current.terms.filter((term: ITermData) => term.id !== termId)
    toolRef.current.term_ids = toolRef.current.term_ids.filter((id: number) => id !== termId)
    setTool({ ...toolRef.current })
  }, [])

  const handleCopyTermToClipboard = useCallback(
    (term: ITermData): IClipboardItemState<ITermData> | null => {
      // Use toolRef in a Callback function
      clipboard.terms.addItem('copy', term)
      return clipboard.terms.getItemState(term.id)
    },
    [],
  )

  const handleCutTermToClipboard = useCallback(
    (term: ITermData): IClipboardItemState<ITermData> | null => {
      // Use toolRef in a Callback function
      clipboard.terms.addItem('cut', term)
      return clipboard.terms.getItemState(term.id)
    },
    [],
  )

  const handleRemoveTermFromClipboard = useCallback(
    (term: ITermData): IClipboardItemState<ITermData> | null => {
      // Use toolRef in a Callback function
      clipboard.terms.removeItem(term.id)
      return clipboard.terms.getItemState(term.id)
    },
    [],
  )

  const handleFixFunctionalIntegrity = (): void => {
    setSubmitting(true)
    StudiesService.fixFunctionalIntegrityOfTool(tool.id)
      .then((response) => {
        const fixedTool: IToolData = response.data
        console.log('Tool fixed', fixedTool.id)
        setTool({ ...fixedTool })
        enqueueSnackbar(t`Tool fixed correctly`, { variant: 'success' })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while tool fixing', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    console.log('Debug (Tool)', tool)
  }

  const findFunctionalIntegrityIssues = (): boolean => {
    const termIds: number[] = tool.terms.map((term: ITermData) => term.id)
    const equal: boolean = isEqual(tool.term_ids, termIds)
    if (!equal) {
      console.log('Functional integrity issues for tool', tool.id, tool.term_ids, termIds)
    }
    return !equal
  }

  const handleCommandExecuted = ()=> {
    console.log('handleCommandExecuted', tool.id)
    ToolDataService.get(tool.id)
      .then((response) => {
        console.log('Tool updated', tool.id)
        const loadedTool: IToolData = response.data
        setTool(loadedTool)
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while tool updating', err, err.response)
      })


  }

  return (
    <Droppable direction="vertical" droppableId={`tool-${tool.id}`} type="TOOL">
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <Paper
          onDoubleClick={handleDisplayDebugInfo}
          elevation={0}
          ref={provided.innerRef}
          style={{
            backgroundColor: snapshot.isDraggingOver ? lightBlue['50'] : 'white',
            border: '1px solid',
            borderColor: snapshot.isDraggingOver ? lightBlue['100'] : blueGrey['100'],
          }}
          sx={{ m: 1, p: 1 }}
        >
          <Box component="form" noValidate onBlur={handleSubmit(handleFormBlur)}>
            <Box display="flex">
              {findFunctionalIntegrityIssues() ? (
                <Box sx={{ pt: ' 2px' }}>
                  <Tooltip
                    title={t`Integrity issue: the terms do not match the sort list. Click to repair data.`}
                  >
                    <IconButton
                      color="error"
                      onClick={handleFixFunctionalIntegrity}
                      size="small"
                      sx={{ mr: '5px', px: 0 }}
                    >
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <span />
              )}
              <Box flexGrow={1}>
                {!tool.toolType ? (
                  <FormTextField
                    autoComplete='off'
                    placeholder={t`New tool`}
                    errorText={errors.title?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: '1.1rem',
                        letterSpacing: '0.1px',
                      },
                    }}
                    multiline
                    registerReturn={register('title')}
                    variant="standard"
                  />
                ) : (
                  <Box sx={{ p: '4px 0 5px' }}>
                    <Typography color="primary" fontSize="1.1rem" letterSpacing="0.1px">
                      {getTypeToolText(tool.toolType)}
                    </Typography>
                  </Box>
                )}
                <FormTextField
                  autoComplete='off'
                  errorText={errors.subtitle?.message}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: 'rgba(0, 0, 0, 0.54)',
                      fontSize: '0.85rem',
                      letterSpacing: '0.01px',
                    },
                  }}
                  multiline
                  registerReturn={register('subtitle')}
                  variant="standard"
                />
              </Box>
              <ToolActionsMenu
                clipboard={clipboard}
                onTermsMoved={onTermsMoved}
                onToolDeleted={onToolDeleted}
                setSubmitting={setSubmitting}
                setTool={setTool}
                tool={tool}
                toolRef={toolRef}
              />
            </Box>
          </Box>
          {tool.terms.map((term: ITermData, index: number) => {
            // For performance reasons, the Clipboard Context is not used directly in Term
            // components to prevent them from being re-render whenever the clipboard changes.
            const clipboardTermState: IClipboardItemState<ITermData> | null =
              clipboard.terms.getItemState(term.id)
            const clipboardTermAction: string = clipboardTermState ? clipboardTermState.action : ''
            return (
              <Draggable
                draggableId={`term-${term.id}`}
                index={index}
                key={`term-${term.id}-${clipboardTermAction}`}
              >
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Term
                      onCopyToClipboard={handleCopyTermToClipboard}
                      onCutToClipboard={handleCutTermToClipboard}
                      onRemoveFromClipboard={handleRemoveTermFromClipboard}
                      onTermChanged={handleTermChanged}
                      onTermDeleted={handleTermDeleted}
                      term={term}
                      clipboardTermState={clipboardTermState}
                    />
                  </Box>
                )}
              </Draggable>
            )
          })}
          {provided.placeholder}
          <Box display="flex"  sx={{ alignItems: 'center' }} >
            <Box sx={{ alignSelf: 'flex-end' }}>
              <VoiceCommand
                  command="add_term"
                  context={tool.id.toString()}
                  onCommandExecuted={handleCommandExecuted}
              />
            </Box>
            <Box width={'100%'} >
              <AddTerm toolId={tool.id} onTermCreated={handleTermCreated} />
              <ProgressBackdrop open={submitting} />
            </Box>
          </Box>
        </Paper>
      )}
    </Droppable>
  )
}

export default React.memo(Tool)
