import { AxiosResponse } from 'axios'
import axiosInstance from '../utils/axios'
import IStudyListsData from '../types/IStudyListsData'
import IStudyListData from '../types/IStudyListData'
import IStudyData from '../types/IStudyData'
import IStationListData from '../types/IStationListData'
import IStationData from '../types/IStationData'
import IToolData from '../types/IToolData'
import ITermData from '../types/ITermData'
import ITemplateData from '../types/ITemplateData'
import { IClipboardItemState } from '../utils/ClipboardContext'

const rootPath = '/studies'

const addStudy = (study: IStudyData): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(`${rootPath}/add_study`, study)

const addStationList = (stationList: IStationListData): Promise<AxiosResponse<IStationListData>> =>
  axiosInstance.post<IStationListData, AxiosResponse<IStationListData>>(
    `${rootPath}/add_station_list`,
    stationList,
  )

const addStation = (station: IStationData): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(`${rootPath}/add_station`, station)

const addTool = (tool: IToolData): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<IToolData, AxiosResponse<IToolData>>(`${rootPath}/add_tool`, tool)

const addTerm = (term: ITermData): Promise<AxiosResponse<ITermData>> =>
  axiosInstance.post<ITermData, AxiosResponse<ITermData>>(`${rootPath}/add_term`, term)

const authenticated = (): Promise<AxiosResponse<unknown>> =>
  axiosInstance.get(`${rootPath}/authenticated`)

const copyStudies = (
  studyListId: number,
  studyIds: number[],
): Promise<AxiosResponse<IStudyData[]>> =>
  axiosInstance.post<number[], AxiosResponse<IStudyData[]>>(
    `${rootPath}/copy_studies?study_list_id=${studyListId}`,
    studyIds,
  )

const copyStations = (
  stationListId: number,
  stationIds: number[],
): Promise<AxiosResponse<IStationData[]>> =>
  axiosInstance.post<number[], AxiosResponse<IStationData[]>>(
    `${rootPath}/copy_stations?station_list_id=${stationListId}`,
    stationIds,
  )

const copyTools = (stationId: number, toolIds: number[]): Promise<AxiosResponse<IToolData[]>> =>
  axiosInstance.post<number[], AxiosResponse<IToolData[]>>(
    `${rootPath}/copy_tools?station_id=${stationId}`,
    toolIds,
  )

const copyTerms = (toolId: number, termIds: number[]): Promise<AxiosResponse<ITermData[]>> =>
  axiosInstance.post<number[], AxiosResponse<ITermData[]>>(
    `${rootPath}/copy_terms?tool_id=${toolId}`,
    termIds,
  )

const copyOrMoveStudies = (
  studyListId: number,
  studyStates: IClipboardItemState<IStudyData>[],
): Promise<AxiosResponse<IStudyData[]>> =>
  axiosInstance.post<IClipboardItemState<IStudyData>[], AxiosResponse<IStudyData[]>>(
    `${rootPath}/copy_or_move_studies?study_list_id=${studyListId}`,
    studyStates,
  )

const copyOrMoveStations = (
  stationListId: number,
  stationStates: IClipboardItemState<IStationData>[],
): Promise<AxiosResponse<IStationData[]>> =>
  axiosInstance.post<IClipboardItemState<IStationData>[], AxiosResponse<IStationData[]>>(
    `${rootPath}/copy_or_move_stations?station_list_id=${stationListId}`,
    stationStates,
  )

const copyOrMoveTools = (
  stationId: number,
  toolStates: IClipboardItemState<IToolData>[],
): Promise<AxiosResponse<IToolData[]>> =>
  axiosInstance.post<IClipboardItemState<IToolData>[], AxiosResponse<IToolData[]>>(
    `${rootPath}/copy_or_move_tools?station_id=${stationId}`,
    toolStates,
  )

const copyOrMoveTerms = (
  toolId: number,
  termStates: IClipboardItemState<ITermData>[],
): Promise<AxiosResponse<ITermData[]>> =>
  axiosInstance.post<IClipboardItemState<ITermData>[], AxiosResponse<ITermData[]>>(
    `${rootPath}/copy_or_move_terms?tool_id=${toolId}`,
    termStates,
  )

const deleteAllStationListsOfStudy = (studyId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${rootPath}/delete_all_station_lists_of_study?study_id=${studyId}`)

const deleteAllStationsOfStationList = (stationListId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(
    `${rootPath}/delete_all_stations_of_station_list?station_list_id=${stationListId}`,
  )

const deleteAllToolsOfStation = (stationId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${rootPath}/delete_all_tools_of_station?station_id=${stationId}`)

const deleteAllTermsOfTool = (toolId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${rootPath}/delete_all_terms_of_tool?tool_id=${toolId}`)

const deleteStudy = (studyId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${rootPath}/delete_study?study_id=${studyId}`)

const deleteStationList = (stationListId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${rootPath}/delete_station_list?station_list_id=${stationListId}`)

const deleteStation = (stationId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${rootPath}/delete_station?station_id=${stationId}`)

const deleteTool = (toolId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${rootPath}/delete_tool?tool_id=${toolId}`)

const deleteTerm = (termId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${rootPath}/delete_term?term_id=${termId}`)

const fixFunctionalIntegrityOfStudyList = (
  studyListId: number,
): Promise<AxiosResponse<IStudyListData>> =>
  axiosInstance.put<unknown, AxiosResponse<IStudyListData>>(
    `${rootPath}/fix_functional_integrity_of_study_list?study_list_id=${studyListId}`,
  )

const fixFunctionalIntegrityOfStudy = (studyId: number): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.put<unknown, AxiosResponse<IStudyData>>(
    `${rootPath}/fix_functional_integrity_of_study?study_id=${studyId}`,
  )

const fixFunctionalIntegrityOfStationList = (
  stationListId: number,
): Promise<AxiosResponse<IStationListData>> =>
  axiosInstance.put<unknown, AxiosResponse<IStationListData>>(
    `${rootPath}/fix_functional_integrity_of_station_list?station_list_id=${stationListId}`,
  )

const fixFunctionalIntegrityOfStation = (stationId: number): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.put<unknown, AxiosResponse<IStationData>>(
    `${rootPath}/fix_functional_integrity_of_station?station_id=${stationId}`,
  )

const fixFunctionalIntegrityOfTool = (toolId: number): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.put<unknown, AxiosResponse<IToolData>>(
    `${rootPath}/fix_functional_integrity_of_tool?tool_id=${toolId}`,
  )

const getToolMethods = (): Promise<AxiosResponse<unknown>> =>
  axiosInstance.get(`${rootPath}/get_tool_methods`)

const getStudyListsData = (): Promise<AxiosResponse<IStudyListsData>> =>
  axiosInstance.get<unknown, AxiosResponse<IStudyListsData>>(`${rootPath}/get_study_lists_data`)

const getStudyTemplates = (): Promise<AxiosResponse<ITemplateData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<ITemplateData[]>>(`${rootPath}/get_study_templates`)

const getStationTemplates = (): Promise<AxiosResponse<ITemplateData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<ITemplateData[]>>(`${rootPath}/get_station_templates`)

const getToolTemplates = (): Promise<AxiosResponse<ITemplateData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<ITemplateData[]>>(`${rootPath}/get_tool_templates`)

const importStudies = (
  studyListId: number,
  studies: IStudyData[],
): Promise<AxiosResponse<IStudyData[]>> =>
  axiosInstance.post<IStudyData[], AxiosResponse<IStudyData[]>>(
    `${rootPath}/import_studies?study_list_id=${studyListId}`,
    studies,
  )

const importStations = (
  stationListId: number,
  stations: IStationData[],
): Promise<AxiosResponse<IStationData[]>> =>
  axiosInstance.post<IStationData[], AxiosResponse<IStationData[]>>(
    `${rootPath}/import_stations?station_list_id=${stationListId}`,
    stations,
  )

const importTools = (stationId: number, tools: IToolData[]): Promise<AxiosResponse<IToolData[]>> =>
  axiosInstance.post<IToolData[], AxiosResponse<IToolData[]>>(
    `${rootPath}/import_tools?station_id=${stationId}`,
    tools,
  )

const importTerms = (toolId: number, terms: ITermData[]): Promise<AxiosResponse<ITermData[]>> =>
  axiosInstance.post<ITermData[], AxiosResponse<ITermData[]>>(
    `${rootPath}/import_terms?tool_id=${toolId}`,
    terms,
  )

const injectStudyTemplateOnExisting = (
  targetStudyId: number,
  templateStudyId: number,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.put<unknown, AxiosResponse<IStudyData>>(
    `${rootPath}/inject_study_template_on_existing?target_study_id=${targetStudyId}&template_study_id=${templateStudyId}`,
  )

const shareStudyWithUser = (
  studyId: number,
  sharedWithUserEmail: string,
): Promise<AxiosResponse<IStudyData>> => {
  let encodedEmail = encodeURIComponent(sharedWithUserEmail)
  return axiosInstance.put<unknown, AxiosResponse<IStudyData>>(
      `${rootPath}/share_study?study_id=${studyId}&email=${encodedEmail}`,
  )
}

const injectStudyTemplate = (
  targetStudyListId: number,
  templateStudyId: number,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<unknown, AxiosResponse<IStudyData>>(
    `${rootPath}/inject_study_template?target_study_list_id=${targetStudyListId}&template_study_id=${templateStudyId}`,
  )

const injectStationTemplate = (
  targetStationListId: number,
  templateStationId: number,
): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<unknown, AxiosResponse<IStationData>>(
    `${rootPath}/inject_station_template?target_station_list_id=${targetStationListId}&template_station_id=${templateStationId}`,
  )

const injectToolTemplate = (
  targetStationId: number,
  templateToolId: number,
): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<unknown, AxiosResponse<IToolData>>(
    `${rootPath}/inject_tool_template?target_station_id=${targetStationId}&template_tool_id=${templateToolId}`,
  )

const insertStationList = (
  destIndex: number,
  stationList: IStationListData,
): Promise<AxiosResponse<IStationListData>> =>
  axiosInstance.post<IStationListData, AxiosResponse<IStationListData>>(
    `${rootPath}/insert_station_list?dest_index=${destIndex}`,
    stationList,
  )

const loadStationFromApi = (stationId: number): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.put<unknown, AxiosResponse<IStationData>>(
    `${rootPath}/load_station_from_api?station_id=${stationId}`,
  )

const loadToolFromApi = (toolId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.put(`${rootPath}/load_tool_from_api?tool_id=${toolId}`)

const moveStudy = (
  studyId: number,
  sourceStudyListId: number,
  destStudyListId: number,
  destIndex: number,
): Promise<AxiosResponse<unknown>> =>
  axiosInstance.put(
    `${rootPath}/move_study?study_id=${studyId}&source_study_list_id=${sourceStudyListId}&dest_study_list_id=${destStudyListId}&dest_index=${destIndex}`,
  )

const moveStation = (
  stationId: number,
  sourceStationListId: number,
  destStationListId: number,
  destIndex: number,
): Promise<AxiosResponse<unknown>> =>
  axiosInstance.put(
    `${rootPath}/move_station?station_id=${stationId}&source_station_list_id=${sourceStationListId}&dest_station_list_id=${destStationListId}&dest_index=${destIndex}`,
  )

const moveTool = (
  toolId: number,
  sourceStationId: number,
  destStationId: number,
  destIndex: number,
): Promise<AxiosResponse<unknown>> =>
  axiosInstance.put(
    `${rootPath}/move_tool?tool_id=${toolId}&source_station_id=${sourceStationId}&dest_station_id=${destStationId}&dest_index=${destIndex}`,
  )

const moveTerm = (
  termId: number,
  sourceToolId: number,
  destToolId: number,
  destIndex: number,
): Promise<AxiosResponse<unknown>> =>
  axiosInstance.put(
    `${rootPath}/move_term?term_id=${termId}&source_tool_id=${sourceToolId}&dest_tool_id=${destToolId}&dest_index=${destIndex}`,
  )

const voiceForTool = (toolId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.get(`${rootPath}/voice_for_tool?tool_id=${toolId}`)

const voiceForTerm = (termId: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.get(`${rootPath}/voice_for_term?term_id=${termId}`)

const voiceCommand = (voice: Blob, command : string, context: string): Promise<AxiosResponse<unknown>> => {
  const formData = new FormData()
  formData.append('file', voice) // Use the 'file' key as needed by your server API
  return axiosInstance.post(`${rootPath}/voice_command?command=${command}&context=${context}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
    }
  })
}

const StudiesService = {
  addStudy,
  addStationList,
  addStation,
  addTool,
  addTerm,
  authenticated,
  copyStudies,
  copyStations,
  copyTools,
  copyTerms,
  copyOrMoveStudies,
  copyOrMoveStations,
  copyOrMoveTools,
  copyOrMoveTerms,
  deleteAllStationListsOfStudy,
  deleteAllStationsOfStationList,
  deleteAllToolsOfStation,
  deleteAllTermsOfTool,
  deleteStudy,
  deleteStationList,
  deleteStation,
  deleteTool,
  deleteTerm,
  fixFunctionalIntegrityOfStudyList,
  fixFunctionalIntegrityOfStudy,
  fixFunctionalIntegrityOfStationList,
  fixFunctionalIntegrityOfStation,
  fixFunctionalIntegrityOfTool,
  getToolMethods,
  getStudyListsData,
  getStudyTemplates,
  getStationTemplates,
  getToolTemplates,
  importStudies,
  importStations,
  importTools,
  importTerms,
  injectStudyTemplateOnExisting,
  injectStudyTemplate,
  injectStationTemplate,
  injectToolTemplate,
  insertStationList,
  loadStationFromApi,
  loadToolFromApi,
  moveStudy,
  moveStation,
  moveTool,
  moveTerm,
  shareStudyWithUser,
  voiceForTool,
  voiceForTerm,
  voiceCommand,
}

export default StudiesService
