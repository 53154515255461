import { AxiosResponse } from 'axios'
import axiosInstance from '../utils/axios'
import IToolData from '../types/IToolData'

const rootPath = '/studies/tools'

const getAll = (): Promise<AxiosResponse<IToolData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<IToolData[]>>(rootPath)

const get = (id: number): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.get<unknown, AxiosResponse<IToolData>>(`${rootPath}/${id}`)

const create = (data: IToolData): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<IToolData, AxiosResponse<IToolData>>(`${rootPath}/`, data)

const updateOrCreate = (id: number, data: IToolData): Promise<AxiosResponse<IToolData>> => {
  if (id > 0) {
    let data2 : any
    data2 = Object.assign({},  data)
    delete data2["term_ids"]
    console.log("ToolDS data 2", data2, "data", data)
    return axiosInstance.put<IToolData, AxiosResponse<IToolData>>(`${rootPath}/${id}/`, data2)
  }
  return create(data)
}

const remove = (id: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(`${rootPath}/${id}/`)

const removeAll = (): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(rootPath)

const ToolDataService = {
  getAll,
  get,
  create,
  updateOrCreate,
  remove,
  removeAll,
}

export default ToolDataService
