import React, { useCallback, useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  Folder as FolderIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  TextFields as TextFieldsIcon,
  UploadFileOutlined as UploadFileOutlinedIcon,
  RecordVoiceOver,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'

import { IClipboard, IClipboardItemState } from '../utils/ClipboardContext'
import ITermData, { TermImportSchema } from '../types/ITermData'
import IToolData, { getTypeToolText, serializeTool } from '../types/IToolData'
import ToolConfigDialog from './ToolConfigDialog'
import ToolCopyDialog from './ToolCopyDialog'
import StudiesService from '../DataServices/StudiesService'

import ConfirmDialog from '../utils/controls/ConfirmDialog'
import FileUploadDialog from '../utils/controls/FileUploadDialog'
import MoreActionsMenu from '../utils/controls/MoreActionsMenu'
import ClipboardMenu from '../utils/controls/ClipboardMenu'

type ToolActionsMenuProps = {
  clipboard: IClipboard
  onTermsMoved: (movedTermIds: number[], targetToolId: number) => void
  onToolDeleted: (toolId: number) => void
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  setTool: React.Dispatch<React.SetStateAction<IToolData>>
  tool: IToolData
  toolRef: React.MutableRefObject<IToolData>
}

export default function ToolActionsMenu({
  clipboard,
  onTermsMoved,
  onToolDeleted,
  setSubmitting,
  setTool,
  tool,
  toolRef,
}: ToolActionsMenuProps): JSX.Element {
  const [optionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const [configDialogOpen, setConfigDialogOpen] = useState<boolean>(false)
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false)
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [deleteAllTermsDialogOpen, setDeleteAllTermsDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const cbToolState: IClipboardItemState<IToolData> | null = clipboard.tools.getItemState(tool.id)
  const isCopiedToClipboard: boolean = cbToolState !== null && cbToolState.action === 'copy'
  const isCuttedToClipboard: boolean = cbToolState !== null && cbToolState.action === 'cut'

  const handleCopyToClipboard = (): void => {
    clipboard.tools.addItem('copy', tool)
    enqueueSnackbar(t`Tool copied to clipboard`)
  }

  const handleCutToClipboard = (): void => {
    clipboard.tools.addItem('cut', tool)
    enqueueSnackbar(t`Tool cut to clipboard`)
  }

  const handleRemoveFromClipboard = (): void => {
    clipboard.tools.removeItem(tool.id)
    enqueueSnackbar(t`Tool removed from clipboard`)
  }

  const handleVoice = (): void => {
    console.log('Begining handleVoice')
    setSubmitting(true)
    StudiesService.voiceForTool(tool.id)
      .then((response) => {
        console.log(response.data)
        const fetchAudioData = async () => {
          try {
            // Create the audio URL and play the audio
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const audioBytes = atob(response.data)

            // Convert the bytes to a Uint8Array
            const audioArray = new Uint8Array(audioBytes.length)
            for (let i = 0; i < audioBytes.length; i++) {
              audioArray[i] = audioBytes.charCodeAt(i)
            }
            const oBlob = new Blob([audioArray], { type: 'audio/mpeg' })
            const audioURL = window.URL.createObjectURL(oBlob)
            const audio: HTMLAudioElement = new Audio()
            audio.src = audioURL
            audio.play()
          } catch (error) {
            console.error('Error loading audio:', error)
          }
        }
        // Call the function to fetch and play the audio
        fetchAudioData()
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while getting my voice', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handlePasteFromClipboardSelection = (): void => {
    const termStates: IClipboardItemState<ITermData>[] = clipboard.terms.getSelectedItemStates()
    if (termStates.length > 0) {
      setSubmitting(true)
      const termsToMove: ITermData[] = clipboard.terms.getSelectedItems('cut')
      StudiesService.copyOrMoveTerms(tool.id, termStates)
        .then((response) => {
          const pastedTerms: ITermData[] = response.data
          console.log('Terms pasted (length)', pastedTerms.length)
          const movedTermIds: number[] = termsToMove.map(({ id }): number => id)
          if (movedTermIds.length > 0) {
            // Remove terms from this tool (if exists)
            tool.terms = tool.terms.filter((term: ITermData) => movedTermIds.indexOf(term.id) < 0)
            tool.term_ids = tool.term_ids.filter((id: number) => movedTermIds.indexOf(id) < 0)
          }
          pastedTerms.forEach((term: ITermData) => {
            tool.terms.push(term)
            tool.term_ids.push(term.id)
          })
          setTool({ ...tool })
          if (movedTermIds.length > 0) {
            onTermsMoved(movedTermIds, tool.id)
          }
          clipboard.terms.removeSelectedItemStates()
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while terms pasting', err, err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleOpenConfigDialog = (): void => {
    setOptionsMenuOpen(false)
    setConfigDialogOpen(true)
  }

  const handleCloseConfigDialog = useCallback((): void => {
    setConfigDialogOpen(false)
  }, [])

  const handleOpenCopyDialog = (): void => {
    setOptionsMenuOpen(false)
    setCopyDialogOpen(true)
  }

  const handleCloseCopyDialog = useCallback((): void => {
    setCopyDialogOpen(false)
  }, [])

  const handleOpenImportDialog = (): void => {
    setOptionsMenuOpen(false)
    setImportDialogOpen(true)
  }

  const handleCloseImportDialog = useCallback((): void => {
    setImportDialogOpen(false)
  }, [])

  const handleImportClick = useCallback(
    (json: string, setErrorList: React.Dispatch<React.SetStateAction<string[]>>): void => {
      // Use toolRef in a Callback function
      TermImportSchema.validate(json, { abortEarly: false, stripUnknown: true })
        .then((value: unknown) => {
          const newTerm: ITermData = value as ITermData
          setImportDialogOpen(false)
          setSubmitting(true)
          StudiesService.importTerms(tool.id, [newTerm])
            .then((response) => {
              const createdTerms: ITermData[] = response.data
              console.log('Terms imported (length)', createdTerms.length)
              createdTerms.forEach((createdTerm: ITermData) => {
                toolRef.current.terms.push(createdTerm)
                toolRef.current.term_ids.push(createdTerm.id)
              })
              setTool({ ...toolRef.current })
            })
            .catch((err) => {
              console.log('ERROR: An error occurred while term importing', err, err.response)
            })
            .finally(() => {
              setSubmitting(false)
            })
        })
        .catch(({ errors }) => {
          // "errors" is a string array when "abortEarly" is false
          console.log('Schema validation error while term importing', errors)
          setErrorList([t`Invalid file format`, ...errors])
        })
    },
    [],
  )

  const handleOpenDeleteAllTermsDialog = (): void => {
    setOptionsMenuOpen(false)
    setDeleteAllTermsDialogOpen(true)
  }

  const handleCloseDeleteAllTermsDialog = useCallback((): void => {
    setDeleteAllTermsDialogOpen(false)
  }, [])

  const handleDeleteAllTermsClick = useCallback((): void => {
    // Use toolRef in a Callback function
    setDeleteAllTermsDialogOpen(false)
    setSubmitting(true)
    StudiesService.deleteAllTermsOfTool(tool.id)
      .then(() => {
        console.log('All terms deleted', tool.id)
        toolRef.current.term_ids = []
        toolRef.current.terms = []
        setTool({ ...toolRef.current })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while terms deleting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [])

  const handleOpenDeleteDialog = (): void => {
    setOptionsMenuOpen(false)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  const handleDeleteClick = useCallback((): void => {
    setDeleteDialogOpen(false)
    setSubmitting(true)
    StudiesService.deleteTool(tool.id)
      .then(() => {
        console.log('Tool deleted', tool.id)
        onToolDeleted(tool.id)
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while tool deleting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [])

  const moreActionMenuItems = tool && [
    {
      icon: <SettingsOutlinedIcon />,
      label: t`Configure tool`,
      onClick: handleOpenConfigDialog,
    },
    {
      icon: <TextFieldsIcon />,
      label: t`Copy to Excel, Word, etc.`,
      onClick: handleOpenCopyDialog,
    },
    {
      icon: <UploadFileOutlinedIcon />,
      label: t`Export tool to file`,
      props: {
        href: `data:text/json;charset=utf-8,${encodeURIComponent(serializeTool(tool))}`,
        download: `STS-Tool (${tool.toolType ? tool.toolType : tool.title}).json`,
        component: 'a',
      },
      onClick: () => setOptionsMenuOpen(false),
    },
    {
      icon: <FileDownloadOutlinedIcon />,
      label: t`Import term from file`,
      onClick: handleOpenImportDialog,
      hasDivider: true,
    },
    {
      icon: <DeleteOutlinedIcon color="secondary" />,
      label: t`Delete all terms`,
      onClick: handleOpenDeleteAllTermsDialog,
      hasDivider: true,
      color: 'error.dark',
    },
    {
      icon: <DeleteForeverOutlinedIcon color="secondary" />,
      label: t`Delete tool`,
      onClick: handleOpenDeleteDialog,
      color: 'error.dark',
    },
  ]

  return (
    <>
      {tool.isTemplate && (
        <Box sx={{ mt: '2px', whiteSpace: 'nowrap' }}>
          <Tooltip title={t`This station is a template`}>
            <IconButton
              color="warning"
              onClick={handleOpenConfigDialog}
              size="small"
              sx={{ px: 0, ml: '4px' }}
            >
              <FolderIcon />
            </IconButton>
          </Tooltip>
          <span style={{ marginLeft: '8px', marginRight: '4px' }}>|</span>
        </Box>
      )}
      <Box>
        <Tooltip title={t`Read out loud`}>
          <IconButton color="primary" onClick={handleVoice} size="medium" sx={{ px: 0, ml: '5px' }}>
            <RecordVoiceOver fontSize="small" sx={{ p: '0px' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <ClipboardMenu
        containerSx={{ pt: '4px' }}
        isCopied={isCopiedToClipboard}
        isCutted={isCuttedToClipboard}
        itemToString={t`the tool`}
        onCopyToClipboard={handleCopyToClipboard}
        onCutToClipboard={handleCutToClipboard}
        onRemoveFromClipboard={handleRemoveFromClipboard}
        onPasteFromClipboardSelection={handlePasteFromClipboardSelection}
        pasteMessages={{
          empty: t`No term selected in clipboard`,
          selected: t`Paste selected terms from clipboard`,
        }}
        selectedChildItemCount={clipboard.terms.selectedItemCount}
      />
      <MoreActionsMenu
        containerSx={{ pt: '4px' }}
        items={moreActionMenuItems}
        open={optionsMenuOpen}
        setOpen={setOptionsMenuOpen}
      />
      <ToolConfigDialog
        open={configDialogOpen}
        onClose={handleCloseConfigDialog}
        setTool={setTool}
        tool={tool}
      />
      <ToolCopyDialog open={copyDialogOpen} onClose={handleCloseCopyDialog} tool={tool} />
      <FileUploadDialog
        description={t`Click or drag to import term`}
        open={importDialogOpen}
        onClose={handleCloseImportDialog}
        onUpload={handleImportClick}
        title={tool.toolType ? getTypeToolText(tool.toolType) : tool.title}
      />
      <ConfirmDialog
        confirmColor="secondary"
        confirmText={t`Delete all terms`}
        description={t`Are you sure you want to delete all terms of this tool?`}
        open={deleteAllTermsDialogOpen}
        onClose={handleCloseDeleteAllTermsDialog}
        onConfirm={handleDeleteAllTermsClick}
        title={tool.toolType ? getTypeToolText(tool.toolType) : tool.title}
      />
      <ConfirmDialog
        confirmColor="secondary"
        confirmText={t`Delete tool`}
        description={t`Are you sure you want to delete this tool?`}
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteClick}
        title={tool.toolType ? getTypeToolText(tool.toolType) : tool.title}
      />
    </>
  )
}
