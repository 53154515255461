import React, { useCallback, useState } from 'react'
import {
  Add as AddIcon,
  CreateNewFolderOutlined as CreateNewFolderOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'

import { Box, IconButton, Tooltip } from '@mui/material'
import ClipboardContext, { IClipboard, IClipboardItemState } from '../utils/ClipboardContext'

import IStudyData, { StudyImportSchema } from '../types/IStudyData'
import IStudyListData from '../types/IStudyListData'
import StudiesService from '../DataServices/StudiesService'

import FileUploadDialog from '../utils/controls/FileUploadDialog'
import TemplateDialog from '../utils/controls/TemplateDialog'
import MoreActionsMenu from '../utils/controls/MoreActionsMenu'
import ClipboardMenu from '../utils/controls/ClipboardMenu'

type StudyListActionsMenuProps = {
  onCreateStudy: () => void
  onStudiesMoved: (movedStudyIds: number[], targetStudyListId: number) => void
  setStudyList: React.Dispatch<React.SetStateAction<IStudyListData>>
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  studyList: IStudyListData
  studyListRef: React.MutableRefObject<IStudyListData>
}

export default function StudyListActionsMenu({
  onCreateStudy,
  onStudiesMoved,
  setStudyList,
  setSubmitting,
  studyList,
  studyListRef,
}: StudyListActionsMenuProps): JSX.Element {
  const [optionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const [templateDialogOpen, setTemplateDialogOpen] = useState<boolean>(false)
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)

  const handlePasteFromClipboardSelection = (clipboard: IClipboard): void => {
    const studyStates: IClipboardItemState<IStudyData>[] = clipboard.studies.getSelectedItemStates()
    if (studyStates.length > 0) {
      setSubmitting(true)
      const studiesToMove: IStudyData[] = clipboard.studies.getSelectedItems('cut')
      StudiesService.copyOrMoveStudies(studyList.id, studyStates)
        .then((response) => {
          const pastedStudies: IStudyData[] = response.data
          console.log('Studies pasted (length)', pastedStudies.length)
          const movedStudyIds: number[] = studiesToMove.map(({ id }): number => id)
          if (movedStudyIds.length > 0) {
            // Remove studies from this studyList (if exists)
            studyList.studies = studyList.studies.filter(
              (study: IStudyData) => movedStudyIds.indexOf(study.id) < 0,
            )
            studyList.study_ids = studyList.study_ids.filter(
              (id: number) => movedStudyIds.indexOf(id) < 0,
            )
          }
          pastedStudies.forEach((station: IStudyData) => {
            studyList.studies.push(station)
            studyList.study_ids.push(station.id)
          })
          setStudyList({ ...studyList })
          if (movedStudyIds.length > 0) {
            onStudiesMoved(movedStudyIds, studyList.id)
          }
          clipboard.studies.removeSelectedItemStates()
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while studies pasting', err, err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleCreateStudyClick = (): void => {
    setOptionsMenuOpen(false)
    onCreateStudy()
  }

  const handleOpenTemplateDialog = (): void => {
    setOptionsMenuOpen(false)
    setTemplateDialogOpen(true)
  }

  const handleCloseTemplateDialog = (): void => {
    setTemplateDialogOpen(false)
  }

  const handleImportFromTemplate = (templateId: number): void => {
    setSubmitting(true)
    StudiesService.injectStudyTemplate(studyList.id, templateId)
      .then((response) => {
        const injectedStudy: IStudyData = response.data
        console.log('Study template injected', injectedStudy.id)
        studyList.studies.push(injectedStudy)
        studyList.study_ids.push(injectedStudy.id)
        setStudyList({ ...studyList })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while study template injecting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleOpenImportDialog = (): void => {
    setOptionsMenuOpen(false)
    setImportDialogOpen(true)
  }

  const handleCloseImportDialog = useCallback((): void => {
    setImportDialogOpen(false)
  }, [])

  const handleImportClick = useCallback(
    (json: string, setErrorList: React.Dispatch<React.SetStateAction<string[]>>): void => {
      // Use toolRef in a Callback function
      StudyImportSchema.validate(json, { abortEarly: false, stripUnknown: true })
        .then((value: unknown) => {
          const newStudy: IStudyData = value as IStudyData
          setImportDialogOpen(false)
          setSubmitting(true)
          StudiesService.importStudies(studyList.id, [newStudy])
            .then((response) => {
              const createdStudies: IStudyData[] = response.data
              console.log('Studies imported (length)', createdStudies.length)
              createdStudies.forEach((createdStudy: IStudyData) => {
                studyListRef.current.studies.push(createdStudy)
                studyListRef.current.study_ids.push(createdStudy.id)
              })
              setStudyList({ ...studyListRef.current })
            })
            .catch((err) => {
              console.log('ERROR: An error occurred while studies importing', err, err.response)
            })
            .finally(() => {
              setSubmitting(false)
            })
        })
        .catch(({ errors }) => {
          // "errors" is a string array when "abortEarly" is false
          console.log('Schema validation error while study importing', errors)
          setErrorList([t`Invalid file format`, ...errors])
        })
    },
    [],
  )

  const moreActionMenuItems = [
    {
      icon: <AddIcon />,
      label: t`Add study`,
      onClick: handleCreateStudyClick,
    },
    {
      icon: <CreateNewFolderOutlinedIcon />,
      label: t`Import study from template`,
      onClick: handleOpenTemplateDialog,
    },
    {
      icon: <FileDownloadOutlinedIcon />,
      label: t`Import study from file`,
      onClick: handleOpenImportDialog,
    },
  ]

  return (
    <>
      <ClipboardContext.Consumer>
        {(clipboard: IClipboard) => (
          <ClipboardMenu
            containerSx={{ mt: 1 }}
            onPasteFromClipboardSelection={() => handlePasteFromClipboardSelection(clipboard)}
            pasteMessages={{
              empty: t`No study selected in clipboard`,
              selected: t`Paste selected studies from clipboard`,
            }}
            selectedChildItemCount={clipboard.studies.selectedItemCount}
          />
        )}
      </ClipboardContext.Consumer>
      <MoreActionsMenu
        containerSx={{ mr: 1, mt: 1 }}
        items={moreActionMenuItems}
        open={optionsMenuOpen}
        setOpen={setOptionsMenuOpen}
      />
      <TemplateDialog
        description={t`Add a study (including its stations, tools and terms) from a template.`}
        getTemplates={StudiesService.getStudyTemplates}
        open={templateDialogOpen}
        onClose={handleCloseTemplateDialog}
        onImportFromTemplate={handleImportFromTemplate}
        title={t`Import study from template`}
      />
      <FileUploadDialog
        description={t`Click or drag to import study`}
        open={importDialogOpen}
        onClose={handleCloseImportDialog}
        onUpload={handleImportClick}
        title={studyList.title}
      />
    </>
  )
}
