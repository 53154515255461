import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'

export type ConfirmDialogProps = {
  confirmColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  confirmText?: string
  description: string
  onClose: () => void
  onConfirm: () => void
  open: boolean
  title: string
}

function ConfirmDialog({
  confirmColor = 'primary',
  confirmText = 'OK',
  description,
  onClose,
  onConfirm,
  open,
  title,
}: ConfirmDialogProps): JSX.Element {
  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" sx={{ p: 2 }}>
        <Box display="flex">
          <Box flexGrow={1}>{title}</Box>
          <Tooltip title={t`Close`}>
            <IconButton onClick={onClose} sx={{ color: 'text.secondary', mr: 0, p: 0 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 2 }}>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onClose} size="small" variant="outlined">{t`Cancel`}</Button>
        <Button autoFocus color={confirmColor} onClick={onConfirm} size="small" variant="contained">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ConfirmDialog)
