import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box } from '@mui/material'
import { t } from '@lingui/macro'
import ITermData from '../types/ITermData'
import StudiesService from '../DataServices/StudiesService'
import FormTextField from '../utils/controls/FormTextField'

type AddTermProps = {
  onTermCreated: (newTerm: ITermData) => void
  toolId: number
}

type FormTermFields = {
  term: string
}

function AddTerm({ onTermCreated, toolId }: AddTermProps): JSX.Element {
  console.log('Load AddTerm Component', toolId)

  const formSchema = yup.object().shape({
    term: yup.string().trim(),
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormTermFields>({
    resolver: yupResolver(formSchema),
  })

  const handleFormSubmit = (data: FormTermFields): void => {
    if (data.term.length > 0) {
      const newTerm: ITermData = {
        id: 0,
        term: data.term,
        note: '',
        hyperlink: '',
        tool: toolId,
      }
      StudiesService.addTerm(newTerm)
        .then((response) => {
          const createdTerm: ITermData = response.data
          console.log('Term created', createdTerm.id)
          reset()
          onTermCreated(createdTerm)
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while term creating', err, err.response)
        })
    }
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>

      <FormTextField
        autoComplete='off'
        errorText={errors.term?.message}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: '0.9rem',
            lineHeight: '1.5rem',
          },
        }}
        placeholder={t`Add term`}
        registerReturn={register('term')}
        variant="standard"
      />
    </Box>
  )
}

export default React.memo(AddTerm)
