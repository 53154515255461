import { AxiosResponse } from 'axios'
import axiosInstance from '../utils/axios'
import IStudyData from '../types/IStudyData'

const rootPath = '/studies/studies'

const getAll = (): Promise<AxiosResponse<IStudyData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<IStudyData[]>>(rootPath)

const get = (id: number): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.get<unknown, AxiosResponse<IStudyData>>(`${rootPath}/${id}`)

const getAllFromStudyList = (studyListId: number): Promise<AxiosResponse<IStudyData[]>> =>
  axiosInstance.get<unknown, AxiosResponse<IStudyData[]>>(
    `${rootPath}/?study_list_id=${studyListId}`,
  )

const create = (data: IStudyData): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(`${rootPath}/`, data)

const updateOrCreate = (id: number, data: IStudyData): Promise<AxiosResponse<IStudyData>> => {
  if (id > 0) {
    return axiosInstance.put<IStudyData, AxiosResponse<IStudyData>>(`${rootPath}/${id}/`, data)
  }
  return create(data)
}

const remove = (id: number): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(`${rootPath}/${id}/`)

const removeAll = (): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(rootPath)

const StudyDataService = {
  getAll,
  get,
  create,
  updateOrCreate,
  remove,
  removeAll,
  getAllFromStudyList,
}

export default StudyDataService
