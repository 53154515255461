import React, { useEffect, useMemo, useRef, useState } from 'react'
import { lightBlue } from '@mui/material/colors'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box, Collapse, IconButton, Paper } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'
import { RotationalExpandMoreIcon } from '../utils/controls/RotationalIcons'

import { IClipboardItemState } from '../utils/ClipboardContext'
import ITermData from '../types/ITermData'
import TermDataService from '../DataServices/TermDataService'
import TermActionsMenu from './TermActionsMenu'
import FormTextField from '../utils/controls/FormTextField'

type TermProps = {
  onCopyToClipboard: (term: ITermData) => IClipboardItemState<ITermData> | null
  onCutToClipboard: (term: ITermData) => IClipboardItemState<ITermData> | null
  onRemoveFromClipboard: (term: ITermData) => IClipboardItemState<ITermData> | null
  onTermChanged: (term: ITermData) => void
  onTermDeleted: (termId: number) => void
  term: ITermData
  clipboardTermState: IClipboardItemState<ITermData> | null
}

type FormTermFields = {
  term: string
  note: string
  hyperlink: string
}

function Term({
  onCopyToClipboard,
  onCutToClipboard,
  onRemoveFromClipboard,
  onTermChanged,
  onTermDeleted,
  term: initTerm,
  clipboardTermState: initClipboardTermState,
}: TermProps): JSX.Element {
  console.log('Load Term Component', initTerm.id)

  const isInitialTermRender = useRef<boolean>(true)
  const [term, setTerm] = useState<ITermData>(initTerm)
  const [expanded, setExpanded] = useState<boolean>(false)

  const formSchema = yup.object().shape({
    term: yup
      .string()
      .trim()
      .required(t`This field is required`),
    note: yup.string().trim(),
    hyperlink: yup
      .string()
      .trim()
      .url(t`Invalid URL`)
      .max(500, t`A maximum of ${500} characters are allowed`),
  })

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<FormTermFields>({
    defaultValues: useMemo(() => term as FormTermFields, [term]),
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (isInitialTermRender.current) {
      isInitialTermRender.current = false
    } else {
      console.log('Term changed (triggers onTermChanged)', term.id)
      reset(term as FormTermFields)
      onTermChanged(term)
    }
  }, [term])

  const handleFormBlur = (data: FormTermFields): void => {
    if (isDirty) {
      const termToUpdate: ITermData = { ...term, ...data }
      TermDataService.updateOrCreate(term.id, termToUpdate)
        .then(() => {
          console.log('Term updated', termToUpdate.id)
          setTerm({ ...termToUpdate })
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while term updating', err, err.response)
        })
    }
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    console.log('Debug (Term)', term)
  }

  return (
    <Paper
      key={term.id}
      onDoubleClick={handleDisplayDebugInfo}
      sx={{ bgcolor: lightBlue['100'], m: 0, mb: 1, p: 1, pb: '3px' }}
      variant='outlined'
    >
      <Box component="form" noValidate onBlur={handleSubmit(handleFormBlur)}>
        <Box display="flex">
          <Box>
            <IconButton
              aria-label={t`Show more`}
              onClick={() => setExpanded(!expanded)}
              size="small"
              sx={{ mr: 1, px: 0 }}
            >
              <RotationalExpandMoreIcon degrees={expanded ? 0 : -90} />
            </IconButton>
          </Box>
          <Box flexGrow="1">
            <Box display="flex">
              <FormTextField
                autoComplete='off'
                errorText={errors.term?.message}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: '0.85rem',
                    letterSpacing: '0.01px',
                  },
                }}
                multiline
                registerReturn={register('term')}
                variant="standard"
              />
              <TermActionsMenu
                clipboardTermState={initClipboardTermState}
                onCopyToClipboard={onCopyToClipboard}
                onCutToClipboard={onCutToClipboard}
                onRemoveFromClipboard={onRemoveFromClipboard}
                onTermDeleted={onTermDeleted}
                term={term}
              />
            </Box>
            <Box>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box>
                  <FormTextField
                    autoComplete='off'
                    errorText={errors.note?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: 'rgba(0, 0, 0, 0.74)',
                        fontSize: '0.75rem',
                        letterSpacing: '0.01px',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                      },
                    }}
                    multiline
                    placeholder="Note"
                    registerReturn={register('note')}
                    hiddenLabel
                    variant="filled"
                    size='small'
                  />
                  <FormTextField
                    autoComplete='off'
                    errorText={errors.hyperlink?.message}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: 'rgba(0, 0, 0, 0.74)',
                        fontSize: '0.75rem',
                        letterSpacing: '0.01px',
                        marginTop: '4px',
                      },
                    }}
                    variant="standard"
                    placeholder='Hyperlink'
                    registerReturn={register('hyperlink')}
                    size='small'
                  />
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default React.memo(Term)
