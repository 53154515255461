import React, { useEffect, useRef } from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material'
import { t } from '@lingui/macro'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

export interface Item {
  label: string
  icon: React.ReactElement
  onClick: () => void
  hasDivider?: boolean
  color?: string
  hidden?: boolean
  props?: MenuItemProps | Record<string, unknown>
}

export type MoreActionsMenuProps = {
  containerSx?: SxProps<Theme>
  items: Item[] | null
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MoreActionsMenu({
  containerSx,
  items,
  open,
  setOpen,
}: MoreActionsMenuProps): JSX.Element {
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (e: Event | React.SyntheticEvent): void => {
    if (anchorRef.current && anchorRef.current.contains(e.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleListKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Tab') {
      e.preventDefault()
      setOpen(false)
    } else if (e.key === 'Escape') {
      setOpen(false)
    }
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  const menuActions = items?.map(
    ({ label, icon, onClick, color, hasDivider, hidden, props = {} }) =>
      !hidden && [
        hasDivider && <Divider sx={{ my: '8px' }} />,
        <MenuItem onClick={onClick} sx={{ px: 1 }} key={label} {...props}>
          <ListItemIcon style={{ minWidth: '28px' }}>{icon}</ListItemIcon>
          <ListItemText>
            <Typography color={color || 'black'} fontSize="0.8rem" fontFamily="Segoe UI">
              {label}
            </Typography>
          </ListItemText>
        </MenuItem>,
      ],
  )

  return (
    <Box sx={containerSx}>
      <Tooltip title={t`More options`}>
        <IconButton
          aria-controls={open ? 'composition-options-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          color="primary"
          id="composition-options-button"
          onClick={handleToggle}
          ref={anchorRef}
          size="small"
          sx={{ px: 0, ml: '2px' }}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={open}
        placement="bottom-start"
        role={undefined}
        style={{ zIndex: 1 }}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  aria-labelledby="composition-options-button"
                  dense
                  id="composition-options-menu"
                  onKeyDown={handleListKeyDown}
                  sx={{ px: '4px', py: '6px' }}
                >
                  {menuActions}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
