import React, { useCallback, useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  Add as AddIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DriveFolderUploadOutlined as DriveFolderUploadOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  Folder as FolderIcon,
  PushPin as PushPinIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  UploadFileOutlined as UploadFileOutlinedIcon,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { t } from '@lingui/macro'
import {
  RotationalArrowDownwardIcon,
  RotationalPushPinOutlinedIcon,
} from '../utils/controls/RotationalIcons'

import { IClipboard, IClipboardItemState } from '../utils/ClipboardContext'
import IStationData, { serializeStation } from '../types/IStationData'
import IToolData, { ToolImportSchema } from '../types/IToolData'
import StudiesService from '../DataServices/StudiesService'

import ConfirmDialog from '../utils/controls/ConfirmDialog'
import StationConfigDialog from './StationConfigDialog'
import FileUploadDialog from '../utils/controls/FileUploadDialog'
import TemplateDialog from '../utils/controls/TemplateDialog'
import MoreActionsMenu from '../utils/controls/MoreActionsMenu'
import ClipboardMenu from '../utils/controls/ClipboardMenu'

type StationActionsMenuProps = {
  clipboard: IClipboard
  onChangeDirection: () => void
  onChangePin: () => void
  onCreateTool: () => void
  onStationDeleted: (stationId: number) => void
  onToolsMoved: (movedToolIds: number[], targetStationId: number) => void
  setStation: React.Dispatch<React.SetStateAction<IStationData>>
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  station: IStationData
  stationRef: React.MutableRefObject<IStationData>
}

export default function StationActionsMenu({
  clipboard,
  onChangeDirection,
  onChangePin,
  onCreateTool,
  onStationDeleted,
  onToolsMoved,
  setStation,
  setSubmitting,
  station,
  stationRef,
}: StationActionsMenuProps): JSX.Element {
  const [optionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const [configDialogOpen, setConfigDialogOpen] = useState<boolean>(false)
  const [templateDialogOpen, setTemplateDialogOpen] = useState<boolean>(false)
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [deleteAllToolsDialogOpen, setDeleteAllToolsDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const cbStationState: IClipboardItemState<IStationData> | null = clipboard.stations.getItemState(
    station.id,
  )
  const isCopiedToClipboard: boolean = cbStationState !== null && cbStationState.action === 'copy'
  const isCuttedToClipboard: boolean = cbStationState !== null && cbStationState.action === 'cut'

  const handleCreateToolClick = (): void => {
    setOptionsMenuOpen(false)
    onCreateTool()
  }

  const handleChangeDirectionClick = (): void => {
    setOptionsMenuOpen(false)
    onChangeDirection()
  }

  const handleChangePinClick = (): void => {
    setOptionsMenuOpen(false)
    onChangePin()
  }

  const handleCopyToClipboard = (): void => {
    clipboard.stations.addItem('copy', station)
    enqueueSnackbar(t`Station copied to clipboard`)
  }

  const handleCutToClipboard = (): void => {
    clipboard.stations.addItem('cut', station)
    enqueueSnackbar(t`Station cut to clipboard`)
  }

  const handleRemoveFromClipboard = (): void => {
    clipboard.stations.removeItem(station.id)
    enqueueSnackbar(t`Station removed from clipboard`)
  }

  const handleOpenConfigDialog = (): void => {
    setOptionsMenuOpen(false)
    setConfigDialogOpen(true)
  }

  const handleCloseConfigDialog = useCallback((): void => {
    setConfigDialogOpen(false)
  }, [])

  const handlePasteFromClipboardSelection = (): void => {
    const toolStates: IClipboardItemState<IToolData>[] = clipboard.tools.getSelectedItemStates()
    if (toolStates.length > 0) {
      setSubmitting(true)
      const toolsToMove: IToolData[] = clipboard.tools.getSelectedItems('cut')
      StudiesService.copyOrMoveTools(station.id, toolStates)
        .then((response) => {
          const pastedTools: IToolData[] = response.data
          console.log('Tools pasted (length)', pastedTools.length)
          const movedToolIds: number[] = toolsToMove.map(({ id }): number => id)
          if (movedToolIds.length > 0) {
            // Remove tools from this station (if exists)
            station.tools = station.tools.filter(
              (tool: IToolData) => movedToolIds.indexOf(tool.id) < 0,
            )
            station.tool_ids = station.tool_ids.filter((id: number) => movedToolIds.indexOf(id) < 0)
          }
          pastedTools.forEach((tool: IToolData) => {
            station.tools.push(tool)
            station.tool_ids.push(tool.id)
          })
          setStation({ ...station })
          if (movedToolIds.length > 0) {
            onToolsMoved(movedToolIds, station.id)
          }
          clipboard.tools.removeSelectedItemStates()
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while tools pasting', err, err.response)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleOpenTemplateDialog = (): void => {
    setOptionsMenuOpen(false)
    setTemplateDialogOpen(true)
  }

  const handleCloseTemplateDialog = (): void => {
    setTemplateDialogOpen(false)
  }

  const handleImportFromTemplate = (templateId: number): void => {
    setSubmitting(true)
    StudiesService.injectToolTemplate(station.id, templateId)
      .then((response) => {
        const injectedTool: IToolData = response.data
        console.log('Tool template injected', injectedTool.id)
        station.tools.push(injectedTool)
        station.tool_ids.push(injectedTool.id)
        setStation({ ...station })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while tool template injecting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleOpenImportDialog = (): void => {
    setOptionsMenuOpen(false)
    setImportDialogOpen(true)
  }

  const handleCloseImportDialog = useCallback((): void => {
    setImportDialogOpen(false)
  }, [])

  const handleImportClick = useCallback(
    (json: string, setErrorList: React.Dispatch<React.SetStateAction<string[]>>): void => {
      // Use stationRef in a Callback function
      ToolImportSchema.validate(json, { abortEarly: false, stripUnknown: true })
        .then((value: unknown) => {
          const newTool: IToolData = value as IToolData
          setImportDialogOpen(false)
          setSubmitting(true)
          StudiesService.importTools(station.id, [newTool])
            .then((response) => {
              const createdTools: IToolData[] = response.data
              console.log('Tools imported (length)', createdTools.length)
              createdTools.forEach((createdTool: IToolData) => {
                stationRef.current.tools.push(createdTool)
                stationRef.current.tool_ids.push(createdTool.id)
              })
              setStation({ ...stationRef.current })
            })
            .catch((err) => {
              console.log('ERROR: An error occurred while tools importing', err, err.response)
            })
            .finally(() => {
              setSubmitting(false)
            })
        })
        .catch(({ errors }) => {
          // "errors" is a string array when "abortEarly" is false
          console.log('Schema validation error while tool importing', errors)
          setErrorList([t`Invalid file format`, ...errors])
        })
    },
    [],
  )

  const handleOpenDeleteAllToolsDialog = (): void => {
    setOptionsMenuOpen(false)
    setDeleteAllToolsDialogOpen(true)
  }

  const handleCloseDeleteAllToolsDialog = useCallback((): void => {
    setDeleteAllToolsDialogOpen(false)
  }, [])

  const handleDeleteAllToolsClick = useCallback((): void => {
    // Use stationRef in a Callback function
    setDeleteAllToolsDialogOpen(false)
    setSubmitting(true)
    StudiesService.deleteAllToolsOfStation(station.id)
      .then(() => {
        console.log('All tools deleted', station.id)
        stationRef.current.tool_ids = []
        stationRef.current.tools = []
        setStation({ ...stationRef.current })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while tools deleting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [])

  const handleOpenDeleteDialog = (): void => {
    setOptionsMenuOpen(false)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  const handleDeleteClick = useCallback((): void => {
    setDeleteDialogOpen(false)
    setSubmitting(true)
    StudiesService.deleteStation(station.id)
      .then(() => {
        console.log('Station deleted', station.id)
        onStationDeleted(station.id)
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while station deleting', err, err.response)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [])

  const moreActionMenuItems = station && [
    {
      icon: <SettingsOutlinedIcon />,
      label: t`Configure station`,
      onClick: handleOpenConfigDialog,
    },
    {
      icon: <RotationalArrowDownwardIcon degrees={station.direction === 'row' ? -90 : 0} />,
      label: t`Reverse orientation`,
      onClick: handleChangeDirectionClick,
    },
    {
      icon: station.isPinned ? (
        <PushPinIcon sx={{ mt: '3px' }} />
      ) : (
        <RotationalPushPinOutlinedIcon degrees={-30} sx={{ mt: '3px' }} />
      ),
      label: station.isPinned ? t`Unpin station` : t`Pin station`,
      onClick: handleChangePinClick,
    },
    {
      icon: <FileDownloadOutlinedIcon />,
      label: t`Export station to file`,
      props: {
        href: `data:text/json;charset=utf-8,${encodeURIComponent(serializeStation(station))}`,
        download: `STS-Station (${station.title}).json`,
        component: 'a',
      },
      onClick: () => setOptionsMenuOpen(false),
    },
    {
      icon: <AddIcon />,
      label: t`Add tool`,
      onClick: handleCreateToolClick,
      hasDivider: true,
    },
    {
      icon: <DriveFolderUploadOutlinedIcon />,
      label: t`Import tool from template`,
      onClick: handleOpenTemplateDialog,
    },
    {
      icon: <UploadFileOutlinedIcon />,
      label: t`Import tool from file`,
      onClick: handleOpenImportDialog,
    },
    {
      icon: <DeleteOutlinedIcon color="secondary" />,
      label: t`Delete all tools`,
      onClick: handleOpenDeleteAllToolsDialog,
      hasDivider: true,
      color: 'error.dark',
    },
    {
      icon: <DeleteForeverOutlinedIcon color="secondary" />,
      label: t`Delete station`,
      onClick: handleOpenDeleteDialog,
      color: 'error.dark',
    },
  ]

  return (
    <>
      {station.isTemplate && (
        <Box sx={{ mt: '2px', whiteSpace: 'nowrap' }}>
          <Tooltip title={t`This station is a template`}>
            <IconButton
              color="warning"
              onClick={handleOpenConfigDialog}
              size="small"
              sx={{ px: 0, ml: '4px' }}
            >
              <FolderIcon />
            </IconButton>
          </Tooltip>
          <span style={{ marginLeft: '8px', marginRight: '4px' }}>|</span>
        </Box>
      )}
      <ClipboardMenu
        containerSx={{ mt: '4px' }}
        isCopied={isCopiedToClipboard}
        isCutted={isCuttedToClipboard}
        itemToString={t`the station`}
        onCopyToClipboard={handleCopyToClipboard}
        onCutToClipboard={handleCutToClipboard}
        onRemoveFromClipboard={handleRemoveFromClipboard}
        onPasteFromClipboardSelection={handlePasteFromClipboardSelection}
        pasteMessages={{
          empty: t`No tool selected in clipboard`,
          selected: t`Paste selected tools from clipboard`,
        }}
        selectedChildItemCount={clipboard.tools.selectedItemCount}
      />
      <MoreActionsMenu
        containerSx={{ mt: '4px' }}
        items={moreActionMenuItems}
        open={optionsMenuOpen}
        setOpen={setOptionsMenuOpen}
      />
      <StationConfigDialog
        open={configDialogOpen}
        onClose={handleCloseConfigDialog}
        setStation={setStation}
        station={station}
      />
      <TemplateDialog
        description={t`Add a tool (including its terms) from a template.`}
        getTemplates={StudiesService.getToolTemplates}
        open={templateDialogOpen}
        onClose={handleCloseTemplateDialog}
        onImportFromTemplate={handleImportFromTemplate}
        title={t`Import tool from template`}
      />
      <FileUploadDialog
        description={t`Click or drag to import tool`}
        open={importDialogOpen}
        onClose={handleCloseImportDialog}
        onUpload={handleImportClick}
        title={station.title}
      />
      <ConfirmDialog
        confirmColor="secondary"
        confirmText={t`Delete all tools`}
        description={t`Are you sure you want to delete all tools of this station?`}
        open={deleteAllToolsDialogOpen}
        onClose={handleCloseDeleteAllToolsDialog}
        onConfirm={handleDeleteAllToolsClick}
        title={station.title}
      />
      <ConfirmDialog
        confirmColor="secondary"
        confirmText={t`Delete station`}
        description={t`Are you sure you want to delete this station?`}
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteClick}
        title={station.title}
      />
    </>
  )
}
